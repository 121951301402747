import Modal from "react-bootstrap/Modal";
import "./CenteredModal.css";
import Container from "react-bootstrap/Container";
const CenteredModal = (props) => {
    return (
        <Modal
            {...props}
            size={props.size}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={"integration-modal"}
        >
            <Container>
                {props.children}
            </Container>
        </Modal>
    );
};

export default CenteredModal;