import Row from "react-bootstrap/Row";
import {Col, Nav, Tab} from "react-bootstrap";
import "./report-type.css";
import {BsFillBoxFill, BsFillHandbagFill, BsPrinter} from "react-icons/bs";
import {HiUsers} from "react-icons/hi";
import {BiCalendarEvent, BiSolidDiscount} from "react-icons/bi";
import {FaChartColumn} from "react-icons/fa6";
import SalesGridComponent from "../Sales-Grid/SalesGrid.component";
import CustomersGridComponent from "../Customers-Grid/CustomersGrid.component";
import AbandonedBasketsComponent from "../Abandoned-Baskets-Grid/AbandonedBaskets.component";
import DiscountsGridComponent from "../Discounts-Grid/DiscountsGrid.component";
import ProductsTableComponent from "../Products-Table/ProductsTable.component";
import AnalyticsDatePickerComponent from "../Range-Date-Picker/AnalyticsDatePicker.component";
import {useRef, useState} from "react";
import {addDays} from "date-fns";
const ReportTypeComponent = () => {
    const [shownDatePicker, setShownDatePicker] = useState(false);
    const pickerRef = useRef(null);
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);
    const handleDateRangeChange = (newDateRange) => {
        setDateRange(newDateRange);
    }

    const handleWrapperClick = (e) => {
        if (pickerRef.current && !pickerRef.current.contains(e.target)) {
            setShownDatePicker(false);
        }
    };

    const tabs = [
        { tab: "Sales", eventKey: "sales", content: <SalesGridComponent />, icon: <FaChartColumn size={20}/> },
        { tab: "Products", eventKey: "products", content: <ProductsTableComponent />, icon: <BsFillBoxFill size={20} /> },
        { tab: "Customers", eventKey: "customers", content: <CustomersGridComponent /> , icon: <HiUsers size={20} /> },
        { tab: "Discounts", eventKey: "discounts", content: <DiscountsGridComponent />, icon: <BiSolidDiscount size={20} /> },
        { tab: "Abandoned Baskets", eventKey: "abandoned-baskets", content: <AbandonedBasketsComponent />, icon: <BsFillHandbagFill size={20}/> },
    ];
    return (
        <div onClick={handleWrapperClick}>
        <h4 className={"mainColor"}>
            Report Type
        </h4>
            <small className={"mainColor mb-3"}>It may take up to 24 hours for data to be updated</small>
            <Tab.Container defaultActiveKey="sales">
                    <Nav variant="pills" className={"report-tabs-container"}>
                    {tabs.map((tab,index)=>(
                        <div key={index} className={"report-tabs-tab"}>
                                <Nav.Item>
                                    <Nav.Link eventKey={tab.eventKey} className={"d-flex flex-column justify-content-evenly align-items-center align-content-stretch"}>
                                        <div>
                                            {tab.icon}
                                        </div>
                                        <div>
                                            {tab.tab}
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                        </div>
                    ))}
                    </Nav>
                    <Row className={"date-picker-container my-4"}>
                        <Col lg={{span: 3, offset: 1}}>
                            <BsPrinter size={25} color={"#1A478F"} />
                        </Col>
                        <Col lg={{span: 4, offset: 0}} className="position-relative mr-auto" ref={pickerRef}>
                            <div role={"button"} onClick={()=> setShownDatePicker(!shownDatePicker)} className={"report-date-container"}>
                                <div className={"report-date-value"}>
                                    {dateRange[0].startDate.toDateString()} - {dateRange[0].endDate.toDateString()}
                                </div>

                                <div className={"report-date-calendar"}>
                                    <div className={"mx-2"}>
                                        Report Date
                                    </div>
                                    <div>
                                        <BiCalendarEvent />
                                    </div>
                                </div>
                            </div>
                            {shownDatePicker && (
                                <div className="range-date-picker">
                                    <AnalyticsDatePickerComponent
                                        handleDateRangeChange={handleDateRangeChange}
                                        initialDateRange={dateRange}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Tab.Content>
                            {tabs.map((tab,index)=>(
                                <Tab.Pane eventKey={tab.eventKey} key={index}>
                                    {tab.content}
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Row>
            </Tab.Container>
        </div>
    );
};

export default ReportTypeComponent;
