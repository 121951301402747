import CenteredModal from "../Modals/CenteredModal/CenteredModal";
import {FaRegCircleXmark} from "react-icons/fa6";
import {Tab, Tabs, Form, Col, Button} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import PhoneInput from 'react-phone-number-input'
import {useState} from "react";
import Row from "react-bootstrap/Row";

const FindNewClientModal = ({ show, onHide }) => {
    const [value, setValue] = useState("+966");

    return (
        <CenteredModal show={show} onHide={onHide} size={'lg'}>
            <div className={'p-0 p-md-5'}>
                <div className={'position-relative'}>
                    <h3 className={'text-center fw-bold'}>Add a new client</h3>
                    <div className={'product-modal-close-icon'} onClick={onHide}>
                        <FaRegCircleXmark size={25} color={'#FA5252'}/>
                    </div>
                </div>
            </div>
            <Tabs
                defaultActiveKey="individual"
                id="fill-tab-example"
                className="mb-3 new-client-tabs"
                variant="pills"
                fill
            >
                <Tab eventKey="individual" title="Individual">
                    <Container>
                        <Form.Group className="mb-4 new-client-input" controlId="customer-name">
                            <Form.Label>Customer Name</Form.Label>
                            <Form.Control type="email" placeholder="Enter the customer's name" />
                        </Form.Group>
                        <Form.Group className="mb-4 new-client-input" controlId="customer-email">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control type="email" placeholder="Enter the customer's email" />
                        </Form.Group>
                        <Form.Group className="mb-4 new-client-input" controlId="customer-phone">
                            <Form.Label>Mobile number</Form.Label>
                            <PhoneInput
                                placeholder="Enter phone number"
                                value={value}
                                onChange={setValue}
                                defaultCountry="SA"
                            />
                        </Form.Group>
                        <div className={"mainColor mb-2"}>Gender</div>
                        <Form.Group className={"mb-4 row justify-content-between"}>
                            <Col lg={6}>
                                <Form.Check
                                    inline
                                    label="Male"
                                    name="group1"
                                    type={"radio"}
                                    id={`male-radio`}
                                />
                            </Col>
                            <Col lg={6}>
                                <Form.Check
                                    inline
                                    label="Female"
                                    name="group1"
                                    type={"radio"}
                                    id={`female-radio`}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 new-client-input" controlId="customer-birthdate">
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control type="date" />
                        </Form.Group>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className={"new-client-input"}>
                                    <Form.Label>City</Form.Label>
                                    <Form.Select aria-label="Default select example">
                                        <option>Saudi Arabia</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className={"new-client-input"}>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select aria-label="Default select example">
                                        <option>Choose</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className={"d-flex justify-content-center my-5"}>
                            <div className={"submit-button me-4"}>
                                Save
                            </div>
                            <Button variant={"outline-danger"} className={"rounded-pill"} onClick={onHide}>
                                Cancel
                            </Button>
                        </div>
                    </Container>

                </Tab>
                <Tab eventKey="company" title="Company">
                    <Form.Group className="mb-4 new-client-input" controlId="customer-name">
                        <Form.Label>Customer Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter the customer's name" />
                    </Form.Group>
                    <Form.Group className="mb-4 new-client-input" controlId="customer-email">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control type="email" placeholder="Enter the customer's email" />
                    </Form.Group>
                    <Form.Group className="mb-4 new-client-input" controlId="company-name">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control type="text" placeholder="Type the company name" />
                    </Form.Group>
                    <Form.Group className="mb-4 new-client-input" controlId="customer-phone">
                        <Form.Label>Mobile number</Form.Label>
                        <PhoneInput
                            placeholder="Enter phone number"
                            value={value}
                            onChange={setValue}
                            defaultCountry="SA"
                        />
                    </Form.Group>
                    <Form.Group className="mb-4 new-client-input" controlId="commercial-register">
                        <Form.Label>Commercial Register</Form.Label>
                        <Form.Control type="text" placeholder="Write the commercial register" />
                    </Form.Group>
                    <Form.Group className="mb-4 new-client-input" controlId="tax-number">
                        <Form.Label>Tax Number</Form.Label>
                        <Form.Control type="text" placeholder="Enter the tax number" />
                    </Form.Group>
                    <Row>
                        <Col lg={6}>
                            <Form.Group className={"new-client-input"}>
                                <Form.Label>City</Form.Label>
                                <Form.Select aria-label="Default select example">
                                    <option>Saudi Arabia</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className={"new-client-input"}>
                                <Form.Label>Country</Form.Label>
                                <Form.Select aria-label="Default select example">
                                    <option>Choose</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className={"d-flex justify-content-center my-5"}>
                        <div className={"submit-button me-4"}>
                            Save
                        </div>
                        <Button variant={"outline-danger"} className={"rounded-pill"} onClick={onHide}>
                            Cancel
                        </Button>
                    </div>
                </Tab>
            </Tabs>
        </CenteredModal>
);
};

export default FindNewClientModal;