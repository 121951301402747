import { api, BackEnd_EndPoint } from '../config';

const ProductService = {
    // Get all product
    getAllProducts: async () => {
        try {
            const response = await api.get(`${BackEnd_EndPoint}product`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching product:', error.message || error);
            throw error;
        }
    },

    // Add a new product
    addProduct: async (productData) => {
        try {
            const response = await api.post(`${BackEnd_EndPoint}product`, productData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error adding product:', error.message || error);
            throw error;
        }
    },

    // Update a product
    updateProduct: async (productData) => {
        try {
            const response = await api.put(`${BackEnd_EndPoint}product/${productData.id}`, productData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error updating product:', error.message || error);
            throw error;
        }
    },

    // Delete a product
    deleteProduct: async (productId) => {
        try {
            const response = await api.delete(`${BackEnd_EndPoint}product/${productId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error deleting product:', error.message || error);
            throw error;
        }
    },

    // Get single product
    getProduct: async (productId) => {
        try {
            const response = await api.get(`${BackEnd_EndPoint}product/${productId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching product:', error.message || error);
            throw error;
        }
    }
};

export default ProductService;
