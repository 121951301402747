import Modal from "react-bootstrap/Modal";
import "./CenteredModal.css";
import {FaRegCircleXmark} from "react-icons/fa6";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import {Col} from "react-bootstrap";
import CreatableSelect from 'react-select/creatable';
const FilterOrderModal = (props) => {
    const selectedTags = [
        { label: "Tag1", value: "Tag1" },
        { label: "Tag2", value: "Tag2" },
    ];
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={"sorting-orders-modal"}
        >
            <Container className={"text-white"}>
            <div className={"position-relative mb-5"}>
                <h3 className={"text-center fw-bold text-white"}>Sorting orders</h3>
                <div className={"modal-close-icon"} onClick={props.onHide}><FaRegCircleXmark size={25} color={"#fff"} /></div>
            </div>
            <Row className="mb-3">
                <Form.Group controlId="order-number">
                    <Form.Label>Order Number</Form.Label>
                    <Form.Control type="text" placeholder="order number" />
                </Form.Group>
            </Row>
                <Row>
                    <Col md={6} sm={12} className="mb-3">
                        <Form.Group controlId="order-status">
                            <Form.Label>Order status</Form.Label>
                            <Form.Select aria-label="order-status" defaultValue={"0"}>
                                <option value={"0"} disabled>everyone</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                        <Form.Group controlId="payment-status">
                            <Form.Label>Payment status</Form.Label>
                            <Form.Select aria-label="payment-status" defaultValue={"0"}>
                                <option value={"0"} disabled>everyone</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12} className="mb-3">
                        <Form.Group controlId="payment-method">
                            <Form.Label>Payment Method</Form.Label>
                            <Form.Select aria-label="payment-method" defaultValue={"0"}>
                                <option value={"0"} disabled>everyone</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                        <Form.Group controlId="payment-transaction">
                            <Form.Label>Payment transaction ID</Form.Label>
                            <Form.Control type="text" placeholder="Payment transaction ID" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12} className="mb-3">
                        <Form.Group controlId="request-source">
                            <Form.Label>Request source</Form.Label>
                            <Form.Select aria-label="request-source" defaultValue={"0"}>
                                <option value={"0"} disabled>everyone</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                        <Form.Group controlId="shipping-method">
                            <Form.Label>Shipping Method</Form.Label>
                            <Form.Select aria-label="shipping-method" defaultValue={"0"}>
                                <option value={"0"} disabled>everyone</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12} className="mb-3">
                        <Form.Group controlId="customer-name">
                            <Form.Label>Customer Name</Form.Label>
                            <Form.Control type="text" placeholder="Customer Name" />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                        <Form.Group controlId="client-type">
                            <Form.Label>Client type</Form.Label>
                            <Form.Select aria-label="client-type" defaultValue={"0"}>
                                <option value={"0"} disabled>everyone</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12} className="mb-3">
                        <Form.Group controlId="country">
                            <Form.Label>Country</Form.Label>
                            <Form.Control type="text" placeholder="Country" />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                        <Form.Group controlId="city">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" placeholder="City" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={"justify-content-center"}>
                    <Col md={5} sm={12} className="mb-3 text-start text-md-end">
                        <Form.Check
                            inline
                            label="By date of creation"
                            name="group1"
                            type={"radio"}
                            id={"radio-date-filter"}
                        />
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                        <Form.Check
                            inline
                            name="group1"
                            label="According to the date of modification"
                            type={"radio"}
                            id={"radio-date-filter-2"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12} className="mb-3">
                        <Form.Group controlId="dateFrom">
                            <Form.Label>From the date of</Form.Label>
                            <Form.Control type="date" className={"d-flex row-reverse"} />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                        <Form.Group controlId="dateTo">
                            <Form.Label>To date</Form.Label>
                            <Form.Control type="date" className={"d-flex row-reverse"} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <p>
                        Tags
                    </p>
                    <CreatableSelect
                        isClearable
                        options={selectedTags}
                        defaultValue={selectedTags[0]}
                        isMulti
                    />
                </Row>
            </Container>
        </Modal>
    );
};

export default FilterOrderModal;