import {AiFillPlusCircle} from "react-icons/ai";
import {Button} from "react-bootstrap";
import CenteredModal from "../../components/Modals/CenteredModal/CenteredModal";
import {useEffect, useMemo, useState} from "react";
import {BiSolidFileExport} from "react-icons/bi";
import AddCustomerForm from "../../components/Customers/AddCustomerForm";
import {useDispatch, useSelector} from "react-redux";
import Row from "react-bootstrap/Row";
import CustomersTable from "../../components/Customers/CustomersTable";
import {fetchCustomers} from "../../features/customer/customerActions";

const Customers = () => {
    const [show, setShow] = useState(false);
    const Customers = useSelector(state => state.customer.data);
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Phone',
            accessor: 'phone',
        },
        {
            Header: 'City',
            accessor: 'city',
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'Floor',
            accessor: 'floor'
        },
        {
            Header: 'Actions',
            accessor: '',
        }
    ], []);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchCustomers());
        }
        fetchData();
    }, []);
    console.log(Customers)
    return (
        <>
        <div className={"d-flex justify-content-between align-items-center"}>
        <div className={"mainColor"}>
            <h4 className={"fw-bold"}>Customers</h4>
            <small>All customers in your store are here</small>
        </div>
            <div>
                <Button className={"create-request-button py-1 px-2 border-0"} onClick={()=>setShow(true)}>
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <div className={"me-2"}>
                            Add new customer
                        </div>
                        <div>
                            <AiFillPlusCircle color={"white"} />
                        </div>
                    </div>
                </Button>
                <button className={"rounded-pill bg-white mainColor py-1 px-2 ms-2"} style={{border: "1px solid #1A478F"}}>
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <div className={"me-2"}>
                            Import Customers
                        </div>
                        <div>
                            <BiSolidFileExport className={"mainColor"}/>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    <Row>
        <CustomersTable data={Customers} columns={columns} />
    </Row>
            <CenteredModal show={show} onHide={() => setShow(false)} size={"lg"}>
                <AddCustomerForm setShow={setShow} />
            </CenteredModal>
    </>
    );
};

export default Customers;