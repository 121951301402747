import {api} from "../config";
import {toast} from "react-toastify";

const FeatureService = {
    getAllFeatures: async () => {
        try {
            const response = await api.get("feature", {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    },

    addFeature: async (featureData) => {
        try {
            const response = await api.post("feature", featureData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error creating feature:', error);
            throw error;
        }
    },

    updateFeature: async (featureId, featureData) => {
        try {
            const response = await api.put(`feature/${featureId}`, featureData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error updating feature:', error);
            throw error;
        }
    },

    deleteFeature: async (featureId) => {
        try {
            const response = await api.delete(`feature/${featureId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            toast.success(response.data.message, {position: "bottom-right", theme: "dark"})
            return response.data;
        } catch (error) {
            toast.error(error.response.data.message, {position: "bottom-right", theme: "dark"})
            throw error;
        }
    },

    getSingleFeature: async (featureId) => {
        try {
            const response = await api.get(`feature/${featureId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    },

    addFeatureOptions: async (featureData) => {
        try {
            const response = await api.post("featureCategories", featureData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            toast.success(response.data.message, {position: "bottom-right", theme: "dark"})
            return response.data;
        } catch (error) {
            toast.error(error.response.data.message, {position: "bottom-right", theme: "dark"})
            throw error;
        }
    }

}
export default FeatureService;