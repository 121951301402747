import {createAsyncThunk} from '@reduxjs/toolkit';
import FeatureService from "../../services/features/FeatureService";

export const fetchFeatures = createAsyncThunk('product/fetchFeatures', async () => {
    try {
        return await FeatureService.getAllFeatures();
    } catch (error) {
        throw error;
    }
});

export const fetchFeature = createAsyncThunk('product/fetchFeature', async (id) => {
    try {
        return await FeatureService.getSingleFeature(id);
    } catch (error) {
        throw error;
    }
});

export const addFeature = createAsyncThunk('product/addFeature', async (params) => {
    const data ={name: params.name, client_id: params.clientId};
    try {
        return await FeatureService.addFeature(data);
    } catch (error) {
        throw error;
    }
});

export const updateFeature = createAsyncThunk('product/updateFeature', async (params) => {
    const {categoryId, categoryData} = params;
    try {
        return await FeatureService.updateFeature(categoryId, categoryData);
    } catch (error) {
        throw error;
    }
});

export const deleteFeature = createAsyncThunk('product/deleteFeature', async (id) => {
    try {
        return await FeatureService.deleteFeature(id);
    } catch (error) {
        throw error;
    }
});

export const addFeatureOptions = createAsyncThunk('product/addFeatureOptionsInFeatures', async (params) => {
    const data = {name: params.name, feature_id: params.featureId, client_id: params.clientId}
    try {
        return await FeatureService.addFeatureOptions(data);
    } catch (error) {
        throw error;
    }
});