import {useMemo, useState} from "react";
import {useAsyncDebounce, useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import {Col, Form, InputGroup, Pagination, Row, Table} from "react-bootstrap";
import {BsFillCaretDownFill} from "react-icons/bs";
import {BiSearch} from "react-icons/bi"
import {FaFilterCircleXmark} from "react-icons/fa6";

function GlobalFilter({
                          preGlobalFilteredRows, globalFilter, setGlobalFilter,
                      }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (<div className={"position-relative"}>
        <InputGroup>
            <Form.Control
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
                className={"search-input"}
            />
        </InputGroup>
        <div className={"search-icon"}>
            <BiSearch color={"#000"} size={20}/>
        </div>
    </div>)
}

function DefaultColumnFilter({
                                 column: {filterValue, preFilteredRows, setFilter},
                             }) {
    const count = preFilteredRows.length

    return (<input
        className="form-control"
        value={filterValue || ''}
        onChange={e => {
            setFilter(e.target.value || undefined)
        }}
        placeholder={`Search ${count} records...`}
    />)
}

const OrdersDataTable = ({tableHeader, columns, data, loading, classNames, setCurrentPage, currentPage, recordsPerPage, setRecordsPerPage, totalPages, tableActions}) => {
    const defaultColumn = useMemo(
        () => ({
            // Default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        setFilter,
        canPreviousPage,
        canNextPage,
        gotoPage,
        setPageSize,
        rows,
        state: { pageIndex, pageSize, globalFilter },
    } = useTable({
        columns, data, defaultColumn, initialState: { pageIndex: currentPage - 1, pageSize: recordsPerPage },
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const handleClearFilters = () => {
        setGlobalFilter("");
        columns.forEach((column) => {
            if (column.accessor) {
                setFilter(column.accessor, undefined);
            }
        });
    };

    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex + 1);
        gotoPage(pageIndex);
    };

    const handlePageSizeChange = (size) => {
        setRecordsPerPage(size);
        setPageSize(size);
    };
    return (
            <>
                <div className={`all-orders-table ${classNames}`}>
                    <Row className={"m-4"} data-bs-theme={"dark"}>
                        {tableHeader}
                        <Row>
                            <Col lg={6}>
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                />
                            </Col>
                            <Col lg={6} className={"d-flex justify-content-center justify-content-lg-end align-items-center my-2 my-lg-0"}>
                                <div className={"clear-filter"} onClick={handleClearFilters}>
                                    <FaFilterCircleXmark size={25} />
                                </div>
                                {tableActions}
                            </Col>
                        </Row>
                    </Row>
                    <Table responsive={"md"} bordered={false}
                           className="table text-center position-relative" {...getTableProps()}>
                            <>
                                <thead>
                                {headerGroups.map((headerGroup, index) => (
                                    <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                        {headerGroup.headers.map((column, j) => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                                key={j}>
                                                {column.render('Header')}
                                                {/* Render the columns filter UI */}
                                                {/*<div>{column.canFilter ? column.render('Filter') : null}</div>*/}
                                                <span>
                                                                {column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : <>
                                                                    {" "}
                                                                    {column.accessor &&
                                                                        <BsFillCaretDownFill />}
                                                                </>}
                                                            </span>
                                            </th>))}
                                    </tr>))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                {rows.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}
                                            className={"client-table-row filter-table-rows"}
                                            key={row.original.id}>
                                            {row.cells.map((cell, j) => {
                                                return (
                                                    <td {...cell.getCellProps()} key={j}>
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}

                                        </tr>
                                    );
                                })}
                                </tbody>
                            </>
                    </Table>
                    <div
                        className={"d-flex justify-content-between flex-column flex-md-row align-items-center my-4 mx-3"}>
                        <div className={"d-flex flex-column justify-content-center align-items-center"}>
                            <div className={"mb-1"}>
                                Records Per Page
                            </div>
                            <div className="btn-group records-buttons-container" role="group">
                                {[10, 20, 30, 40, 50].map((pageSizeOption) => (
                                    <div
                                        key={pageSizeOption}
                                        role="button"
                                        className={`${pageSize === pageSizeOption ? 'record-button-selected' : 'record-button'}`}
                                        onClick={() => handlePageSizeChange(pageSizeOption)}
                                    >
                                        {pageSizeOption}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Pagination className={"data-table-pagination"}>
                            <Pagination.Prev onClick={() => handlePageChange(pageIndex - 1)} disabled={!canPreviousPage} />
                            {Array.from({ length: totalPages }).map((_, index) => {
                                if (totalPages <= 5 || index === 0 || index === totalPages - 1 || (index >= pageIndex - 2 && index <= pageIndex + 2)) {
                                    return (
                                        <Pagination.Item
                                            key={index}
                                            onClick={() => handlePageChange(index)}
                                            active={pageIndex === index}
                                        >
                                            {index + 1}
                                        </Pagination.Item>
                                    );
                                } else if (index === 1 || index === totalPages - 2) {
                                    return <Pagination.Ellipsis key={index} />;
                                }
                                return null;
                            })}
                            <Pagination.Next onClick={() => handlePageChange(pageIndex + 1)} disabled={!canNextPage} />
                        </Pagination>
                        <div className={`d-flex justify-content-between align-items-center ${classNames?.includes("admin-theme") ? "text-white" : "text-dark"}`}>
                            <div className="me-2 d-flex justify-content-start">
                                <div>
                                    Page{' '}
                                </div>
                                <strong className={"d-flex justify-content-start"}>
                                    <div>
                                        <input
                                            className="page-number-input"
                                            type="number"
                                            value={currentPage}
                                            min={1}
                                            max={totalPages}
                                            onChange={e => setCurrentPage(e.target.value)}
                                        />
                                    </div>
                                    <div className={"me-2"}>
                                        of {totalPages}
                                    </div>
                                </strong>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}

export default OrdersDataTable;