import WidgetsRow from "../../../components/Admin/Dashboard/WidgetsRow";
import Statistics from "../../../components/Admin/Dashboard/Statistics";
import "./admin-dashboard.css";
const AdminDashboard = () => {
    return (
        <>
            <WidgetsRow />
            <Statistics />
        </>
    );
};

export default AdminDashboard;