import Chart from 'react-apexcharts';

const EcommerceStatisticsChart = () => {
    const chartData = {
        options: {
            chart: {
                id: 'ecommerce-chart',
                animations: {
                    enabled: true,
                    easing: 'linear',
                    dynamicAnimation: {
                        speed: 1000
                    }
                },
                toolbar: {
                    show: false
                }
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                labels: {
                    style: {
                        colors: '#F2F2F2' // Color of the x-axis labels
                    }
                }
            },
            yaxis: {
                title: {
                    text: 'Revenue (USD)',
                    style: {
                        color: '#F2F2F2' // Color of the y-axis title
                    }
                },
                labels: {
                    style: {
                        colors: '#F2F2F2' // Color of the y-axis labels
                    }
                }
            },
            legend: {
                position: 'top',
                labels: {
                    colors: '#F2F2F2' // Color of the legend labels
                }
            },
            colors: ['#DAA21C', '#DFF1FF', '#4B566A'], // Colors of the lines
            tooltip: {
                theme: 'dark' // Theme for the tooltip
            }
        },
        series: [
            {
                name: 'Sales',
                data: [220, 230, 250, 200, 210, 190, 230, 240, 260, 250, 280, 300]
            },
            {
                name: 'Orders',
                data: [200, 210, 220, 180, 190, 180, 200, 210, 220, 210, 240, 260]
            },
            {
                name: 'Visitors',
                data: [180, 190, 200, 160, 170, 160, 180, 190, 200, 190, 210, 230]
            }
        ]
    };

    return (
        <>
            <h5 className={"fw-bold m-4"}>
                Revenue
            </h5>
                    <Chart
                        options={chartData.options}
                        series={chartData.series}
                        type="line"
                        width="100%"
                        height="400"
                    />
        </>
    );
}

export default EcommerceStatisticsChart;