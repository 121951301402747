import GenericGridComponent from "../Shared-Components/GenericGrid.component";

const AbandonedBasketsComponent = () => {
    const data = [{value: 0, type: "basket", description: "Number of abandoned baskets"}, {
        value: 0,
        type: "basket",
        description: "The number of abandoned baskets that were converted into orders"
    }, {value: "0 %", description: "Conversion rate"}, {
        value: 0, type: "SAR", description: "Total value of abandoned baskets"
    }, {
        value: 0, type: "SAR", description: "Total sales of abandoned carts converted into orders"
    }];

    const dataSecondGrid = [{
        title: "Path of abandoned baskets", description: "There is no matching data"
    }, {title: "Basket abandonment stage", description: "There is no matching data"}, {
        title: "Total abandoned cart sales", description: "There is no matching data"
    }, {title: "Conversion rate", description: "There is no matching data"}];
    return (<GenericGridComponent data={data} dataSecondGrid={dataSecondGrid}/>);
};

export default AbandonedBasketsComponent;
