import ChatComponent from "./Chat.component";
import {PiChatsTeardropLight} from "react-icons/pi";
import "./floating-icon.css"
const FloatingIconComponent = ({ chatRef, isOpen, setIsOpen }) => {
    const toggleQaSection = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={"faq-container"}>
            {isOpen &&
                <div ref={chatRef}>
                    <ChatComponent />
                </div>}
            <PiChatsTeardropLight className={"floating-qa-icon"} onClick={toggleQaSection} />
        </div>
    );
};

export default FloatingIconComponent;
