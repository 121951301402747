import { useState } from 'react';
import DateRange from "react-date-range/dist/components/DateRange";
import DefinedRange from "react-date-range/dist/components/DefinedRange";

const AnalyticsDatePickerComponent = ({ handleDateRangeChange, initialDateRange }) => {
    const [state, setState] = useState([
        {
            startDate: initialDateRange.startDate || new Date(), // Use the initialDateRange prop or today's date
            endDate: initialDateRange.endDate || null,
            key: 'selection'
        }
    ]);

    return (
        <div className={"d-flex justify-content-between align-content-center align-items-center"}>
            <DateRange
                editableDateInputs={true}
                onChange={item => {
                    setState([item.selection]);
                    handleDateRangeChange([item.selection]);
                }}
                moveRangeOnFirstSelection={false}
                ranges={state}
            />
            <DefinedRange
                onChange={item => {
                    setState([item.selection]);
                    handleDateRangeChange([item.selection]);
                }}
                ranges={state}
            />;
        </div>
    );
};

export default AnalyticsDatePickerComponent;
