import {api, BackEnd_EndPoint} from "../config";
import {toast} from "react-toastify";

const CategoryService = {
    getAllCategories: async () => {
        try {
            const response = await api.get(`${BackEnd_EndPoint}category`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    },

    addCategory: async (categoryData) => {
        try {
            const response = await api.post(`${BackEnd_EndPoint}category`, categoryData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error creating category:', error);
            throw error;
        }
    },

    updateCategory: async (categoryId, categoryData) => {
        try {
            const response = await api.put(`${BackEnd_EndPoint}category/${categoryId}`, categoryData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error updating category:', error);
            throw error;
        }
    },

    deleteCategory: async (categoryId) => {
        try {
            const response = await api.delete(`${BackEnd_EndPoint}category/${categoryId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            toast.success(response.data.message, {position: "bottom-right", theme: "dark"})
            return response.data;
        } catch (error) {
            toast.error(error.response.data.message, {position: "bottom-right", theme: "dark"})
            throw error;
        }
    },

    getSingleCategory: async (categoryId) => {
        try {
            const response = await api.get(`${BackEnd_EndPoint}category/${categoryId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    },

    addSubcategory: async (subcategoryData) => {
        try {
            const response = await api.post(`${BackEnd_EndPoint}subCategory`, subcategoryData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success(response.data.message, {position: "bottom-right", theme: "dark"});
            return response.data;
        } catch (error) {
            toast.error(error.response.data.message, {position: "bottom-right", theme: "dark"});
            console.log(error)
        }
    },

    reactivateCategory: async (categoryId) => {
        try {
            const response = await api.put(`${BackEnd_EndPoint}category/${categoryId}`, {status: "active"}, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            toast.success(response.data.message, {position: "bottom-right", theme: "dark"});
            return response.data;
        } catch (error) {
            toast.error(error.response.data.message, {position: "bottom-right", theme: "dark"});
            throw error;
        }
    }

}
export default CategoryService;