import Row from "react-bootstrap/Row";
import {Button, Form} from "react-bootstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {AiFillPlusCircle} from "react-icons/ai";
import {addFeature, addFeatureOptions} from "../../features/feature/featureActions";
const AddFeatureForm = ({features, selectedFeatureId, setSelectedFeatureId}) => {
    const [createFeature, setCreateFeature] = useState(false);
    const dispatch = useDispatch();
    const clientId = useSelector(state => state?.auth?.userInfo?.id);

    const handleAddFeature = async (values) => {
        const {name} = values
        const params = {name, clientId}
        await dispatch(addFeature(params));
        setCreateFeature(false);
    }

    const handleAddFeatureOptions = async (values) => {
        const {name} = values
        const featureId = parseInt(selectedFeatureId)
        const params = {featureId, name, clientId}
        await dispatch(addFeatureOptions(params))
    }

    const addFeatureValidationSchema = yup.object().shape({
        name: yup.string().required("Feature is required"),
    })

    const addFeatureOptionValidationSchema = yup.object().shape({
        name: yup.string().required("Feature Option is required"),
    })

    const handleSelectedFeature = async (event) => {
        const featureId = event.target.value;
        setSelectedFeatureId(featureId);
    }

    return (<div className={"p-3"}>
            <Formik
                initialValues={{name: ''}}
                onSubmit={handleAddFeature}
                validationSchema={addFeatureValidationSchema}>
                {({handleSubmit, handleChange, values, touched, errors}) => (<Form onSubmit={handleSubmit}>
                        <Row>
                            <h4 className={"text-center"}>
                                {createFeature ? "Create New Feature" : "Select Feature"}</h4>
                            {createFeature ?
                                <>
                                        <Form.Group>
                                            <Form.Control
                                                type={"text"}
                                                name={"name"}
                                                onChange={handleChange}
                                                onBlur={handleChange}
                                                value={values.name}
                                                placeholder={"Name"}
                                                isInvalid={!!errors.name && touched.name}
                                                isValid={!errors.name && touched.name}
                                                className={"mb-3"}
                                            />
                                            {errors.name && touched.name &&
                                                <Form.Control.Feedback type={"invalid"}>{errors.name}</Form.Control.Feedback>}
                                        </Form.Group>
                                <div className={"d-flex justify-content-center"}>
                                    <Button type={"submit"} className={"submit-btn rounded-pill border-0 px-3"}>Add</Button>
                                    <Button type={"button"} className={"bg-danger rounded-pill border-0 px-3 ms-3"} onClick={()=>setCreateFeature(false)}>Cancel</Button>
                                </div>
                                </>
                                :
                                <div className={"d-flex justify-content-between align-items-center"}>
                                    <Form.Select aria-label="Default select example" onChange={handleSelectedFeature}>
                                        <option value="" disabled>Select a feature...</option>
                                        {features?.map(feature => <option key={feature.id} value={feature.id}>{feature.name}</option>)}
                                    </Form.Select>
                                    <div className={"rounded-circle bg-white"} onClick={()=>setCreateFeature(true)}>
                                        <AiFillPlusCircle className={"text-primary-emphasis"} size={30}/>
                                    </div>
                                </div>

                            }
                        </Row>
                    </Form>)}
            </Formik>
        {createFeature ? null :
            selectedFeatureId ?
            <Formik initialValues={{name: ''}} onSubmit={handleAddFeatureOptions} validationSchema={addFeatureOptionValidationSchema}>
                {({handleSubmit, handleChange, values, touched, errors}) => (<Form onSubmit={handleSubmit}>
                    <Row className={"mt-5"}>
                        <h4 className={"text-center"}>Add Feature Options</h4>
                        <Form.Group>
                            <Form.Control
                                type={"text"}
                                name={"name"}
                                onChange={handleChange}
                                onBlur={handleChange}
                                value={values.name}
                                placeholder={"Feature Option"}
                                isInvalid={!!errors.name && touched.name}
                                isValid={!errors.name && touched.name}
                                className={"mb-3"}
                            />
                            {errors.name && touched.name &&
                                <Form.Control.Feedback type={"invalid"}>{errors.name}</Form.Control.Feedback>}
                        </Form.Group>
                    </Row>
                    <center>
                        <Button type={"submit"} className={"submit-btn rounded-pill px-3"}>Add</Button>
                    </center>

                </Form>)}
            </Formik>
                : null
        }
        </div>);
};

export default AddFeatureForm;