import { useState } from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';
import {Table, Form, Button} from 'react-bootstrap';
import { MdOutlineModeEdit, MdOutlineDone } from 'react-icons/md';
import { FaRegTrashAlt } from 'react-icons/fa';
import {NavLink} from "react-router-dom";

const DraftsPage = () => {
    const [draftsData, setDraftsData] = useState([
        {
            draftNumber: 1,
            customerName: 'Mark',
            dateOfApplication: '2023-01-01',
            modificationDate: '2023-01-02',
            total: '$100',
        },
        {
            draftNumber: 2,
            customerName: 'Jacob',
            dateOfApplication: '2023-01-03',
            modificationDate: '2023-01-04',
            total: '$150',
        },
        {
            draftNumber: 3,
            customerName: 'Larry the Bird',
            dateOfApplication: '2023-01-05',
            modificationDate: '2023-01-06',
            total: '$200',
        },
    ]);

    const [editingDraftNumber, setEditingDraftNumber] = useState(null);
    const [editedDraft, setEditedDraft] = useState({});

    const handleEdit = (draftNumber) => {
        setEditingDraftNumber(draftNumber);
        setEditedDraft({ ...draftsData.find(draft => draft.draftNumber === draftNumber) });
    };

    const handleSave = () => {
        const updatedDrafts = draftsData.map(draft =>
            draft.draftNumber === editingDraftNumber ? editedDraft : draft
        );
        setDraftsData(updatedDrafts);
        setEditingDraftNumber(null);
        setEditedDraft({});
    };

    const handleInputChange = (e, key) => {
        setEditedDraft({ ...editedDraft, [key]: e.target.value });
    };

    const handleCancelEdit = () => {
        setEditingDraftNumber(null);
        setEditedDraft({});
    };

    const handleDelete = (draftNumber) => {
        const updatedDrafts = draftsData.filter(draft => draft.draftNumber !== draftNumber);
        setDraftsData(updatedDrafts);
    };

    return (
        <>
            <Button className={'create-request-button border-0'} as={NavLink} to={"/create-request"}>
                <div className={'d-flex justify-content-between align-items-center'}>
                    <div className={'me-2'}>
                        Create a new request
                    </div>
                    <div>
                        <AiFillPlusCircle color={'white'} />
                    </div>
                </div>
            </Button>
            <h3 className={'fw-bold mainColor my-4'}>
                Drafts
            </h3>
            <Table hover className={'drafts-table text-center'}>
                <thead>
                <tr>
                    <th>Draft Number</th>
                    <th>Customer Name</th>
                    <th>The Date Of Application</th>
                    <th>Modification Date</th>
                    <th>The Total</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {draftsData.map((draft) => (
                    <tr key={draft.draftNumber}>
                        <td>
                            {editingDraftNumber === draft.draftNumber ? (
                                <Form.Control type="text" value={editedDraft.draftNumber} onChange={(e) => handleInputChange(e, 'draftNumber')} />
                            ) : (
                                draft.draftNumber
                            )}
                        </td>
                        <td>
                            {editingDraftNumber === draft.draftNumber ? (
                                <Form.Control type="text" value={editedDraft.customerName} onChange={(e) => handleInputChange(e, 'customerName')} />
                            ) : (
                                draft.customerName
                            )}
                        </td>
                        <td>
                            {editingDraftNumber === draft.draftNumber ? (
                                <Form.Control type="text" value={editedDraft.dateOfApplication} onChange={(e) => handleInputChange(e, 'dateOfApplication')} />
                            ) : (
                                draft.dateOfApplication
                            )}
                        </td>
                        <td>
                            {editingDraftNumber === draft.draftNumber ? (
                                <Form.Control type="text" value={editedDraft.modificationDate} onChange={(e) => handleInputChange(e, 'modificationDate')} />
                            ) : (
                                draft.modificationDate
                            )}
                        </td>
                        <td>
                            {editingDraftNumber === draft.draftNumber ? (
                                <Form.Control type="text" value={editedDraft.total} onChange={(e) => handleInputChange(e, 'total')} />
                            ) : (
                                draft.total
                            )}
                        </td>
                        <td>
                            {editingDraftNumber === draft.draftNumber ? (
                                <div className={'d-flex justify-content-center'}>
                                    <MdOutlineDone color={'#28a745'} size={20} onClick={handleSave} role={'button'} className={'me-2'} />
                                    <MdOutlineModeEdit color={'#1A478F'} size={20} onClick={handleCancelEdit} role={'button'} />
                                </div>
                            ) : (
                                <div className={'d-flex justify-content-center'}>
                                    <MdOutlineModeEdit color={'#1A478F'} size={20} onClick={() => handleEdit(draft.draftNumber)} role={'button'} className={'me-2'} />
                                    <FaRegTrashAlt color={'#1A478F'} size={20} onClick={() => handleDelete(draft.draftNumber)} role={'button'} />
                                </div>
                            )}
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );
};

export default DraftsPage;