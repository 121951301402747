import {Col, InputGroup, Form, Row, Collapse} from "react-bootstrap";
import {FaSquarePlus} from "react-icons/fa6";
import {useState} from "react";
import FindNewClientModal from "./FindNewClientModal";

const CustomerInformationTabComponent = () => {
    const [someoneElse, setSomeoneElse] = useState(false);
    const [findNewClientModalShow, setFindNewClientModalShow] = useState(false);
    return (
        <div className={"tab-content"}>
            <Row className={"mainColor justify-content-evenly py-5"}>
                <Col lg={5} className={"fw-bold fs-5"}>
                    <p>
                        Select or create a client
                    </p>
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <InputGroup>
                            <Form.Control
                                placeholder="Find a client"
                                aria-label="find-a-client"
                                aria-describedby="find-a-client"
                            />
                        </InputGroup>
                        <FaSquarePlus role={"button"} className={"ms-3"} color={"#F18318"} size={35} onClick={()=>setFindNewClientModalShow(true)} />
                    </div>
                    <div className={"someone-else-toggle-container my-4"}>
                        <div>
                            Someone else will receive the shipment?
                        </div>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            onChange={() => setSomeoneElse(!someoneElse)}
                        />
                    </div>
                    <Collapse in={someoneElse}>
                    <Row className={"justify-content-evenly"}>
                        <Col lg={5} className={"fs-6"}>
                            <p>Recipient data</p>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Recipient's Name"
                                    aria-label="recipient"
                                    aria-describedby="recipient"
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="state-key" className={"mainBgColor"}>+966</InputGroup.Text>
                                <Form.Control
                                    placeholder="State Key"
                                    aria-label="state-key"
                                    aria-describedby="state-key"
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Mobile Number"
                                    aria-label="mobile-number"
                                    aria-describedby="mobile-number"
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="E-mail"
                                    aria-label="E-mail"
                                    aria-describedby="E-mail"
                                    type={"email"}
                                />
                            </InputGroup>
                        </Col>
                        <Col lg={5} className={"fs-6"}>
                            <p>Recipient's Address</p>
                            <Form.Select aria-label="Country" className="mb-3">
                                <option>Country</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                            <Form.Select aria-label="City" className="mb-3">
                                <option>City</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="District"
                                    aria-label="district"
                                    aria-describedby="district"
                                    type={"text"}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Street"
                                    aria-label="Street"
                                    aria-describedby="Street"
                                    type={"text"}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    </Collapse>

                    <div className={"someone-else-toggle-container my-4"}>
                        <div>
                            Does the recipient receive messages and request notifications?
                        </div>
                        <Form.Check
                            type="switch"
                            id="custom-switch-2"
                        />
                    </div>
                </Col>
                <Col lg={5}>
                    <p className={"fw-bold fs-5"}>
                        Delivery Address
                    </p>
                    <Form.Select aria-label="Country" className="mb-3">
                        <option>Country</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>
                    <Form.Select aria-label="City" className="mb-3">
                        <option>City</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder="District"
                            aria-label="district"
                            aria-describedby="district"
                            type={"text"}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder="Street"
                            aria-label="Street"
                            aria-describedby="Street"
                            type={"text"}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder="Building Number"
                            aria-label="building-number"
                            aria-describedby="building-number"
                            type={"number"}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder="Postal Code"
                            aria-label="postal-code"
                            aria-describedby="postal-code"
                            type={"number"}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder="Additional Code"
                            aria-label="additional-code"
                            aria-describedby="additional-code"
                            type={"number"}
                        />
                    </InputGroup>
                </Col>
            </Row>
            <FindNewClientModal onHide={()=>setFindNewClientModalShow(false)} show={findNewClientModalShow} />
        </div>
    );
};

export default CustomerInformationTabComponent;