import * as yup from 'yup';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import {Button, Form} from "react-bootstrap";
import {Formik} from 'formik';
import {useEffect, useState} from "react";
// import CheckPhoneApi from "../../services/auth/check-phone.api";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {registerUser} from "../../features/auth/authActions";
import {Link, useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import Container from "react-bootstrap/Container";
import {ReactSVG} from "react-svg";
import Logo from "../../assets/media/logo.svg";
const SignUpPage = () => {
    const [showPassword, setShowPassword] = useState(false);
    const { userInfo, error, success } = useSelector(
        (state) => state.auth
    )
    const dispatch = useDispatch();

    const SignUpSchema = yup.object().shape({
        name: yup.string().required('Name is required'),
        email: yup.string().email('Invalid email').required('Email is required'),
        phone: yup.string().required('Phone number is required'),
        password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    });
    const navigate = useNavigate();

    useEffect(() => {
        if (success) {
            navigate('/')
        }
        if (error) {
            toast.error(error, {position: "bottom-right", theme: "dark",});
        }
    }, [navigate, userInfo, success])

    const initialValues = {
        name: '',
        email: '',
        password: '',
        phone: '',
        rememberMe: false
    };

    const handleSignUpSubmit = async (values) => {
        // TODO making validation using check-phone api
        // const data =
        //     {
        //         phone: values.phone,
        //         email: values.email
        //     }
        try {
            const actionResult = await dispatch(registerUser(values));
            if (registerUser.fulfilled.match(actionResult)) {
                const { user, token, Permissions } = actionResult.payload;
                const expirationTime = values.rememberMe ? 7 : 1;
                Cookies.set('userInfo', JSON.stringify({ user, token }), { expires: expirationTime });
                const PermissionsNames = Permissions.map(permission => permission.name);
                Cookies.set('Permissions', JSON.stringify(PermissionsNames), { expires: expirationTime });
                toast.success("Sign up successfully", {position: "bottom-right", theme: "dark",})
            }
        }catch (err){
            toast.error(err.response.data.message, {position: "bottom-right", theme: "dark",});
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className={"authBackground"}>
        <Formik
            initialValues={initialValues}
            validationSchema={SignUpSchema}
            onSubmit={(values)=> handleSignUpSubmit(values)}
        >
            {({ values, handleChange, touched, errors, handleSubmit }) => (
                <Container className={"d-flex justify-content-evenly align-items-center flex-column h-100"}>
                    <ReactSVG src={Logo} />
                    <Form noValidate onSubmit={handleSubmit} className={"signUpForm"}>
                        <h1 className={"mb-2 mainColor fw-bold text-center mt-3"}>Create Account</h1>
                        <div className={"my-5"}>
                            <Form.Group>
                                <Form.Control name={"name"} className={"mb-3 authFormInput"} type="text"
                                              placeholder="Full Name"
                                              value={values.name}
                                              onChange={handleChange}
                                              isValid={touched.name && !errors.name}
                                              isInvalid={touched.name && errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control name={"email"} className={"mb-3 authFormInput"} type="email"
                                              placeholder="Email Address"
                                              value={values.email}
                                              onChange={handleChange}
                                              isValid={touched.email && !errors.email}
                                              isInvalid={touched.email && errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                 <Form.Control name={"phone"} className={"mb-3 authFormInput"} type="tel"
                                              placeholder="Phone Number"
                                              value={values.phone}
                                              onChange={handleChange}
                                              isValid={touched.phone && !errors.phone}
                                              isInvalid={touched.phone && errors.phone}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.phone}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className={"password-input-container"}>
                                <Form.Control name={"password"} className={"mb-3 authFormInput"}
                                              type={showPassword ? 'text' : 'password'}
                                              placeholder="Password"
                                              value={values.password}
                                              onChange={handleChange}
                                              isValid={touched.password && !errors.password}
                                              isInvalid={touched.password && errors.password}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                                <div className={"password-input-icon"} onClick={togglePasswordVisibility}>
                                    {showPassword ? <AiOutlineEye className={"password-toggle-icon"} size={25}/> :
                                        <AiOutlineEyeInvisible className={"password-toggle-icon"} size={25}/>}
                                </div>
                            </Form.Group>
                        </div>
                            <Button className={"submitBtn w-100"} type={"submit"}>
                                Submit
                            </Button>
                        <div className={"d-flex justify-content-between align-items-center"}>
                            <Form.Group className={"my-4 d-flex justify-content-between"}>
                                <Form.Check
                                    type="switch"
                                    id="rememberMe"
                                    name="rememberMe"
                                    value={values.rememberMe}
                                    onChange={handleChange}
                                    label="Keep Logged In"
                                />
                            </Form.Group>
                            <Link to={"/client/forgot-password"} className={"text-center text-decoration-none mainColor"}>
                                Forgot Password?
                            </Link>
                        </div>
                        <center>
                            have an account? <Link to={"/client/login"} className={"text-decoration-none mainColor"}>Sign In</Link>
                        </center>
                    </Form>
                </Container>
            )}
        </Formik>
        </div>
    );
};

export default SignUpPage;