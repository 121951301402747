import { BsBoxSeam, BsFillPinFill } from "react-icons/bs";
import product1 from "../../assets/media/product1.png";
import { LiaCoinsSolid } from "react-icons/lia";
import { TbBoxSeam } from "react-icons/tb";
import { PiBellLight, PiInfinityLight } from "react-icons/pi";
import { MdOutlineCategory } from "react-icons/md";
import * as yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { Form } from 'react-bootstrap';

const validationSchema = yup.object().shape({
    name: yup.string().required('Product Name is required'),
    price: yup.number().required('Price is required'),
    quantity: yup.number().required('Quantity is required'),
    category: yup.string().required('Category is required'),
});

const ProductRowComponent = ({ id, name, price, quantity, category, onUpdate, onMoreClick, onProductDataClick }) => {
    const initialValues = {
        name: name || '',
        price: price || '',
        quantity: quantity || '',
        category: category || '',
    };

    const handleSaveClick = (values) => {
        onUpdate(id, {...values });
    };

    return (
        <div className={"product-row px-0"}>
            <div className={"product-icons-container"}>
                <div>
                    <input type={"checkbox"}/>
                </div>
                <div className={"divider"}/>
                <div>
                    <BsFillPinFill/>
                </div>
            </div>
            <div className={"product-info-container"}>
                <div className={"d-flex justify-content-evenly"}>
                    <div className={"product-image-container"}>
                        <div className={"product-image-overlay"}/>
                        <img src={product1} className={"product-image"} alt={"product"}/>
                    </div>
                    <div className={"w-75 d-flex flex-column justify-content-evenly"}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => handleSaveClick(values)}
                        >
                            {({handleSubmit, values, errors, touched, handleChange}) => (
                                <Form onSubmit={handleSubmit} noValidate id={`update-product-form${id}`}>
                                    <div className={"d-flex justify-content-between"}>
                                        <div
                                            className={"rounded-button w-75 me-2 p-2 d-flex justify-content-start align-items-center"}>
                                            <div className={"me-2"}>
                                                <BsBoxSeam size={20}/>
                                            </div>
                                            <div>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Product Name"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={touched.name && errors.name}
                                                    style={{color: "#1A478F", width: "100%"}}
                                                />
                                                <ErrorMessage name="name" component="div" className="text-danger"/>
                                            </div>
                                        </div>
                                        <div className={"rounded-button d-flex justify-content-between w-25"}>
                                            <div className={"p-2 d-flex justify-content-start align-items-center"}>
                                                <div className={"me-2"}>
                                                    <LiaCoinsSolid size={20}/>
                                                </div>
                                                <div>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Price"
                                                        name="price"
                                                        value={values.price}
                                                        onChange={handleChange}
                                                        isInvalid={touched.price && errors.price}
                                                        style={{color: "#1A478F", width: "100%"}}
                                                    />
                                                    <ErrorMessage name="price" component="div" className="text-danger"/>
                                                </div>
                                            </div>
                                            <div className={"rounded-button-aside p-2"}>
                                                SAR
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"d-flex justify-content-start"}>
                                        <div
                                            className={"rounded-button w-50 me-2 d-flex justify-content-between align-items-center"}>
                                            <div className={"d-flex justify-content-start align-items-center p-2"}>
                                                <div className={"me-2"}>
                                                    <TbBoxSeam size={20}/>
                                                </div>
                                                <div>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Quantity"
                                                        name="quantity"
                                                        value={values.quantity}
                                                        onChange={handleChange}
                                                        isInvalid={touched.quantity && errors.quantity}
                                                        style={{color: "#1A478F", width: "100%"}}
                                                    />
                                                    <ErrorMessage name="quantity" component="div"
                                                                  className="text-danger"/>
                                                </div>
                                            </div>
                                            <div
                                                className={"d-flex justify-content-start align-items-center bg-sec-products p-2"}>
                                                <div>
                                                    <PiBellLight size={20}/>
                                                </div>
                                                <div className={"divider-primary align-self-stretch mx-1"}/>
                                                <div>
                                                    <PiInfinityLight size={20}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={"rounded-button w-50 d-flex justify-content-between align-items-center"}>
                                            <div className={"d-flex justify-content-start align-items-center p-2"}>
                                                <div className={"me-2"}>
                                                    <MdOutlineCategory size={20}/>
                                                </div>
                                                <div>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Category"
                                                        name="category"
                                                        value={values.category}
                                                        onChange={handleChange}
                                                        isInvalid={touched.category && errors.category}
                                                        style={{color: "#1A478F", width: "100%"}}
                                                    />
                                                    <ErrorMessage name="category" component="div"
                                                                  className="text-danger"/>
                                                </div>
                                            </div>
                                            <div className={"bg-sec-products p-2"}>
                                                Add category
                                            </div>
                                        </div>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <div className={"product-row-actions text-center"}>
                <div className={"d-flex justify-content-center align-content-center"}>
                    <div className={"rounded-button w-50 me-2 py-2 px-2"}
                         onClick={onMoreClick}>
                        More
                    </div>
                    <div className={"rounded-button w-50 py-2 px-2"}
                         onClick={onProductDataClick}>
                        Product Data
                    </div>
                </div>
                <button type="submit" form={`update-product-form${id}`}
                        className={"rounded-button-primary py-2 px-2 text-white"}>Save
                </button>
            </div>
        </div>
    );
};

export default ProductRowComponent;