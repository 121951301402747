import {Navigate} from 'react-router-dom';
import {useSelector} from "react-redux";

function Protected({isSignedIn,
                       // requiredRoles,
                       requiredPermissions,
                       children}) {
    const userInfo = useSelector((state) => state.auth.userInfo)
    console.log(userInfo)
    if (isSignedIn) {
        if (userInfo?.type === "admin"){
            return <Navigate to="/admin/dashboard"/>;
        } else if (requiredPermissions) {
            return <Navigate to="/unauthorized"/>;
        }
        return children;
    } else {
        return <Navigate to="/client/login"/>;
    }
}

export default Protected;