import HeaderComponent from "./Header/Header.component";
import SidebarComponent from "./Sidebar/Sidebar.component";
import FloatingIconComponent from "./Floating-Icon/FloatingIcon.component";
import {useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import {Outlet} from "react-router-dom";
import {ToastContainer} from "react-toastify";
const Layout = () => {
    const chatRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleWrapperClick = (e) => {
        if (chatRef.current && !chatRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    return (
        <div className={"d-flex justify-content-between mt-2"} onClick={handleWrapperClick}>
            <ToastContainer />
            <div className={"px-3"}>
                <SidebarComponent />
            </div>
            <Container>
                <main className={"flex-grow-1"}>
                    <HeaderComponent />
                    <Outlet />
                    <FloatingIconComponent chatRef={chatRef} isOpen={isOpen} setIsOpen={setIsOpen} />
                </main>
            </Container>
        </div>
    );
};

export default Layout;
