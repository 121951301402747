import {Button, Dropdown, InputGroup, Stack} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {BiSearch} from "react-icons/bi";
import {MdOutlineNightlight} from "react-icons/md";
import {HiOutlineGift} from "react-icons/hi";
import {TbBellRinging} from "react-icons/tb";
import userIMG from "../../../assets/media/user.png";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import {IoIosLogIn, IoIosLogOut} from "react-icons/io";
import {Link} from "react-router-dom";
import "./header.css";
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../../../features/auth/authActions";

const HeaderComponent = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(
        (state) => state.auth
    )
    return (
        <Container className={"mb-5"}>
            <Row className="justify-content-center mt-3">
                <Stack direction={"horizontal"} className={"justify-content-between align-items-start align-items-md-center flex-column flex-lg-row"}>
                    <Button variant="outline-primary text-nowrap rounded-pill">
                        Help Center
                    </Button>
                    <div className={"search-box my-2"}>
                        <div className={"search-box_input"}>
                            <InputGroup size="lg">
                                <Form.Control
                                    placeholder={"Search by order number, customer name, shipment number"}
                                    aria-label="Large"
                                    aria-describedby="inputGroup-sizing-sm"
                                />
                            </InputGroup>
                        </div>
                        <div className={"d-flex justify-content-between align-items-center"}>
                            <Form.Select size="lg"  className={"search-box_select"}>
                                <option>Requests</option>
                                <option value={"1"}>Large select</option>
                                <option value={"2"}>Large select</option>
                            </Form.Select>
                            <div className={"search-box_icon"}>
                                <BiSearch size={25} />
                            </div>
                        </div>

                    </div>
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <MdOutlineNightlight size={25} color={"#1A478F"} className={"home-header-icon"} />
                        <HiOutlineGift size={25} color={"#1A478F"} className={"home-header-icon mx-2"} />
                        <TbBellRinging size={25} color={"#1A478F"} className={"home-header-icon"} />
                        <div className={"profile-picture_container"}>
                            <div>
                                <Dropdown className="d-inline mx-2 profile-dropdown-container">
                                    <Dropdown.Toggle id="dropdown-autoclose-true" className={"profile-dropdown-button"}>
                                        <img src={userIMG} alt={"Profile Pic"} className={"profile-picture"}/>
                                        <div className={"profile-picture_title"}>
                                            Pro
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className={"profile-dropdown-menu p-3"}>
                                        <div className={"text-center"}>
                                        <img src={userIMG} alt={"Profile Pic"}
                                                 className={"profile-picture-dropdown"}/>
                                            <p>

                                            </p>
                                            <button className={"pricing-button text-uppercase"}>
                                                pricing
                                            </button>
                                            <div className={"text-start mt-3"}>
                                                <div>
                                                    User Guide
                                                </div>
                                                <div>
                                                    Support
                                                </div>
                                                {userInfo ? <div className={"d-flex justify-content-between text-danger"}
                                                                 onClick={() => dispatch(logoutUser())} role={"button"}>
                                                    <div>
                                                        Logout
                                                    </div>
                                                    <div>
                                                        <IoIosLogOut/>
                                                    </div>
                                                </div> : <Link to={"/client/login"}
                                                               className={"d-flex justify-content-between text-success"}>
                                                    <div>
                                                        Login
                                                    </div>
                                                    <div>
                                                        <IoIosLogIn/>
                                                    </div>
                                                </Link>}
                                            </div>
                                        </div>

                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </Stack>
            </Row>
        </Container>
    );
};

export default HeaderComponent;
