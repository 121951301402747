import {Card} from "react-bootstrap";
import product from "../../assets/media/placeholder.png"
import {TbBoxSeam} from "react-icons/tb";
import {PiBellLight, PiInfinityLight} from "react-icons/pi";
import {BsBoxSeam, BsFillPinFill, BsImage} from "react-icons/bs";
import {LiaCoinsSolid} from "react-icons/lia";
import {MdOutlineCategory} from "react-icons/md";
import * as yup from "yup";
import {ErrorMessage, Formik} from "formik";
import {Form} from "react-bootstrap";
import {Button} from "react-bootstrap";
import CenteredModal from "../Modals/CenteredModal/CenteredModal";
import {useState} from "react";
import AddProductSteps from "./AddProductSteps";

const ProductSchema = yup.object().shape({
    name: yup.string().required('Product Name is required'),
    price: yup.number().required('Price is required'),
    quantity: yup.number().required('Quantity is required'),
    category: yup.string().required('Category is required'),
});
const ProductColumnComponent = ({ id, name, price, quantity, category, onUpdate }) => {
    const [show, setShow] = useState(false);

    const initialValues = {
        name: name || '',
        price: price || '',
        quantity: quantity || '',
        category: category || '',
    };

    const handleSaveClick = (values) => {
        onUpdate(id, {...values });
    };

    return (
        <Card style={{ width: '20rem' }} className={"product-column-card"}>
            <Formik
                initialValues={initialValues}
                validationSchema={ProductSchema}
                onSubmit={(values) => handleSaveClick(values)}
            >
                {({ handleSubmit, handleChange, values, errors }) => (
                    <Form onSubmit={handleSubmit}>
            <div className={"position-relative"}>
                <div className={"product-image-overlay"}>
                    <div className={"product-icons-column-container pt-3"}>
                        <div>
                            <BsFillPinFill color={"white"} size={25} />
                        </div>
                        <div>
                            <input  type={"checkbox"} />
                        </div>
                    </div>
                </div>
                <Card.Img variant="top" src={product} alt={"product image"} />
                <div className={"product-image-footer-overlay"}>
                    <div className={"product-image-footer-overlay-content"}>
                        <div className={"me-2"}>
                            <BsImage />
                        </div>
                        <div>
                            إضافة صورة أو فيديو
                        </div>
                    </div>
                </div>
            </div>
            <Card.Body>
                <div className={"rounded-button-column p-2 d-flex justify-content-start align-items-center mb-2"}>
                    <div className={"me-2"}>
                        <BsBoxSeam size={20} />
                    </div>
                    <Form.Group controlId="productName" className="mb-2">
                        <Form.Control
                            type="text"
                            placeholder="Product Name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                            size={"sm"}
                        />
                        <ErrorMessage
                            name="name"
                            component={Form.Control.Feedback}
                            type="invalid"
                        />
                    </Form.Group>
                </div>
                <div className={"rounded-button-column d-flex justify-content-between mb-2"}>
                    <div className={"p-2 d-flex justify-content-start align-items-center"}>
                        <div className={"me-2"}>
                            <LiaCoinsSolid size={20} />
                        </div>
                        <Form.Group controlId="productPrice" className="mb-2">
                            <Form.Control
                                type="number"
                                placeholder="Price"
                                name="price"
                                value={values.price}
                                onChange={handleChange}
                                isInvalid={!!errors.price}
                                size={"sm"}
                            />
                            <ErrorMessage
                                name="price"
                                component={Form.Control.Feedback}
                                type="invalid"
                            />
                        </Form.Group>
                    </div>
                    <div className={"rounded-button-column-aside p-2 bg-primary-products"}>
                        SAR
                    </div>
                </div>
                <div className={"rounded-button-column d-flex justify-content-between align-items-center mb-2"}>
                    <div className={"d-flex justify-content-start align-items-center p-2"}>
                        <div className={"me-2"}>
                            <TbBoxSeam size={20} />
                        </div>
                        <Form.Group controlId="productQuantity" className="mb-2">
                            <Form.Control
                                type="number"
                                placeholder="Quantity"
                                name="quantity"
                                value={values.quantity}
                                onChange={handleChange}
                                isInvalid={!!errors.quantity}
                                size={"sm"}
                            />
                            <ErrorMessage
                                name="quantity"
                                component={Form.Control.Feedback}
                                type="invalid"
                            />
                        </Form.Group>
                    </div>
                    <div className={"d-flex justify-content-start align-items-center bg-primary-products p-2"}>
                        <div>
                            <PiBellLight size={20} />
                        </div>
                        <div className={"divider align-self-stretch mx-1"} />
                        <div>
                            <PiInfinityLight size={20} />
                        </div>
                    </div>
                </div>
                <div className={"rounded-button-column d-flex justify-content-between align-items-center"}>
                    <div className={"d-flex justify-content-start align-items-center p-2"}>
                        <div className={"me-2"}>
                            <MdOutlineCategory size={20} />
                        </div>
                        <Form.Group controlId="productCategory" className="mb-2">
                            <Form.Control
                                type="text"
                                placeholder="Category"
                                name="category"
                                value={values.category}
                                onChange={handleChange}
                                isInvalid={!!errors.category}
                                size={"sm"}
                            />
                            <ErrorMessage
                                name="category"
                                component={Form.Control.Feedback}
                                type="invalid"
                            />
                        </Form.Group>
                    </div>
                    <div className={"bg-primary-products p-2"}>
                        Add category
                    </div>
                </div>
            </Card.Body>
            <div className={"product-column-actions text-center p-3"}>
                <div className={"d-flex justify-content-center align-content-center my-3"}>
                    {/*<div className={"rounded-button w-50 me-2 py-2 px-2"} onClick={onMoreClick}>*/}
                    {/*    More*/}
                    {/*</div>*/}
                    {/*<div className={"rounded-button w-50 py-2 px-2"} onClick={onProductDataClick}>*/}
                    {/*    Product Data*/}
                    {/*</div>*/}
                </div>
                <Button variant="primary" type="submit"  className={"rounded-button-primary py-2 px-2 text-white"}>
                    Save
                </Button>
            </div>

                    </Form>
                )}
            </Formik>
            <CenteredModal show={show} onHide={()=> setShow(!show)}>
                <AddProductSteps />
            </CenteredModal>
        </Card>
    );
};

export default ProductColumnComponent;
