import {Button, Col, Form, InputGroup, Pagination, Row} from "react-bootstrap";
import {BsFillCaretDownFill} from "react-icons/bs";
import {useMemo, useState} from "react";
import {useAsyncDebounce, useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import {BiSearch} from "react-icons/bi";

function GlobalFilter({
                          preGlobalFilteredRows, globalFilter, setGlobalFilter,
                      }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (<div className={"position-relative"}>
        <InputGroup>
            <Form.Control
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
                className={"search-input"}
            />
        </InputGroup>
        <div className={"search-icon"}>
            <BiSearch color={"#000"} size={20}/>
        </div>
    </div>)
}

function DefaultColumnFilter({
                                 column: {filterValue, preFilteredRows, setFilter},
                             }) {
    const count = preFilteredRows.length

    return (<input
        className="form-control"
        value={filterValue || ''}
        onChange={e => {
            setFilter(e.target.value || undefined)
        }}
        placeholder={`Search ${count} records...`}
    />)
}

const CustomersTable = ({columns, data}) => {
    const defaultColumn = useMemo(() => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
    }), [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        setFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize, globalFilter},
    } = useTable({
        columns, data, defaultColumn, initialState: {pageIndex: 0, pageSize: 5},
    }, useFilters, useGlobalFilter, useSortBy, usePagination);

    const handleClearFilters = () => {
        setGlobalFilter('');
        columns.forEach((column) => {
            if (column.accessor) {
                setFilter(column.accessor, undefined);
            }
        });
    };

    return (<div className={"all-clients-table py-3"}>
        <>
            <Row className={"justify-content-start align-items-center my-4"}>
                <Col lg={5}>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </Col>
                <Col lg={5}>
                    <Button variant={"outline-success"} className={"clear-filters rounded-pill"} onClick={handleClearFilters}>
                        Clear Filters
                    </Button>
                </Col>
            </Row>
        </>
        <table className="table text-center" {...getTableProps()}>
            <thead>
            {headerGroups?.map((headerGroup) => (<tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup?.headers?.map((column) => (<th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                  {column.isSorted ? (column.isSortedDesc ? (' 🔽') : (' 🔼')) : (<>
                      {' '}
                      {column.accessor && <BsFillCaretDownFill/>}
                  </>)}
                </span>
                </th>))}
            </tr>))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {page?.map((row, i) => {
                prepareRow(row);
                return (<tr {...row.getRowProps()} key={i} className={"client-table-row"}>
                    {row?.cells?.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                </tr>);
            })}
            </tbody>
        </table>
        <div className={"d-flex justify-content-between align-items-center my-4 mx-3"}>
            <div className={"d-flex justify-content-between align-items-center"}>
                <div className="me-2 d-flex justify-content-start">
                    <div>
                        Page{' '}
                    </div>
                    <strong className={"d-flex justify-content-start"}>
                        <div>
                            <input
                                className="page-number-input"
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(page);
                                }}
                            />
                        </div>
                        <div className={"me-2"}>
                            of {pageOptions.length}
                        </div>
                    </strong>
                </div>
            </div>
            <Pagination className={"data-table-pagination"}>
                <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                {Array.from({ length: pageOptions.length })?.map((_, index) => {
                    if (pageOptions.length <= 5 || index === 0 || index === pageOptions.length - 1 || (index >= pageIndex - 2 && index <= pageIndex + 2)) {
                        return (
                            <Pagination.Item
                                key={index}
                                onClick={() => gotoPage(index)}
                                active={pageIndex === index}
                            >
                                {index + 1}
                            </Pagination.Item>
                        );
                    } else if (index === 1 || index === pageOptions.length - 2) {
                        return <Pagination.Ellipsis key={index} />;
                    }
                    return null;
                })}
                <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
            </Pagination>
            <div className={"d-flex flex-column justify-content-center align-items-center"}>
                <div className={"mb-1"}>
                    Records Per Page
                </div>
                <div className="btn-group records-buttons-container" role="group">
                    {[1, 5, 10, 20, 30, 40, 50].map((pageSizeOption) => (
                        <div
                            key={pageSizeOption}
                            role="button"
                            className={`${pageSize === pageSizeOption ? 'record-button-selected' : 'record-button'}`}
                            onClick={() => setPageSize(pageSizeOption)}
                        >
                            {pageSizeOption}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>)
}

export default CustomersTable;