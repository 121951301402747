import {createAsyncThunk} from '@reduxjs/toolkit';
import CustomerService from "../../services/customer/CustomerService";

export const fetchCustomers = createAsyncThunk('customer/fetchCustomers', async () => {
    try {
        return await CustomerService.getAllCustomers();
    } catch (error) {
        throw error;
    }
});

export const fetchCustomer = createAsyncThunk('customer/fetchCustomer', async (id) => {
    try {
        return await CustomerService.getSingleCustomer(id);
    } catch (error) {
        throw error;
    }
});

export const addCustomer = createAsyncThunk('customer/addCustomer', async (params) => {
    const data = {client_id: params.clientId, name: params.name, phone: params.phone, city: params.city, description: params.description, apartment: params.apartment, floor: params.floor};
    try {
        return await CustomerService.addCustomer(data);
    } catch (error) {
        throw error;
    }
})

export const updateCustomer = createAsyncThunk('customer/updateCustomer', async (params) => {
    const {customerId, customerData} = params;
    try {
        return await CustomerService.updateCustomer(customerId, customerData);
    } catch (error) {
        throw error;
    }
})


export const deleteCustomer = createAsyncThunk('customer/deleteCustomer', async (id) => {
    try {
        return await CustomerService.deleteCustomer(id);
    } catch (error) {
        throw error;
    }
})