import './LoadingAnimation.css';
import {ReactSVG} from "react-svg";
import loadingSVG1 from "../../assets/icons/loading1.svg";
import loadingSVG2 from "../../assets/icons/loading2.svg";
import loadingSVG3 from "../../assets/icons/loading3.svg";
import loadingSVG4 from "../../assets/icons/loading4.svg";
const LoadingAnimation = () => {
    return (
        <div className={"loading-container"}>
            <ReactSVG src={loadingSVG1} className={"loading-svg-1"} />
            <ReactSVG src={loadingSVG2} className={"loading-svg-2"} />
            <ReactSVG src={loadingSVG3} className={"loading-svg-3"} />
            <ReactSVG src={loadingSVG4} className={"loading-svg-4"} />
        </div>
    );
};

export default LoadingAnimation;