import "./sign-in_up.css";
import {Formik} from "formik";
import {Button, Form} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import * as yup from 'yup';
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";
import {toast, ToastContainer} from "react-toastify";
import {loginUser} from "../../features/auth/authActions";
import {useDispatch} from "react-redux";
import Container from "react-bootstrap/Container";
import Cookies from "js-cookie";
import {ReactSVG} from "react-svg";
import Logo from "../../assets/media/logo.svg";
const SignInPage = () => {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const signInValidationSchema = yup.object().shape({
        username: yup.string().required('Username is required'),
        password: yup.string().required('Password is required'),
    });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSignInSubmit = async (values) => {
        const trimmedValues = {
            username: values.username.replace(/\s/g, ''),
            password: values.password.trim(),
        };
        try {
            const action = await dispatch(loginUser(trimmedValues));
            if (loginUser.fulfilled.match(action)) {
                const { user, token, Permissions } = action.payload;
                const permissions = Permissions.map(permission => permission.name);
                const expirationTime = values.rememberMe ? 7 : 1;
                Cookies.set('userInfo', JSON.stringify({ user, token, permissions }), { expires: expirationTime });
                navigate('/', { replace: true });
            }
        } catch (err) {
            toast.error(err.response.data.message, { position: 'bottom-right', theme: 'dark' });
            console.error(err);
        }
    };

    return (
        <div className={"authBackground"}>
            <ToastContainer />
        <Formik
            initialValues={{
                username: '', password: '', rememberMe: false
            }}
            onSubmit={handleSignInSubmit}
            validationSchema={signInValidationSchema}>
            {({handleSubmit, handleChange, values, touched, errors}) => (
                <Container className={"d-flex justify-content-evenly align-items-center flex-column h-100"}>
                    <ReactSVG src={Logo} />
                    <Form noValidate onSubmit={handleSubmit} className={"signInForm"}>
                        <h1 className={"mb-2 mainColor fw-bold text-center mt-3"}>Sign In</h1>
                        <div className={"my-5"}>
                            <Form.Group>
                                <Form.Control name={"username"} className={"mb-3 authFormInput"}
                                              type={"text"}
                                              placeholder="Email or Phone Number"
                                              value={values.username}
                                              onChange={handleChange}
                                              isValid={touched.username && !errors.username}
                                              isInvalid={touched.username && errors.username}
                                />
                                {values.username && !isNaN(values.username) && (
                                    <small className="text-muted">
                                        Please include the country code for phone numbers.
                                    </small>
                                )}
                                <Form.Control.Feedback type="invalid">
                                    {errors.username}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className={"password-input-container "}>
                                <Form.Control name={"password"} className={"mb-3 authFormInput"}
                                              type={showPassword ? 'text' : 'password'}
                                              placeholder="Password"
                                              value={values.password}
                                              onChange={handleChange}
                                              isValid={touched.password && !errors.password}
                                              isInvalid={touched.password && errors.password}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                                <div className={"password-input-icon"} onClick={togglePasswordVisibility}>
                                    {showPassword ? <AiOutlineEye className={"password-toggle-icon"} size={25}/> :
                                        <AiOutlineEyeInvisible className={"password-toggle-icon"} size={25}/>}
                                </div>
                            </Form.Group>
                        </div>
                        <Button className={"submitBtn w-100"} type={"submit"}>
                            Get Started
                        </Button>
                        <div className={"d-flex justify-content-between align-items-center"}>
                            <Form.Group className={"my-4 d-flex justify-content-between"}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="Keep Logged In"
                                />
                            </Form.Group>
                            <Link to={"/client/forgot-password"} className={"text-center text-decoration-none mainColor"}>
                                Forgot Password?
                            </Link>
                        </div>
                        <center>
                            <span className={"opacity-50"}>
                                  i don't have an account ? {" "}
                            </span>
                            <Link to={"/client/register"} className={"text-decoration-none mainColor"}>Sign
                            Up</Link>
                        </center>
                    </Form>
                </Container>
            )}
        </Formik>
        </div>
    );
};

export default SignInPage;