import ClientsTable from "../../../components/Clients/ClientsTable";
import {useSelector} from "react-redux";
import {selectCurrentPage, selectRecordsPerPage, selectTotalPages} from "../../../features/pagination/paginationSlice";

const ClientsPage = () => {
    const totalPages = useSelector(selectTotalPages);
    const currentPage = useSelector(selectCurrentPage);
    const recordsPerPage = useSelector(selectRecordsPerPage);
    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Phone Number',
            accessor: 'phoneNumber',
        },
        {
            Header: 'Address',
            accessor: 'address',
        },
    ];

    const dummyData = [
        { id: 1, name: 'John Doe', email: 'john@example.com', phoneNumber: '123-456-7890', address: '123 Main St' },
        { id: 2, name: 'Jane Smith', email: 'jane@example.com', phoneNumber: '456-789-0123', address: '456 Elm St' },
        { id: 3, name: 'Alice Johnson', email: 'alice@example.com', phoneNumber: '789-012-3456', address: '789 Oak St' },
        // Add more dummy data as needed
    ];

    return (
        <div className={"admin-content-container"}>
            <h3 className={"text-center fw-bold"}>Clients</h3>
            <ClientsTable
                columns={columns}
                data={dummyData}
                totalPages={totalPages}
                currentPage={currentPage}
                recordsPerPage={recordsPerPage}
            />
        </div>
    );
};

export default ClientsPage;