import {createSlice} from '@reduxjs/toolkit';
import {addFeature, deleteFeature, fetchFeature, fetchFeatures, updateFeature, addFeatureOptions} from "./featureActions";

const initialState = {
    data: [], loading: false, error: null,
};

const featureSlice = createSlice({
    name: 'feature', initialState, reducers: {}, extraReducers: (builder) => {
        builder
            .addCase(fetchFeatures.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchFeatures.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
            })
            .addCase(fetchFeatures.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(fetchFeature.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchFeature.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
            })

            .addCase(fetchFeature.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(addFeature.pending, (state) => {
                state.loading = true;
            })
            .addCase(addFeature.fulfilled, (state, action) => {
                state.loading = false;
                state.data.push(action.payload.data);
            })
            .addCase(addFeature.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(deleteFeature.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteFeature.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.filter((feature) => feature.id !== action.payload.data.id);
            })
            .addCase(deleteFeature.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(updateFeature.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateFeature.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.map((feature) => feature.id === action.payload.data.id ? action.payload.data : feature);
            })
            .addCase(updateFeature.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action?.error?.message;
            })
            .addCase(addFeatureOptions.pending, (state) => {
                state.loading = true;
            })
            .addCase(addFeatureOptions.fulfilled, (state, action)=> {
                state.loading = false;
                const feature_id = action.meta.arg.featureId;
                const featureToUpdate = state.data.find((feature) => Number(feature.id) === Number(feature_id));
                if (featureToUpdate) {
                    featureToUpdate.options = [
                        ...(featureToUpdate.options || []),
                        action.payload.data
                    ];
                }
            })
    }
})

export default featureSlice.reducer;
