import React, {useState} from 'react';
import {Button, Card, Form} from "react-bootstrap";
import {ReactSVG} from "react-svg";
import Dots from "../../assets/media/dots.svg";
import {Previews} from "../../components/Category/PreviewDropZone";
import {useDispatch, useSelector} from "react-redux";
import {addCategory} from "../../features/category/categoryActions";
import {FaCircleXmark} from "react-icons/fa6";
import {FaCheck} from "react-icons/fa";

const NewProductCategory = ({setCreateCategory}) => {
    const [categoryName, setCategoryName] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null);
    const clientId = useSelector((state) => state.auth.userInfo.id)
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        setCategoryName(e.target.value);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const categoryData = {name: categoryName, image: uploadedFile, client_id: clientId};
        dispatch(addCategory(categoryData))
        setCreateCategory(false)
    };


    return (<div className={"mb-3"}>
        <Card>
            <Card.Header className="flex-container-centered">
                <div className="flex-container-centered">
                    <ReactSVG src={Dots}/>
                    <Form onSubmit={handleFormSubmit} id={"create-category-form"}
                          className="d-flex justify-content-between align-items-center">
                        <Form.Control
                            type="text"
                            value={categoryName}
                            onChange={handleInputChange}
                            className="me-2"
                        />
                        <Previews setUploadedFile={setUploadedFile} uploadedFile={uploadedFile}/>
                    </Form>
                </div>
                <div className="flex-container-centered">
                    <Button type="submit" form="create-category-form" variant="link"
                            className="text-decoration-none mainColor">
                        <FaCheck className={"mainColor"} size={20}/>
                    </Button>
                    <Button variant="link" className="text-decoration-none" onClick={() => setCreateCategory(false)}>
                        <FaCircleXmark color={"red"} size={20}/>
                    </Button>
                    {/*<ReactSVG src={actions} />*/}
                </div>
            </Card.Header>
        </Card>
    </div>);
};

export default NewProductCategory;