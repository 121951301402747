import {createSlice} from '@reduxjs/toolkit';
import {
    addFeatureOptions,
    deleteFeatureOptions,
    fetchFeatureOptions,
    fetchFeaturesOptions, updateFeatureOptions
} from "./featureOptionsActions";

const initialState = {
    data: [], loading: false, error: null,
};

const featureOptionsSlice = createSlice({
    name: 'featureOptions', initialState, reducers: {}, extraReducers: (builder) => {
        builder
            .addCase(fetchFeaturesOptions.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchFeaturesOptions.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
            })
            .addCase(fetchFeaturesOptions.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(fetchFeatureOptions.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchFeatureOptions.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
            })

            .addCase(fetchFeatureOptions.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(addFeatureOptions.pending, (state) => {
                state.loading = true;
            })
            .addCase(addFeatureOptions.fulfilled, (state, action) => {
                state.loading = false;
                state.data.push(action.payload.data);
            })
            .addCase(addFeatureOptions.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(deleteFeatureOptions.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteFeatureOptions.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.filter((feature) => feature.id !== action.payload.data.id);
            })
            .addCase(deleteFeatureOptions.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(updateFeatureOptions.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateFeatureOptions.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.map((feature) => feature.id === action.payload.data.id ? action.payload.data : feature);
            })
            .addCase(updateFeatureOptions.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
    }
})

export default featureOptionsSlice.reducer;