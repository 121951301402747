import GenericGridComponent from "../Shared-Components/GenericGrid.component";

const CustomersGridComponent = () => {
    const data = [{value: 21, type: "visits", description: "Visits"}, {
        value: 0,
        type: "client",
        description: "Customers"
    }, {value: "% 0", description: "Order conversion rate"}, {
        value: 5536,
        type: "SAR",
        description: "Return customer rate"
    }, {
        value: 5536,
        type: "SAR",
        description: "Average sales per customer",
        locked: true
    }, {
        value: 5536,
        type: "SAR",
        description: "Average number of orders per customer",
        locked: true
    }];

    const dataSecondGrid = [{
        title: "Stages of visits",
        description: "There is no matching data"
    }, {title: "Customers websites", description: "There is no matching data"}, {
        title: "Highest purchasing customers",
        description: "There is no matching data"
    }, {title: "Visiting times", description: "There is no matching data"}, {
        title: "Traffic sources",
        description: "There is no matching data",
        locked: true
    }];

    return (<GenericGridComponent data={data} dataSecondGrid={dataSecondGrid} />);
};

export default CustomersGridComponent;
