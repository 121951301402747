import "./AbandonedBaskets.css";
import Row from "react-bootstrap/Row";
import {ReactSVG} from "react-svg";
import basketSVG from "../../assets/media/basket.svg";
import {Button, Col} from "react-bootstrap";
import {TfiReload} from "react-icons/tfi";
import AbandonedBasketsTable from "../../components/AbandonedBaskets/AbandonedBasketsTable";


const AbandonedBasketsPage = () => {
    const columns = [{
        Header: 'Basket ID', accessor: 'id',
    }, {
        Header: 'Customer Name', accessor: 'customerName',
    }, {
        Header: 'Total Value', accessor: 'totalValue',
    }, {
        Header: 'Abandoned Date', accessor: 'abandonedDate',
    }, {
        Header: 'Status', accessor: 'status',
    }, {
        Header: 'Products', accessor: 'products', Cell: ({value}) => (<ul>
                {value.map(product => (<li key={product.id}>
                        {product.name} - ${product.price}
                    </li>))}
            </ul>)
    }];

    const dummyData = [{
        id: 1,
        customerName: 'John Doe',
        totalValue: 100,
        abandonedDate: '2024-03-10',
        status: 'Abandoned',
        products: [{id: 101, name: 'Product A', price: 50}, {id: 102, name: 'Product B', price: 30}, {
            id: 103,
            name: 'Product C',
            price: 20
        }]
    }, {
        id: 2,
        customerName: 'Jane Smith',
        totalValue: 150,
        abandonedDate: '2024-03-11',
        status: 'Abandoned',
        products: [{id: 104, name: 'Product D', price: 70}, {id: 105, name: 'Product E', price: 80}]
    }, {
        id: 3,
        customerName: 'Bob Johnson',
        totalValue: 200,
        abandonedDate: '2024-03-12',
        status: 'Abandoned',
        products: [{id: 106, name: 'Product F', price: 90}, {id: 107, name: 'Product G', price: 100}, {
            id: 108,
            name: 'Product H',
            price: 110
        }]
    },];
    return (<>
        <Row className={"abandoned-header align-items-center py-4"}>
            <Col lg={2} className={"text-center"}>
                <ReactSVG src={basketSVG}/>
            </Col>
            <Col lg={10}>
                <div className={"text-white"}>
                    <div className={"fw-bold fs-5 mb-3"}>
                        What is the abandoned basket?
                    </div>
                    <div>
                        It is the basket to which the customer added products and then forgot about them and did not
                        complete the purchase process. To motivate the customer to complete the order, you can
                        activate
                        a special temporary discount
                    </div>
                </div>
            </Col>
        </Row>
        <Row className={"content-container p-3 my-5"}>
            <div className={"mainColor fw-bold fs-5"}>
                Analytics
            </div>
            <div className={"mainColor"}>
                During the current month
            </div>
            <Row className={"justify-content-center justify-content-md-between mt-4"}>
                <Col lg={4} md={6} sm={12} className={"analytic-card p-2 mb-3"}>
                    <div className={"d-flex justify-content-between"}>
                        <div className={"fs-6"}>
                            Total value of abandoned baskets
                        </div>
                        <div style={{fontSize: "12px"}}>
                            0 baskets
                        </div>
                    </div>
                    <center>
                        <div className={"analytic-card-price"}>
                            SAR 0.00
                        </div>
                    </center>
                </Col>
                <Col lg={4} md={6} sm={12} className={"analytic-card p-2 mb-3"}>
                    <div className={"d-flex justify-content-between"}>
                        <div className={"fs-6"}>
                            Total value of abandoned baskets
                        </div>
                        <div style={{fontSize: "12px"}}>
                            0 baskets
                        </div>
                    </div>
                    <center>
                        <div className={"analytic-card-price"}>
                            SAR 0.00
                        </div>
                    </center>
                </Col>
                <Col lg={4} md={6} sm={12} className={"analytic-card p-2 mb-3"}>
                    <div className={"d-flex justify-content-between"}>
                        <div className={"fs-6"}>
                            Recovery percentage
                        </div>
                        <div style={{fontSize: "12px"}}>
                            From baskets to requests
                        </div>
                    </div>
                    <center>
                        <div className={"analytic-card-price"}>
                            SAR 0.00
                        </div>
                    </center>
                </Col>
            </Row>
        </Row>
        <div style={{
            borderRadius: "20px",
            background: "linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)",
            minHeight: "250px"
        }} className={"d-flex justify-content-around flex-column text-center"}>
            <h1 className={"mainColor fw-bold"}>
                Recover your abandoned baskets
            </h1>
            <small className={"mainColor"}>
                Motivate your customers to complete their purchase with automatic reminders
            </small>
            <Button className={"orange-button align-self-center py-2"} style={{width: "fit-content"}}>
                Activate automatic messages
            </Button>
        </div>

        <div style={{borderRadius: "20px 20px 0px 0px", background: "#F8F8F8", marginTop: "2rem", padding: "1rem"}}>
            <div className={"d-flex justify-content-between"}>
                <div className={"fs-4 mainColor fw-bold"}>
                    Abandoned baskets
                </div>
                <Button className={"blue-button d-flex justify-content-between align-content-center"}>
                    <div>
                        Baskets turned into requests
                    </div>
                    <div className={"ms-2"}>
                        <TfiReload/>
                    </div>

                </Button>
            </div>
            <Row>
                <AbandonedBasketsTable data={dummyData} columns={columns}/>
            </Row>
        </div>
    </>);
};

export default AbandonedBasketsPage;
