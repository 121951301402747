import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import productReducer from "../features/product/productSlice"
import categoryReducer from "../features/category/categorySlice"
import subcategoryReducer from "../features/subcategory/subcategorySlice"
import featureReducer from "../features/feature/featureSlice"
import featureOptionsReducer from "../features/feature/featureOptionsSlice"
import customerReducer from "../features/customer/customerSlice"
import paginationReducer from "../features/pagination/paginationSlice"

const store = configureStore({
    reducer: {
        auth: authReducer,
        products: productReducer,
        category: categoryReducer,
        subcategory: subcategoryReducer,
        feature: featureReducer,
        featureOptions: featureOptionsReducer,
        customer: customerReducer,
        pagination: paginationReducer
    }
})
export default store