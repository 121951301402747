import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import "./dropzone.css";
import {ReactSVG} from "react-svg";
import importIcon from "../../assets/media/import.svg";

export const Previews = ({setUploadedFile, uploadedFile}) => {
    const [fileError, setFileError] = useState('');
    const [isFilesAccepted, setIsFilesAccepted] = useState(false);

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: 'image/*',
        onDropAccepted: acceptedFiles => {
            const file = acceptedFiles[0];
            if (!file) {
                setFileError('Please upload a file');
                setUploadedFile(null);
                setIsFilesAccepted(false);
                return;
            }
            setUploadedFile(file);
            const acceptedFormats = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (!acceptedFormats.includes('.' + fileExtension)) {
                setFileError(`Please upload a file with one of the following extensions: ${acceptedFormats.join(', ')}`);
                setUploadedFile(null);
                setIsFilesAccepted(false);
                return;
            }

            setFileError('');
            setUploadedFile(file);
            setIsFilesAccepted(true);
            handleSubmit();
        },
        onDropRejected: () => {
            setFileError('Please upload a valid image file');
            setUploadedFile(null);
            setIsFilesAccepted(false);
        }
    });

    const handleSubmit = () => {
        // Handle submission logic here
    };

    const dropzoneClassName = `w-100 px-3 dropzone ${fileError ? 'error' : ''} ${isFilesAccepted ? 'active' : ''}`;

    return (
        <section className="container d-flex flex-column align-items-center justify-content-between">
            <div {...getRootProps({className: dropzoneClassName})}>
                <input {...getInputProps()} />
                <ReactSVG src={importIcon} />
                <div>Add Image</div>
            </div>
            {uploadedFile && (
                <div style={{ width: '100%', height: '100px' }}>
                    <img src={URL.createObjectURL(uploadedFile)} alt="Uploaded Preview" style={{ width: '100%', height: '100px', objectFit: "cover" }} />
                </div>
            )}
            {fileError && <p style={{ color: 'red' }} className={"text-wrap"}>{fileError}</p>}
        </section>
    );
};
