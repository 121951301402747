import { Form, Table, Button, InputGroup } from 'react-bootstrap';
import { FaRegImage } from 'react-icons/fa';
import { IoSearch } from 'react-icons/io5';
import CenteredModal from '../Modals/CenteredModal/CenteredModal';
import {FaRegCircleXmark} from "react-icons/fa6";
import product1 from "../../assets/media/product1.png";
import product2 from "../../assets/media/product2.png";
import {useState} from "react";

const dummyData = [
    {
        id: 1,
        productName: 'French group',
        stockStatus: 'Unlimited quantity in stock',
        originalPrice: 1200.0,
        discountedPrice: 380.0,
        image: product1,
    },
    {
        id: 2,
        productName: 'Product 2',
        stockStatus: 'Limited stock',
        originalPrice: 800.0,
        discountedPrice: 500.0,
    },
    {
        id: 3,
        productName: 'Product 3',
        stockStatus: 'Out of stock',
        originalPrice: 1500.0,
        discountedPrice: 1200.0,
        image: product2,
    },
];

const ChooseProductModal = ({ show, onHide }) => {
    const [hideOutOfStock, setHideOutOfStock] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const handleCheckboxChange = (productId) => {
        if (selectedItems.includes(productId)) {
            setSelectedItems((prevSelectedItems) =>
                prevSelectedItems.filter((id) => id !== productId)
            );
        } else {
            setSelectedItems((prevSelectedItems) => [...prevSelectedItems, productId]);
        }
    };

    const handleHideOutOfStockChange = () => {
        setHideOutOfStock((prevHideOutOfStock) => !prevHideOutOfStock);
    };

    const filteredData = hideOutOfStock
        ? dummyData.filter((product) => product.stockStatus !== 'Out of stock')
        : dummyData;

    const selectedItemCount = selectedItems.length;
    return (
        <CenteredModal show={show} onHide={onHide} size={'lg'}>
            <div className={'p-0 p-md-5'}>
                <div className={'position-relative'}>
                    <h3 className={'text-center fw-bold'}>Choose products</h3>
                    <div className={'product-modal-close-icon'} onClick={onHide}>
                        <FaRegCircleXmark size={25} color={'#FA5252'} />
                    </div>
                </div>
                <div className={'position-relative my-5'}>
                    <InputGroup size="lg">
                        <Form.Control
                            className={'product-modal-search'}
                            aria-label="Large"
                            aria-describedby="inputGroup-sizing-sm"
                            placeholder={'Search'}
                        />
                    </InputGroup>
                    <IoSearch className={'product-search-icon'} color={'#1A478F'} size={25} />
                </div>
                <Form.Check
                    type={'checkbox'}
                    id={'product-modal-checkbox'}
                    className={'outOfStock-checkbox my-5'}
                    label={'Do not show product that are not available in stock'}
                    onChange={handleHideOutOfStockChange}
                />

                <Table className={'product-modal-table'} bordered={false} hover responsive>
                    <tbody>
                    {filteredData.map((product) => (
                        <tr key={product.id}>
                            <td>
                                <Form.Check type={'checkbox'} id={`product-modal-checkbox-${product.id}`} onChange={() => handleCheckboxChange(product.id)} />
                            </td>
                            <td>
                                {product.image ? (
                                    <img src={product.image} alt={product.productName} className={'product-image'} />
                                ) : (
                                    <div className={'image-placeholder'}>
                                        <FaRegImage color={'#FFF'} />
                                    </div>
                                )}
                            </td>
                            <td>{product.productName}</td>
                            <td>{product.stockStatus}</td>
                            <td>
                                <s>{`SAR ${product.originalPrice.toFixed(2)}`}</s>
                            </td>
                            <td>
                                <Button className={'rounded-pill'}>{`SAR ${product.discountedPrice.toFixed(2)}`}</Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <div className={"d-flex justify-content-center"}>
                    <div className={"submit-button me-3"}>
                        Add selected products ({selectedItemCount})
                    </div>
                    <Button variant={"outline-danger"} className={"rounded-pill"} onClick={onHide}>
                        Cancel
                    </Button>
                </div>
            </div>
        </CenteredModal>
    );
};

export default ChooseProductModal;