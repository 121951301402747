import Row from "react-bootstrap/Row";
import {Col, Dropdown} from "react-bootstrap";
import {FaEnvelope, FaPhoneAlt, FaUser} from "react-icons/fa";
import Container from "react-bootstrap/Container";
import EditShippingInfoModal from "./EditShippingInfoModal";
import {useState} from "react";
import EditShippingInfoModalPaid from "./EditShippingInfoModalPaid";

const ShippingPaymentComponent = () => {
    const [shippingDeliveryModalShow, setShippingDeliveryModalShow] = useState(false);
    const [editShippingInfoModalShow, setEditShippingInfoModalShow] = useState(false);
    return (<Container className={"pt-5"}>
            <Row className={"justify-content-center"}>
                <Col lg={5} className={"user-info mainColor"}>
                    <p>
                        <FaUser/> {" "}
                        Mahmoud Al-Tayeb
                    </p>
                    <p>
                        <FaPhoneAlt/> {" "}
                        971555842553
                    </p>
                    <p>
                        <FaEnvelope/> {" "}
                        mahmudahmed23@yahoo.com
                    </p>
                </Col>
                <Col lg={5}>
                    <div className={"shipping-props"}>
                        <div className={"mainColor"}>
                            Shipping
                        </div>
                        <Dropdown>
                            <Dropdown.Toggle id="shipping-options-delivery" className={"mainColor shipping-toggle"}>
                                Requires shipping/delivery?
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={()=>setShippingDeliveryModalShow(true)}>Yes, it requires shipping/delivery?</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">No charging/delivery required?</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Col>
            </Row>
            <Row className={"justify-content-center mt-5"}>
                <Col lg={5}>
                    <div className={"address-props mainColor"}>
                        <div className={"mb-3"}>
                            Country:
                        </div>
                        <div className={"mb-3"}>
                            City:
                        </div>
                        <div className={"mb-3"}>
                            District:
                        </div>
                        <div className={"mb-3"}>
                            Street:
                        </div>
                    </div>
                </Col>
                <Col lg={5}>
                    <div className={"shipping-props"}>
                        <div className={"mainColor"}>
                            Paying off
                        </div>
                            <Dropdown>
                                <Dropdown.Toggle id="shipping-options-paying" className={"mainColor shipping-toggle"}>
                                    Has the payment been made?
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={()=>setEditShippingInfoModalShow(true)}>Yes, payment has been made</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">No, waiting for payment</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                    </div>
                </Col>
                <EditShippingInfoModal show={shippingDeliveryModalShow} onHide={()=>setShippingDeliveryModalShow(false)} />
                <EditShippingInfoModalPaid show={editShippingInfoModalShow} onHide={()=>setEditShippingInfoModalShow(false)} />
            </Row>
    </Container>);
};

export default ShippingPaymentComponent;