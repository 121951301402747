import React, {useState} from 'react';
import {ReactSVG} from "react-svg";
import hierarchy from "../../assets/media/hierarchy-tree.svg";
import Dots from "../../assets/media/dots.svg";
import {Previews} from "./PreviewDropZone";
import {useDispatch} from "react-redux";
// import {addSubcategory} from "../../features/subcategory/subcategoryActions";
import {Button, Form} from "react-bootstrap";
import {FaCircleXmark} from "react-icons/fa6";
import {toast} from "react-toastify";
import {FaCheck} from "react-icons/fa";
import {addSubCategory} from "../../features/category/categoryActions";

const NewSubCategory = ({categoryId, setCreateSubCategory}) => {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [subCategoryName, setSubCategoryName] = useState(null);
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        setSubCategoryName(e.target.value);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (subCategoryName === null) {
            toast.error("SubCategory Name is Required", {position: "bottom-right", theme: "dark"})
        } else {
            const params = {name: subCategoryName, image: uploadedFile, category_id: categoryId}
            dispatch(addSubCategory(params))
            setCreateSubCategory(false);
        }
    };

    return (<div className={"flex-container-centered px-3"}>

            <div className={"subCategory"}>
                <ReactSVG src={hierarchy}/>
                <div className={"mt-3 flex-container-centered"}>
                    <ReactSVG src={Dots}/>
                    <Form onSubmit={handleFormSubmit} id={"edit-category-form"}
                          className="d-flex justify-content-between align-items-center">
                        <Form.Control
                            type="text"
                            value={subCategoryName}
                            onChange={handleInputChange}
                            className="me-2"
                        />
                        <Previews setUploadedFile={setUploadedFile} uploadedFile={uploadedFile}/>
                    </Form>
                </div>
            </div>
            <div className={"flex-container-centered"}>
                <Button type="submit" form="edit-category-form" variant="link"
                        className="text-decoration-none mainColor">
                    <FaCheck className={"mainColor"} size={20}/>
                </Button>
                <Button variant="link" className="text-decoration-none" onClick={() => setCreateSubCategory(false)}>
                    <FaCircleXmark color={"red"} size={20}/>
                </Button>
            </div>

        </div>);
};

export default NewSubCategory;