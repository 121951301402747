import { createSlice } from '@reduxjs/toolkit';
import {loginUser, logoutUser, registerUser} from "./authActions";
import Cookies from "js-cookie";
const getInitialValuesFromCookies = () => {
    if (!Cookies.get('userInfo')) {
        return { userToken: null, userInfo: null };
    }
    const userData = JSON.parse(Cookies.get('userInfo'));
    const userToken = userData.token;
    const userInfo = userData.user;
    const userPermissions = userData.Permissions;
    return { userToken, userInfo, userPermissions };
};

const initialState = {
    loading: false,
    ...getInitialValuesFromCookies(),
    error: null,
    success: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(registerUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.userInfo = action.payload.user;
                state.userToken = action.payload.token;
            })
            .addCase(registerUser.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.userInfo = action.payload.user;
                state.userToken = action.payload.token;
                state.userPermissions = action.payload.Permissions.map(permission => permission.name);
            })
            .addCase(loginUser.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(logoutUser.fulfilled, (state) => {
                state.userInfo = null;
                state.userToken = null;
            });
    },
});

export default authSlice.reducer;