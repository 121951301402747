import { CChart } from "@coreui/react-chartjs";
import OrdersTable from "./OrdersTable";
import {Col} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import SyncCharts from "./EcommerceStatisticsChart";

const Statistics = () => {
    return (
        <Row>
                <Col lg={6} md={12} className={"admin-content-container"}>
                    <OrdersTable/>
                </Col>
                <Col lg={6} md={12} className={"admin-content-container"}>
                    <SyncCharts/>
                </Col>
        </Row>
    );
};

export default Statistics;
