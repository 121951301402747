import {createAsyncThunk} from '@reduxjs/toolkit';
import CategoryService from "../../services/category/CategoryService";

export const fetchCategories = createAsyncThunk('product/fetchCategories', async () => {
    try {
        return await CategoryService.getAllCategories();
    } catch (error) {
        throw error;
    }
});

export const fetchCategory = createAsyncThunk('product/fetchCategory', async (id) => {
    try {
        return await CategoryService.getSingleCategory(id);
    } catch (error) {
        throw error;
    }
});

export const addCategory = createAsyncThunk('product/addCategory', async (params) => {
    const data ={name: params.name, image: params.image, client_id: params.client_id};
    try {
        return await CategoryService.addCategory(data);
    } catch (error) {
        throw error;
    }
});

export const updateCategory = createAsyncThunk('product/updateCategory', async (params) => {
    const {categoryId, categoryData} = params;
    try {
        return await CategoryService.updateCategory(categoryId, categoryData);
    } catch (error) {
        throw error;
    }
});

export const deleteCategory = createAsyncThunk('product/deleteCategory', async (id) => {
    try {
        return await CategoryService.deleteCategory(id);
    } catch (error) {
        throw error;
    }
});

export const addSubCategory = createAsyncThunk('product/addSubCategory', async (params) => {
    const data ={name: params.name, image: params.image, client_id: params.client_id, category_id: params.category_id};
    try {
        return await CategoryService.addSubcategory(data);
    } catch (error) {
        throw error;
    }
});

export const reactivateCategory =  createAsyncThunk('product/reactivateCategory', async (id) => {
    try {
        return await CategoryService.reactivateCategory(id);
    } catch (error) {
        throw error;
    }
});