import {useState} from 'react';
import {FaArrowRightLong} from "react-icons/fa6";
import {Button, Form, Nav, Tab} from "react-bootstrap";
import {AiFillPlusCircle} from "react-icons/ai";
import "./RequestMessage.css";
const RequestMessagePage = () => {
    const [activeTab, setActiveTab] = useState('new');
    const tabs = [
        { id: 1, title: 'new' },
        { id: 2, title: 'ready' },
        { id: 3, title: 'complete' },
        { id: 4, title: 'canceled' },
        { id: 5, title: 'customize' },
    ];

    const handleTabSelect = (selectedTab) => {
        setActiveTab(selectedTab);
    };
    return (
        <>
            <div className={"d-flex justify-content-between mainColor"}>
                <h2 className={"fw-bold"}>Request status messages</h2>
                <FaArrowRightLong/>
            </div>
            <p className={"mainColor"}>
                Messages that are sent if the request status changes from one status to another
            </p>

            <div className={"content-container px-3 py-4 mainColor mb-5"}>
                <div className={"d-flex justify-content-start align-items-center"}>
                    <div className={"fw-bold"}>
                        Current sender name
                    </div>
                    <div className={"w-25 ms-2"}>
                        <Form.Control
                            className={"request-sender-input"}
                            placeholder="Username"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                    </div>
                </div>
                <p className={"mt-4 fw-bold"}>
                    Customize sender name
                </p>
                <p>
                    You can customize the sender name that appears to your customers for a fee separate from the balance
                    shipping fee
                </p>
                <p className={"opacity-50"}>
                    * To customize the sender’s name, a commercial register and a stamp with the name of the
                    organization are required
                </p>
                <p className={"opacity-50"}>
                    * Sender's name service is renewed annually
                </p>
                <center>
                    <button className={"submit-button text-white"}>
                        Show Prices
                    </button>
                </center>
            </div>

            <div className={"content-container px-3 py-4 mb-5"}>
                <div className={"d-flex justify-content-start align-items-center"}>
                    <div className={"fw-bold mainColor"}>
                        current balance
                    </div>
                    <Button variant={"outline-secondary"} className={"mx-3 rounded-2"}>
                        0 messages
                    </Button>
                    <div className={"create-request-button rounded-2"}>
                        <div className={"d-flex justify-content-between align-items-center"}>
                            <div className={"me-2"}>
                                Increase the balance
                            </div>
                            <div>
                                <AiFillPlusCircle color={"white"}/>
                            </div>
                        </div>
                    </div>
                </div>
                <p className={"mainColor mt-4"}>
                    With your current balance, you can send to 0 requests
                </p>
                <p className={"mainColor opacity-50"}>
                    * If there is no balance, messages will be sent via email
                </p>
            </div>

            <div className={"content-container px-3 py-4 mb-3"}>
                <p className={"fw-bold mainColor"}>
                    Choose the cases that will be sent
                </p>
                <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
                    <Nav variant="pills" className="mb-4 basket-case-navs">
                        {tabs.map((tab) => (
                            <Nav.Item key={tab.id}>
                                <Nav.Link eventKey={tab.title}>{tab.title}</Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>

                    <Tab.Content>
                        {tabs.map((tab) => (
                            <Tab.Pane key={tab.id} eventKey={tab.title}>
                                {tab.title === 'customize' && (
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Text here..."
                                        style={{height: '100px'}}
                                        className={"content-container px-3 py-4"}
                                    />
                                )}
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Tab.Container>
            </div>
            <center>
                <div className={"submit-button mb-3"}>
                    Save
                </div>
            </center>
        </>
    );
};

export default RequestMessagePage;