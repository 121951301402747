import SidebarLogo from "../../../assets/media/light-logo.svg";
import SideBarMiniLogo from "../../../assets/media/mini-logo-light.svg";
import {AiFillHome, AiFillPieChart, AiOutlineRight} from "react-icons/ai";
import {HiShoppingCart} from "react-icons/hi2";
import {
    BsChevronDown, BsChevronRight,
    BsFillBoxFill,
    BsShareFill
} from "react-icons/bs";
import {HiUsers} from "react-icons/hi";
import {IoIosColorPalette, IoMdSettings} from "react-icons/io";
import {IoHelpBuoySharp} from "react-icons/io5";
import {BiMenuAltLeft, BiMenuAltRight} from "react-icons/bi";
import {Fragment, useEffect, useState} from "react";
import {Collapse, Nav} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {TbBasketShare} from "react-icons/tb";
import {MdOutlineRequestQuote} from "react-icons/md";

const SidebarAdmin = () => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [openSubmenu, setOpenSubmenu] = useState(null);

    const toggleSubmenu = (link) => {
        setOpenSubmenu((prev) => (prev === link ? null : link));
    };

    const toggleSidebar = () => {
        setIsExpanded((prevState) => !prevState);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setIsExpanded(false);
            } else {
                setIsExpanded(true);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const iconSize = isExpanded ? 20 : 25;

    const mainMenuItems = [
        { icon: <AiFillHome size={iconSize} />, label: "Dashboard", link: "admin/dashboard" },
        { icon: <AiFillPieChart size={iconSize} />, label: "Reports", link: "admin/reports" },
        // {
        //     icon: <HiShoppingCart size={iconSize} />,
        //     label: "Requests",
        //     subItems: [
        //         { label: "Requests", icon: <MdOutlineRequestQuote size={iconSize} />, link: "requests" },
        //         { label: "Abandoned baskets", icon: <TbBasketShare size={iconSize} />, link: "abandoned_baskets" },
        //     ],
        // },
        // { icon: <BsFillBoxFill size={iconSize} />, label: "Products", link: "products" },
        { icon: <HiUsers size={iconSize} />, label: "Clients", link: "admin/clients" },
        // { icon: <IoIosColorPalette size={iconSize} />, label: "Store design", link: "design" },
        // { icon: <IoHelpBuoySharp size={iconSize} />, label: "Support tickets", link: "tickets" },
    ];

    return (
        <div className={`sidebar ${isExpanded ? "expanded" : ""} pt-3 admin-theme`}>
            <div className={"text-center sidebar-top-section"}>
                {isExpanded ? <BiMenuAltRight role={"button"} onClick={toggleSidebar} size={30} className={"collapse-sidebar-icon"} />
                    : <BiMenuAltLeft role={"button"} onClick={toggleSidebar} size={30} className={"expand-sidebar-icon"} />}
                {isExpanded ? <img src={SidebarLogo} alt="Site Logo" /> : <img src={SideBarMiniLogo} alt="Site Logo" />}

                {isExpanded ? (
                    <div className={"sidebar-header mx-auto my-4"}>
                        <div className={"sidebar-header_title"}>
                            <div>
                                Visit our store
                            </div>
                            <div>
                                <AiOutlineRight />
                            </div>
                        </div>
                        <div className={"sidebar-header_icon"}>
                            <BsShareFill size={15}/>
                        </div>
                    </div>) : <div className={"sidebar-header_icon my-2"}>
                    <BsShareFill size={15}/>
                </div>
                }
            </div>
            <div className="sidebar-content px-2 py-4">
                <Nav variant="pills">
                    {mainMenuItems.map((item, index) => (
                        <Fragment key={index}>
                            <Nav.Item key={index} className={"w-100"}>
                                {item.subItems ? (
                                    <>
                                        <Nav.Link
                                            className={`menu-item ${openSubmenu === item.link ? "active" : ""}`}
                                            onClick={() => toggleSubmenu(item.link)}
                                        >
                                            <div className={"text-center"}>
                                                {item.icon}
                                            </div>
                                            <div className={"ms-3 d-flex justify-content-between align-items-center w-75"}>
                                                <div className={"menu-item_label"}>
                                                    {item.label}
                                                </div>
                                                {item.subItems && (
                                                    <span>
                                                            {openSubmenu === item.link ? (
                                                                <BsChevronDown />
                                                            ) : (
                                                                <BsChevronRight />
                                                            )}
                                                        </span>
                                                )}
                                            </div>
                                        </Nav.Link>
                                        <Collapse in={openSubmenu === item.link}>
                                                <div className="flex-column">
                                                    {item.subItems.map((subItem, subIndex) => (
                                                        <Nav.Item key={subIndex}>
                                                            <Nav.Link as={NavLink} to={`/${subItem.link}`}
                                                                      className="submenu-item">
                                                                <div className={"text-center"}>
                                                                    {subItem.icon}
                                                                </div>
                                                                <div className={"menu-item_label ms-3"}>
                                                                    {subItem.label}
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ))}
                                                </div>
                                        </Collapse>
                                    </>
                                ) : (
                                    <Nav.Link className="menu-item"
                                              as={NavLink}
                                              to={`/${item.link}`}
                                    >
                                        <div className={"text-center"}>
                                            {item.icon}
                                        </div>
                                        <div className={"menu-item_label ms-3"}>{item.label}</div>
                                    </Nav.Link>
                                )}
                            </Nav.Item>
                        </Fragment>
                    ))}

                    <div className={"mt-4 text-center w-100"}>
                        <Nav.Item className={"w-100"}>
                            <Nav.Link className="menu-item"
                                      as={NavLink}
                                      to={"/settings"}
                                // link={"settings"}
                            >
                                <div className="settings-button">
                                    <div className={"settings-button_label"}>
                                        Settings
                                    </div>
                                    <div role={"button"} className={"ms-3 settings-button-icon"}>
                                        <IoMdSettings size={iconSize} />
                                    </div>
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                    </div>
                </Nav>
            </div>
        </div>
    );
};

export default SidebarAdmin;
