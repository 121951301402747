import {FaRegCircleXmark} from "react-icons/fa6";
import CenteredModal from "../Modals/CenteredModal/CenteredModal";
import {Button, Col, Form} from "react-bootstrap";
import Select from 'react-select';
import Row from "react-bootstrap/Row";

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
];

const EditShippingInfoModal = ({ show, onHide }) => {
    return (
        <CenteredModal show={show} onHide={onHide} size={'lg'}>
            <div className={"px-3"}>
                <div className={'p-0 p-md-5'}>
                    <div className={'position-relative'}>
                        <h3 className={'text-center fw-bold'}>Edit Shipping Information</h3>
                        <div className={'product-modal-close-icon'} onClick={onHide}>
                            <FaRegCircleXmark size={25} color={'#FA5252'}/>
                        </div>
                    </div>
                </div>
                <Form.Group className={"new-client-input"}>
                    <Form.Select aria-label="Is shipping free?">
                        <option>Is shipping free?</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>
                </Form.Group>
                <h2 className={"separator-title"}>Shipping Address</h2>
                <div className="new-client-input mb-3">
                    <Select
                        classNamePrefix="select"
                        defaultValue={options[0]}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={options}
                        placeholder={"Select country or search"}
                    />
                </div>
                <div className="new-client-input mb-3">
                    <Select
                        classNamePrefix="select"
                        defaultValue={options[0]}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={options}
                        placeholder={"Type or search for the city"}
                    />
                </div>
                <Form.Group controlId="neighborhood-name" className="new-client-input mb-3">
                    <Form.Control type="text" placeholder="Neighborhood name"/>
                </Form.Group>
                <Form.Group controlId="street-name" className="new-client-input mb-3">
                    <Form.Control type="text" placeholder="Street name"/>
                </Form.Group>
                <Form.Group controlId="house-name" className="new-client-input mb-3">
                    <Form.Control type="text" placeholder="Description of the house"/>
                </Form.Group>
                <Form.Group controlId="postal-code" className="new-client-input mb-3">
                    <Form.Control type="text" placeholder="Postal code"/>
                </Form.Group>
                <h2 className={"separator-title"}>Shipping Options</h2>
                <Row className={"justify-content-between align-items-center mb-3"}>
                    <Col lg={6}>
                        <Form.Check
                            inline
                            label="Aramex - (1 - 2 business days)"
                            name="delivery-one"
                            type={"radio"}
                            id={"inline-delivery-1"}
                        />
                    </Col>
                    <Col lg={6}>
                        <label htmlFor={"inline-delivery-1"} className={"delivery-price"}>
                            <div className={"p-1"}>
                                250
                            </div>
                            <div className={"delivery-currency p-1"}>
                                SAR
                            </div>
                        </label>
                    </Col>
                </Row>
                <Row className={"justify-content-between align-items-center mb-3"}>
                    <Col lg={6}>
                        <Form.Check
                            inline
                            label="Aramex - (1 - 2 business days)"
                            name="delivery-one"
                            type={"radio"}
                            id={"inline-delivery-2"}
                        />
                    </Col>
                    <Col lg={6}>
                        <label htmlFor={"inline-delivery-2"} className={"delivery-price"}>
                            <div className={"p-1"}>
                                250
                            </div>
                            <div className={"delivery-currency p-1"}>
                                SAR
                            </div>
                        </label>
                    </Col>
                </Row>
                <Row className={"justify-content-between align-items-center mb-3"}>
                    <Col lg={6}>
                        <Form.Check
                            inline
                            label="Aramex - (1 - 2 business days)"
                            name="delivery-one"
                            type={"radio"}
                            id={"inline-delivery-3"}
                        />
                    </Col>
                    <Col lg={6}>
                        <label htmlFor={"inline-delivery-3"} className={"delivery-price"}>
                            <div className={"p-1"}>
                                250
                            </div>
                            <div className={"delivery-currency p-1"}>
                                SAR
                            </div>
                        </label>
                    </Col>
                </Row>
                <div className={"d-flex justify-content-center my-5"}>
                    <div className={"submit-button me-4"}>
                        Save
                    </div>
                    <Button variant={"outline-danger"} className={"rounded-pill"} onClick={onHide}>
                        Cancel
                    </Button>
                </div>
            </div>
        </CenteredModal>
    );
};

export default EditShippingInfoModal;