import {createAsyncThunk} from '@reduxjs/toolkit';
import ProductService from "../../services/products/ProductService";

export const fetchProducts = createAsyncThunk('product/fetchProducts', async () => {
    try {
        return await ProductService.getAllProducts();
    } catch (error) {
        throw error;
    }
});

// Async thunk for adding a new product
export const addProduct = createAsyncThunk('product/addProduct', async (productData) => {
    try {
        return await ProductService.addProduct(productData);
    } catch (error) {
        throw error;
    }
});

// Async thunk for updating a product
export const updateProduct = createAsyncThunk('product/updateProduct', async (productData) => {
    try {
        return await ProductService.updateProduct(productData);
    } catch (error) {
        throw error;
    }
});

// Async thunk for deleting a product
export const deleteProduct = createAsyncThunk('product/deleteProduct', async (productId) => {
    try {
        await ProductService.deleteProduct(productId);
        return productId; // Return the deleted product ID for easier removal from the state
    } catch (error) {
        throw error;
    }
});

// Async thunk for fetching a single product
export const fetchProduct = createAsyncThunk('product/fetchProduct', async (productId) => {
    try {
        return await ProductService.getProduct(productId);
    } catch (error) {
        throw error;
    }
});
