import { createAsyncThunk } from '@reduxjs/toolkit'
import SignUpApi from "../../services/auth/signup-api";
import SignInApi from "../../services/auth/signin.api";
import Cookies from "js-cookie";
import {toast} from "react-toastify";

export const registerUser = createAsyncThunk(
    'auth/register',
    async (registerData, { rejectWithValue }) => {
        try {
            const response = await SignUpApi(registerData);
            const { user, token } = response.data;
            return { user, token };
        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message, {position: "bottom-right", theme: "dark"});
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)

export const loginUser = createAsyncThunk(
    'auth/login',
    async (loginData, { rejectWithValue }) => {
        try {
            const response = await SignInApi(loginData);
            const { user, token, Permissions } = response.data;
            return { user, token, Permissions };
        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message, {position: "bottom-right", theme: "dark"})
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const logoutUser = createAsyncThunk(
    'auth/logout',
    async () => {
        Cookies.remove('userInfo', { path: '' })
    }
);