import {createSlice} from '@reduxjs/toolkit';
import {
    addCategory,
    addSubCategory,
    deleteCategory,
    fetchCategories,
    fetchCategory, reactivateCategory,
    updateCategory
} from './categoryActions';

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            });
        builder
            .addCase(addCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(addCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.data.push(action.payload.data);
            })
            .addCase(addCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            });
        builder
            .addCase(fetchCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(updateCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCategory.fulfilled, (state, action) => {
                const updatedCategory = action.payload;
                const index = state.data.findIndex((category) => category.id === updatedCategory.id);
                if (index !== -1) {
                    state.data[index] = updatedCategory;
                }
            })
            .addCase(updateCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(deleteCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                const deletedCategoryId = action.meta.arg;
                state.data = state.data.map((category)=> category.id === deletedCategoryId  ? {...category, status: "deactive"} : category);
            })
            .addCase(deleteCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(addSubCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(addSubCategory.fulfilled, (state, action) => {
                state.loading = false;
                const category_id = action.meta.arg.category_id;
                const categoryToUpdate = state.data.find((category) => category.id === category_id);
                if (categoryToUpdate) {
                    categoryToUpdate.subCategories = [
                        ...(categoryToUpdate.subCategories || []),
                        action.payload.data
                    ];
                }
            })
            .addCase(addSubCategory.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action?.error?.message;
                })
            .addCase(reactivateCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(reactivateCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.map((category)=> category.id === action.payload.data.id ? {...category, status: "active"} : category);
            })
            .addCase(reactivateCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
    }
})

export default categorySlice.reducer;