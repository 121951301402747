import React, {useState} from 'react';
import {ReactSVG} from "react-svg";
import hierarchy from "../../assets/media/hierarchy-tree.svg";
import Dots from "../../assets/media/dots.svg";
import editIcon from "../../assets/media/edit.svg";
import {Previews} from "./PreviewDropZone";
import {useDispatch} from "react-redux";
import {
    deleteSubcategory,
    reactivateSubcategory,
    updateSubcategory
} from "../../features/subcategory/subcategoryActions";
import {Button, Dropdown, Form} from "react-bootstrap";
import checkIcon from "../../assets/media/checkmark.svg";
import actions from "../../assets/media/actions.svg";
import {Media_Endpoint} from "../../services/config";
import {FaCheck} from "react-icons/fa";
import {HiOutlineTrash} from "react-icons/hi";

const SubCategory = ({subCategory}) => {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [subCategoryName, setSubCategoryName] = useState(subCategory?.name);
    const subCategoryId = subCategory?.id;
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        setSubCategoryName(e.target.value);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const subCategoryData = {name: subCategoryName, image: uploadedFile};
        const params = {subCategoryId, subCategoryData}
        dispatch(updateSubcategory(params))
        setEditMode(false);
    };

    const handleDeleteSubcategory = async () => {
        await dispatch(deleteSubcategory(subCategoryId))
    }

    const handleReactivateSubcategory = async () => {
        await dispatch(reactivateSubcategory(subCategoryId))
    }

    return (
        <div className={`flex-container-centered px-3 ${subCategory.status === "deactive" ? "danger-category" : null}`}>

            <div className={"subCategory"}>
                <ReactSVG src={hierarchy}/>
                <div className={"mt-3 flex-container-centered"}>
                    <ReactSVG src={Dots}/>
                    {editMode ? (<Form onSubmit={handleFormSubmit} id={"edit-category-form"}
                                       className="d-flex justify-content-between align-items-center">
                        <Form.Control
                            type="text"
                            value={subCategoryName}
                            onChange={handleInputChange}
                            className="me-2"
                        />
                        <Previews setUploadedFile={setUploadedFile} uploadedFile={uploadedFile}/>
                    </Form>) : (
                        <Button
                            variant="link"
                            className="fw-bold fs-5 text-decoration-none mainColor"
                        >
                            {subCategoryName}
                        </Button>
                    )}
                </div>

            </div>

            <div className={"flex-container-centered"}>
                {editMode && (
                    <Button type="submit" form="edit-category-form" variant="link" className="text-decoration-none mainColor">
                        <ReactSVG src={checkIcon} />
                    </Button>
                )}
                {!editMode && (
                    <>
                        <div style={{width: '100%', height: '100px'}}>
                            <img src={Media_Endpoint + subCategory?.image} alt="SubCategory Image"
                                 style={{width: '100%', height: '100px', objectFit: "cover"}}/>
                        </div>
                        <Button
                            variant="link"
                            className="text-decoration-none mainColor"
                            onClick={() => {
                                setEditMode((prevEditMode) => !prevEditMode);
                                console.log("Edit mode set to true:", editMode);
                            }}
                        >
                            <ReactSVG src={editIcon} />
                        </Button>
                    </>
                )}
                <Dropdown>
                    <Dropdown.Toggle
                        id="dropdown-basic"
                        drop={"left"}
                        style={{backgroundColor: "transparent", border: "unset"}}
                        className={"dropdown-category"}
                    >
                        <ReactSVG src={actions}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={"products-filter-dropdown-menu"}>
                        {subCategory?.status === "deactive" ?
                            <Dropdown.Item className={"flex-container-centered px-2"} onClick={handleReactivateSubcategory}>
                                    <span className={"me-2"}>
                                         Reactivate Subcategory
                                    </span>
                                <FaCheck size={20} className={"text-white rounded-circle mainBgColor p-1"} />
                            </Dropdown.Item>
                            :
                            <Dropdown.Item className={"flex-container-centered px-2"} onClick={handleDeleteSubcategory}>
                                    <span className={"me-2"}>
                                         Deactivate Subcategory
                                    </span>
                                <HiOutlineTrash size={20} color={"red"}/>
                            </Dropdown.Item>}
                    </Dropdown.Menu>
                </Dropdown>
            </div>

        </div>
    );
};

export default SubCategory;