import CenteredModal from "../Modals/CenteredModal/CenteredModal";
import Row from "react-bootstrap/Row";
import {Button, Col} from "react-bootstrap";
import {PiTreeStructureFill} from "react-icons/pi";
import "./add-product.css";
const AddProductSteps = ({show, onHide}) => {
    return (
        <CenteredModal show={show} onHide={onHide} size={'lg'}>
            <div className={"my-3"}>
                <h2 className={"mainColor text-center fw-bold"}>
                    Add a product
                </h2>
                <Row className={"justify-content-evenly align-items-center"}>
                    <Col lg={5} className={"add-product-column"}>
                        Basic Product Data
                    </Col>
                    <Col lg={5} className={"add-product-column"}>
                        Additional product details
                    </Col>
                </Row>
                <Row className={"justify-content-evenly my-3"}>
                    <Col lg={5} className={"add-product-column"}>
                        Product options
                    </Col>
                    <Col lg={5} className={"add-product-column"}>
                        Customize product requests
                    </Col>
                </Row>
                <Row className={"justify-content-evenly align-items-center"}>
                    <Col lg={5} className={"d-flex justify-content-between add-product-column align-items-center"}>
                        <div>
                            Product Category
                        </div>
                        <PiTreeStructureFill className={"mainColor"}/>
                    </Col>
                    <Col lg={5} className={"add-product-column"}>
                        Customize product requests
                    </Col>
                </Row>
                <Button className={"submit-btn"}>Done</Button>
            </div>
        </CenteredModal>
    );
};

export default AddProductSteps;