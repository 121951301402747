import {createSlice} from '@reduxjs/toolkit';
import {addCustomer, deleteCustomer, fetchCustomer, fetchCustomers, updateCustomer} from "./customerActions";


const initialState = {
    data: [], loading: false, error: null,
};

const customerSlice = createSlice({
    name: 'customer', initialState, reducers: {}, extraReducers: (builder) => {
        builder
            .addCase(fetchCustomers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCustomers.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
            })
            .addCase(fetchCustomers.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(fetchCustomer.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCustomer.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
            })
            .addCase(fetchCustomer.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(addCustomer.pending, (state) => {
                state.loading = true;
            })
            .addCase(addCustomer.fulfilled, (state, action) => {
                state.loading = false;
                state.data = [...state.data, action.payload.data];
            })
            .addCase(addCustomer.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(updateCustomer.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCustomer.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.map((customer) => customer.id === action.payload.data.id ? action.payload.data : customer);
            })
            .addCase(updateCustomer.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(deleteCustomer.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteCustomer.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.filter((customer) => customer.id !== action.payload.data);
            })
            .addCase(deleteCustomer.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
    }
});

export default customerSlice.reducer;