import "./products.css"
import {AiFillPlusCircle} from "react-icons/ai";
import ProductRowComponent from "../../components/Product/ProductRow.component";
import {useEffect, useState} from "react";
import {HiOutlineBriefcase} from "react-icons/hi";
import {CiFilter} from "react-icons/ci";
import {Button, Col, Dropdown} from "react-bootstrap";
import {HiOutlineSquares2X2} from "react-icons/hi2";
import {PiListDashesBold} from "react-icons/pi";
import ProductColumnComponent from "../../components/Product/ProductColumn.component";
import Row from "react-bootstrap/Row";
import {useDispatch, useSelector} from 'react-redux';
import {fetchProducts, updateProduct} from "../../features/product/productActions";
import {toast} from "react-toastify";
import AddProductForm from "./AddProductForm";
import {fetchCategories} from "../../features/category/categoryActions";
import ProductCategory from "./ProductCategory";
import NewProductCategory from "./NewProductCategory";

const ProductsPage = () => {
    const products = useSelector((state) => state.products.data);
    const categories = useSelector((state) => state.category.data);
    const [productRowView, setProductRowView] = useState(true);
    const [createProduct, setCreateProduct] = useState(false);
    const [categoryCrud, setCategoryCrud] = useState();
    const [createCategory, setCreateCategory] = useState();
    const dispatch = useDispatch();
    const userToken = useSelector(state => state?.auth?.userInfo);
    console.log(userToken)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1280) {
                setProductRowView(false);
            } else {
                setProductRowView(true);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(fetchProducts());
        dispatch(fetchCategories());
    }, []);

    const handleUpdateProduct = (id, updatedProduct) => {
        dispatch(updateProduct({id, ...updatedProduct}));
        toast.success("Product updated successfully", {position: "bottom-right", theme: "dark"});
    };

    const handleSaveClick = () => {
        setCreateCategory(true);
    }
    return (<>
            <div className={"d-flex justify-content-between mb-3"}>
                {categoryCrud ? (<div className={"flex-container-centered"}>
                        <div className={"mainColor fw-bold fs-2"}>Product Category</div>
                        <div className={"d-flex justify-content-end"}>
                            <Button className={"rounded-button-primary mx-3"} onClick={() => setCreateCategory(true)}>
                                Create new Category
                            </Button>
                            <Button variant={"outline-secondary"} className={"mx-3 rounded-pill"} onClick={() => {
                                setCreateProduct(false);
                                setCategoryCrud(false);
                            }}>
                                Back to Products
                            </Button>
                        </div>
                    </div>) : <div className={"d-flex justify-content-start"}>
                    <div className={"create-product-button"} onClick={() => {
                        setCreateProduct(true)
                        setCategoryCrud(false)
                    }}>
                        <div className={"d-flex justify-content-between align-items-center"}>
                            <div className={"me-2"}>Create a new product</div>
                            <div>
                                <AiFillPlusCircle color={"white"}/>
                            </div>
                        </div>
                    </div>
                    <div className={"create-product-button ms-2"} onClick={() => {
                        setCreateProduct(false)
                        setCategoryCrud(true)
                    }}>
                        <div className={"d-flex justify-content-between align-items-center"}>
                            <div className={"me-2"}>Product Category</div>
                            <div>
                                <AiFillPlusCircle color={"white"}/>
                            </div>
                        </div>
                    </div>
                </div>}

                {createProduct ? (<div className={"d-flex justify-content-end fs-5"}>
                        <Button variant={"danger rounded-pill"} onClick={() => {
                            setCreateProduct(false)
                            setCategoryCrud(false)
                        }}>
                            Delete The Product
                        </Button>
                        <Button variant={"outline-primary mx-3 rounded-pill"}>Preview</Button>
                        <Button className={"rounded-button-primary"} onClick={handleSaveClick}>
                            Save
                        </Button>
                    </div>) : (<div className={"d-flex justify-content-between align-items-center"}>
                        {categoryCrud ? null : <>
                            <div
                                className={"rounded-button d-flex justify-content-between align-items-center px-2 py-1 me-2"}>
                                <div className={"me-2"}>Service</div>
                                <div>
                                    <HiOutlineBriefcase/>
                                </div>
                            </div>
                            <div>
                                <Dropdown>
                                    <Dropdown.Toggle
                                        variant="outline-primary"
                                        id="dropdown-basic"
                                        className={"rounded-button d-flex justify-content-between align-items-center px-2 py-1"}
                                        drop={"left"}
                                    >
                                        <div className={"me-2"}>Filter</div>
                                        <div>
                                            <CiFilter/>
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className={"products-filter-dropdown-menu"}>
                                        <Dropdown.Item>استيراد المنتجات</Dropdown.Item>
                                        <Dropdown.Item>عرض التحليلات</Dropdown.Item>
                                        <Dropdown.Item>تصنيفات المنتجات</Dropdown.Item>
                                        <Dropdown.Item>إعدادات معايير التصفية</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className={"mx-2"}>
                                <HiOutlineSquares2X2 color={"#1A478F"} size={20} role={"button"}
                                                     onClick={() => setProductRowView(false)}/>
                            </div>
                            <div className={"menu-icon"}>
                                <PiListDashesBold color={"#1A478F"} size={20} role={"button"}
                                                  onClick={() => setProductRowView(true)}/>
                            </div>
                        </>}
                    </div>)}
            </div>

            {categoryCrud && <>
                {createCategory ? <NewProductCategory setCreateCategory={setCreateCategory}/> : null}
                {categories.map((category, index) => (<Row key={index}>
                        <ProductCategory category={category}/>
                    </Row>))}
            </>}
            {createProduct && !categoryCrud ? (
                <AddProductForm createProduct={createProduct} products={products} setCreateProduct={setCreateProduct}
                                categories={categories}/>
                // end of draft code
                // <ProductColumnComponent
                //     name={newProduct?.name}
                //     price={newProduct?.price}
                //     quantity={newProduct?.quantity}
                //     category={newProduct?.category}
                //     handleSimpleFormChange={handleSimpleFormChange}
                //     createProduct={createProduct}
                //     setCreateProduct={setCreateProduct}
                // />
                // ) : (
            ) : (productRowView && !categoryCrud ? (<Row className={"justify-content-center"}>
                        {products?.map((product) => (<ProductRowComponent
                                key={product?.id}
                                id={product?.id}
                                name={product?.name}
                                price={product?.price}
                                quantity={product?.quantity}
                                category={product?.category}
                                subcategory={product?.subCategory}
                                onUpdate={handleUpdateProduct}
                            />))}
                    </Row>) : (!categoryCrud && <Row className={"justify-content-center"}>
                        {products?.map((product) => (<Col xl={4} lg={6} xs={12} key={product?.id}>
                                <ProductColumnComponent
                                    id={product?.id}
                                    name={product?.name}
                                    price={product?.price}
                                    quantity={product?.quantity}
                                    category={product?.category}
                                    subcategory={product?.subCategory}
                                    onUpdate={handleUpdateProduct}
                                />
                            </Col>))}
                    </Row>))}

        </>);
};

export default ProductsPage;
