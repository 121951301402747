import { createSlice } from '@reduxjs/toolkit';
import {fetchProducts, addProduct, updateProduct, deleteProduct, fetchProduct} from './productActions';

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
            })
            .addCase(fetchProducts.rejected, (state, { error }) => {
                state.loading = false;
                state.error = error.message || 'Failed to fetch product';
            })
            .addCase(addProduct.fulfilled, (state, action) => {
                state.data.push(action.payload.data);
            })
            .addCase(updateProduct.fulfilled, (state, action) => {
                const updatedProduct = action.payload;
                const index = state.data.findIndex((product) => product.id === updatedProduct.id);
                if (index !== -1) {
                    state.data[index] = updatedProduct;
                }
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                const deletedProductId = action.payload;
                state.data = state.data.filter((product) => product.id !== deletedProductId);
            })
            .addCase(fetchProduct.fulfilled, (state, action) => {
                state.data = action.payload;
            });
    },
});

export default productSlice.reducer;
