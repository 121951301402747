import {useState, useRef} from "react";
import "./chat.css";
import ChatLogo from '../../../assets/media/logo.svg';
const ChatComponent = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const chatInputRef = useRef(null);

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleSendMessage = () => {
        if (newMessage.trim() !== "") {
            setMessages([...messages, { text: newMessage, user: "You" }]);
            setNewMessage("");
            chatInputRef.current.focus();
        }
    };

    return (
        <div className="chat-container">
            <div className={"chat-header"}>
                    <img src={ChatLogo} alt={"chat logo"} />
                <strong>
                    Just Send !
                </strong>
              and we will respond to you  immediately
            </div>
            <div className="chat-messages">
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`message ${message.user === "You" ? "user" : "other"}`}
                    >
                        {message.text}
                    </div>
                ))}
            </div>
            <div className="chat-input">
                <input
                    ref={chatInputRef}
                    type="text"
                    placeholder="Type your message..."
                    value={newMessage}
                    onChange={handleInputChange}
                />
                <button onClick={handleSendMessage}>Send</button>
            </div>
        </div>
    );
};

export default ChatComponent;
