import {useMemo, useState} from "react";
import product1 from "../../../assets/media/product_1.png";
import product2 from "../../../assets/media/product_2.png";
import product3 from "../../../assets/media/product_3.png";
import product4 from "../../../assets/media/product_4.png";
import OrdersDataTable from "./OrdersDataTable";

const OrdersTable = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);

    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'orderId',
                Cell: ({ row }) => <span>{row.index + 1}</span>,
            },
            {
                Header: "Product",
                accessor: "product",
                Cell: ({ row }) => (
                    <div className="d-flex justify-content-evenly align-items-center">
                        <img src={row.original.productImg} alt="Product" className="w-6 h-6 rounded-full mr-2" />
                        <div className={"d-flex justify-content-between flex-column"}>
                            <span>{row.original.product}</span>
                            <span>{row.original.description}</span>
                        </div>
                    </div>
                )
            },
            {
                Header: "Quantity",
                accessor: "quantity"
            },
            {
                Header: "Price",
                accessor: "price"
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: ({ value, row }) => {
                    switch(row.original.status) {
                        case "Delivered":
                            return <span className="completed-status py-1 px-2 rounded-1">{value}</span>;
                        case "Processing":
                            return <span className="in-progress-status py-1 px-2 rounded-1">{value}</span>;
                        case "Shipped":
                            return <span className="shipped-status py-1 px-2 rounded-1">{value}</span>;
                        case "Canceled":
                            return <span className="canceled-status py-1 px-2 rounded-1">{value}</span>;
                        default:
                            return <span>{value}</span>;
                    }
                }
            }
        ],
        []
    );

    const data = [
        { productImg: product1, product: "Product A", description: "Lorem ipsum dolor sit amet", quantity: 2, price: "$50", status: "Delivered" },
        { productImg: product2, product: "Product B", description: "Lorem ipsum dolor sit amet", quantity: 1, price: "$100", status: "Processing" },
        { productImg: product3, product: "Product C", description: "Lorem ipsum dolor sit amet", quantity: 3, price: "$150", status: "Shipped" },
        { productImg: product4, product: "Product D", description: "Lorem ipsum dolor sit amet", quantity: 4, price: "$200", status: "Canceled" }
    ];

    const indexedData = data.map((item, index) => ({ ...item, orderId: index + 1 }));

    const tableHeader = () => {
        return (
            <div className={"fs-5 fw-bold text-white mb-3"}>
                Orders
            </div>
        )
    }

    return (
        <OrdersDataTable
            columns={columns}
            data={indexedData}
            loading={false}
            recordsPerPage={recordsPerPage}
            setRecordsPerPage={setRecordsPerPage}
            totalPages={Math.ceil(indexedData.length / recordsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            tableHeader={tableHeader()}
        />
    );
};

export default OrdersTable;