import CenteredModal from "../Modals/CenteredModal/CenteredModal";
import {FaRegCircleXmark} from "react-icons/fa6";
import {Button, Col, Form} from "react-bootstrap";
import DropdownZoneComponent from "../DropdownZone/DropdownZone.component";
import Row from "react-bootstrap/Row";
const EditShippingInfoModalPaid = ({show, onHide}) => {
    return (<CenteredModal show={show} onHide={onHide} size={'lg'}>
        <div className={"px-3"}>
            <div className={'p-0 p-md-5'}>
                <div className={'position-relative'}>
                    <h3 className={'text-center fw-bold'}>Edit Shipping Information</h3>
                    <div className={'product-modal-close-icon'} onClick={onHide}>
                        <FaRegCircleXmark size={25} color={'#FA5252'}/>
                    </div>
                </div>
            </div>
            <Form.Group className={"new-client-input"}>
                <Form.Select aria-label="Yes, payment has been made">
                    <option>Yes, payment has been made</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </Form.Select>
            </Form.Group>
            <h2 className={"separator-title"}>Payment Options</h2>
            <Form.Group className={"d-flex justify-content-between flex-column"}>
                <Form.Check
                    inline
                    label="Bank transfer"
                    name="payment-options"
                    type={"radio"}
                    id={"payment-option-1"}
                    className={"mb-3"}
                />
                <Form.Check
                    inline
                    label="Paiement when recieving"
                    name="payment-options"
                    type={"radio"}
                    id={"payment-option-2"}
                    className={"mb-3"}
                />
                <Form.Check
                    inline
                    label="Credit card"
                    name="payment-options"
                    type={"radio"}
                    id={"payment-option-3"}
                    className={"mb-3"}
                />
                <Form.Check
                    inline
                    label="PayPal"
                    name="payment-options"
                    type={"radio"}
                    id={"payment-option-4"}
                    className={"mb-3"}
                />
            </Form.Group>
            <h2 className={"separator-title"}>Bank transfer details</h2>
            <Form.Group className={"new-client-input"}>
                <Form.Select aria-label="Select bank">
                    <option>Select bank</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </Form.Select>
            </Form.Group>
        <DropdownZoneComponent />
            <Row className={"justify-content-between align-items-center mb-3"}>
                <Col lg={6}>
                    <Form.Check
                        inline
                        label="Aramex - (1 - 2 business days)"
                        name="delivery-one"
                        type={"radio"}
                        id={"inline-delivery-1"}
                    />
                </Col>
                <Col lg={6}>
                    <label htmlFor={"inline-delivery-1"} className={"delivery-price"}>
                        <div className={"p-1"}>
                            250
                        </div>
                        <div className={"delivery-currency p-1"}>
                            SAR
                        </div>
                    </label>
                </Col>
            </Row>
            <Row className={"justify-content-between align-items-center mb-3"}>
                <Col lg={6}>
                    <Form.Check
                        inline
                        label="Aramex - (1 - 2 business days)"
                        name="delivery-one"
                        type={"radio"}
                        id={"inline-delivery-2"}
                    />
                </Col>
                <Col lg={6}>
                    <label htmlFor={"inline-delivery-2"} className={"delivery-price"}>
                        <div className={"p-1"}>
                            250
                        </div>
                        <div className={"delivery-currency p-1"}>
                            SAR
                        </div>
                    </label>
                </Col>
            </Row>
            <Row className={"justify-content-between align-items-center mb-3"}>
                <Col lg={6}>
                    <Form.Check
                        inline
                        label="Aramex - (1 - 2 business days)"
                        name="delivery-one"
                        type={"radio"}
                        id={"inline-delivery-3"}
                    />
                </Col>
                <Col lg={6}>
                    <label htmlFor={"inline-delivery-3"} className={"delivery-price"}>
                        <div className={"p-1"}>
                            250
                        </div>
                        <div className={"delivery-currency p-1"}>
                            SAR
                        </div>
                    </label>
                </Col>
            </Row>
            <div className={"d-flex justify-content-center my-5"}>
                <div className={"submit-button me-4"}>
                    Save
                </div>
                <Button variant={"outline-danger"} className={"rounded-pill"} onClick={onHide}>
                    Cancel
                </Button>
            </div>
        </div>
    </CenteredModal>);
};

export default EditShippingInfoModalPaid;