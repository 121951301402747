import Row from "react-bootstrap/Row";
import {Button, Col, Form} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {MdOutlineModeEdit} from "react-icons/md";
import {FiTrash2} from "react-icons/fi";
import product3 from "../../assets/media/product3.png";
const OrderSummaryTabComponent = () => {
    return (
        <Container className={"px-4 py-3"}>
            <Row className={"order-statistics justify-content-center mainColor mx-3 px-5 pb-5"}>
                    <Col lg={4} className={"text-start5"}>
                        <p>
                            Modify the order number
                        </p>
                        <p className={"fw-bold"}>
                            67160542
                        </p>
                    </Col>
                <Col lg={4} className={"text-center"}>
                    <p className={"fw-bold"}>
                        Date of Order
                    </p>
                    <p>
                        Monday 9 October 2023 | 09:56 AM
                    </p>
                </Col>
                <Col lg={4} className={"text-end"}>
                    <p>
                        Order status
                    </p>
                    <p className={"fw-bold"}>
                        New
                    </p>
                </Col>
            </Row>
            <Row>
                <Col lg={12} className={"tags-row"}>
                    <div className={"d-flex justify-content-start align-items-center py-3"}>
                        <div>
                            Tags:
                        </div>
                        <div className={"mainColor rounded-pill border border-2 border-primary-subtle ms-3 px-1"}>
                            Add a tag +
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className={"my-4"}>
                <Col lg={4} className={"order-summary-card-container"}>
                    <div className={"order-summary-card"}>
                        <div className={"fw-bold fs-6 mainColor"}>
                            Client
                        </div>
                        <div className={"order-summary-card-icon"}>
                            <MdOutlineModeEdit size={20}/>
                        </div>
                    </div>
                </Col>
                <Col lg={4} className={"order-summary-card-container"}>
                    <div className={"order-summary-card"}>
                        <div className={"fw-bold fs-6 mainColor"}>
                            Shipping
                        </div>
                        <div className={"order-summary-card-icon"}>
                            <MdOutlineModeEdit size={20}/>
                        </div>
                    </div>
                </Col>
                <Col lg={4} className={"order-summary-card-container"}>
                    <div className={"order-summary-card"}>
                        <div className={"fw-bold fs-6 mainColor"}>
                            Payment
                        </div>
                        <div className={"order-summary-card-icon"}>
                            <MdOutlineModeEdit size={20}/>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className={"order-summary-price-section py-3"}>
                <Row className={"text-center"}>
                    <Col lg={3}>
                        <div className={"mb-3"}>
                            Product
                        </div>
                        <div className={"d-flex justify-content-center order-image-container align-items-start"}>
                            <div className={"bg-danger rounded-3 p-1 me-3"}>
                                <FiTrash2 color={"white"} size={20}/>
                            </div>
                            <img src={product3} alt={"product"}/>
                        </div>
                    </Col>
                    <Col lg={3} className={"text-center"}>
                        <Form.Group className="mb-3" controlId="pricexquantity">
                            <Form.Label className={"mb-3"}>Price x Quantity</Form.Label>
                            <Form.Control type="number"/>
                        </Form.Group>
                    </Col>
                    <Col lg={3}>
                        <p>Final</p>
                        <p>
                            300 SAR
                        </p>
                    </Col>
                    <Col lg={3}>
                        <p>Total</p>
                        <p className={"total-price-container"}>
                            300 SAR
                        </p>
                    </Col>
                    <Col lg={3}>
                        <div className={"mb-3"}>
                            Product
                        </div>
                        <div className={"d-flex justify-content-center order-image-container align-items-start"}>
                            <div className={"bg-danger rounded-3 p-1 me-3"}>
                                <FiTrash2 color={"white"} size={20}/>
                            </div>
                            <img src={product3} alt={"product"}/>
                        </div>
                    </Col>
                    <Col lg={3} className={"text-center"}>
                        <Form.Group className="mb-3" controlId="pricexquantity">
                            <Form.Label className={"mb-3"}>Price x Quantity</Form.Label>
                            <Form.Control type="number"/>
                        </Form.Group>
                    </Col>
                    <Col lg={3}>
                        <p>Final</p>
                        <p>
                            300 SAR
                        </p>
                    </Col>
                    <Col lg={3}>
                        <p>Total</p>
                        <p className={"total-price-container"}>
                            300 SAR
                        </p>
                    </Col>
                </Row>
                <div className={"d-flex justify-content-center"}>
                    <div className={"submit-button"}>
                        Create Order
                    </div>
                    <Button variant={"outline-danger"} className={"rounded-pill mx-3"}>
                        Delete Order
                    </Button>
                    <Button variant={"outline-secondary"} className={"rounded-pill"}>
                        Save as Draft
                    </Button>
                </div>
            </div>
        </Container>
    );
};

export default OrderSummaryTabComponent;