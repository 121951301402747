import {api} from "../config";
import {toast} from "react-toastify";

const CustomerService = {
    getAllCustomers: async () => {
        try {
            const response = await api.get("customer", {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    },

    getSingleCustomer: async (customerId) => {
        try {
        const response = await api.get(`customer/${customerId}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    },

    addCustomer: async (customerData) => {
        try {
            const response = await api.post("customer", customerData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            toast.success(response.data.message, {position: "bottom-right", theme: "dark"});
            return response.data;
        } catch (error) {
            toast.error(error.response.data.message, {position: "bottom-right", theme: "dark"});
            console.error('Error creating category:', error);
            throw error;
        }
    },

    updateCustomer: async (customerId, customerData) => {
        try {
            const response = await api.put(`customer/${customerId}`, customerData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error creating category:', error);
            throw error;
        }
    },

    deleteCustomer: async (customerId) => {
        try {
            const response = await api.delete(`customer/${customerId}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error creating category:', error);
            throw error;
        }
    }
}
export default CustomerService;