import "./products-table.css";
import product1 from "../../assets/media/product1.png";
import product2 from "../../assets/media/product2.png";
import {Table} from "react-bootstrap";

const ProductsTableComponent = () => {
    const tableHeaders = [
        'The product',
        'The name',
        'Quantity',
        'The sales',
        'Quantity sold',
        'Add to cart times',
        'Request times',
        'Product cost',
        'Product profits',
        'Notify me when available',
        'Product evaluation',
    ];
    const productsData = [
        {
            image: product1,
            name: 'French group',
            quantity: 'Unlimited quantity',
            sales: '0 SAR',
            quantitySold: '0',
            addToCartTimes: '0',
            requestTimes: '0',
            productCost: '0 SAR',
            productProfits: '0 SAR',
            notifyAvailable: '0',
            evaluation: 'There are no reviews',
        },
        {
            image: product2,
            name: 'Another Product',
            quantity: 'Limited quantity',
            sales: '100 SAR',
            quantitySold: '10',
            addToCartTimes: '20',
            requestTimes: '5',
            productCost: '50 SAR',
            productProfits: '50 SAR',
            notifyAvailable: '1',
            evaluation: 'Good product',
        },
    ];

    return (
        <Table className={"products-table"} responsive={"lg"}>
            <thead>
            <tr>
                {tableHeaders.map((header, index) => (
                    <th key={index}>{header}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {productsData.map((product, rowIndex) => (
                <tr key={rowIndex}>
                    <td><img src={product.image} alt="Product" width={"50px"} height={"50px"} className={"bg-white"} /></td>
                    <td>{product.name}</td>
                    <td>
                        <div className={"products-quantity"}>
                            {product.quantity}
                        </div>
                    </td>
                    <td>{product.sales}</td>
                    <td>{product.quantitySold}</td>
                    <td>{product.addToCartTimes}</td>
                    <td>{product.requestTimes}</td>
                    <td>{product.productCost}</td>
                    <td>{product.productProfits}</td>
                    <td>{product.notifyAvailable}</td>
                    <td>
                        <div className={"products-evaluation"}>
                            {product.evaluation}
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
}

export default ProductsTableComponent;
