import axios from 'axios';
import Cookies from "js-cookie";
const BackEnd_EndPoint = process.env.REACT_APP_API_URL;
const Media_Endpoint = process.env.REACT_APP_MEDIA_URL;

const api = axios.create({
    baseURL: BackEnd_EndPoint,
});


api.interceptors.request.use(
    (config) => {
        const userInfo = Cookies.get('userInfo');
        const userInfoString = userInfo && JSON.parse(userInfo);
        if (userInfo) {
            config.headers.Authorization = `Bearer ${userInfoString.token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export { api, BackEnd_EndPoint, Media_Endpoint };