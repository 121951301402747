import {useMemo, useState} from "react";
import {useAsyncDebounce, useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import {Button, ButtonGroup, Col, Form, InputGroup, Pagination, Row, Table} from "react-bootstrap";
import {IoReturnUpBackOutline} from "react-icons/io5";
import {BsFillCaretDownFill} from "react-icons/bs";
import {BiSearch} from "react-icons/bi"
import {ReactSVG} from "react-svg";
import {Link} from "react-router-dom";
// import CreateClientModal from "../../Modals/CreateClientModal";
// import FetchingDataLoading from "../LoadingAnimation/FetchingDataLoading";
import {FaFilterCircleXmark, FaUserPlus} from "react-icons/fa6";
import {Tooltip} from "react-tooltip";
// import CenteredModal from "../Shared/modals/CenteredModal/CenteredModal";
// import ImportLeadsDropZone from "../Modals/ImportLeadsModal";
import {LuImport} from "react-icons/lu";
import {PiExportBold} from "react-icons/pi";
import { useSelector, useDispatch } from 'react-redux';
import { setTotalPages, setCurrentPage, setRecordsPerPage, selectTotalPages, selectCurrentPage, selectRecordsPerPage } from '../../features/pagination/paginationSlice';
// import {useAuth} from "../../context/AuthContext";
// import {useLeadsPaginationContext} from "../../context/LeadsPaginationContext";

function GlobalFilter({
                          preGlobalFilteredRows, globalFilter, setGlobalFilter,
                      }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (<div className={"position-relative"}>
        <InputGroup>
            <Form.Control
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
                className={"search-input"}
            />
        </InputGroup>
        <div className={"search-icon"}>
            <BiSearch color={"#000"} size={20}/>
        </div>
    </div>)
}

function DefaultColumnFilter({
                                 column: {filterValue, preFilteredRows, setFilter},
                             }) {
    const count = preFilteredRows.length

    return (<input
        className="form-control"
        value={filterValue || ''}
        onChange={e => {
            setFilter(e.target.value || undefined)
        }}
        placeholder={`Search ${count} records...`}
    />)
}

const ClientsTable = ({
                                columns,
                                data,
                                handleFilterStatus,
                                loading
                            }) => {
    const totalPages = useSelector(selectTotalPages);
    const currentPage = useSelector(selectCurrentPage);
    const recordsPerPage = useSelector(selectRecordsPerPage);
    const dispatch = useDispatch();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const handleClose = () => setShowCreateModal(false);
    const handleShow = () => setShowCreateModal(true);
    // const {sourceToIcon, handleExportLeads} = useClientContext();
    const [selectedSource, setSelectedSource] = useState(null);
    const [showImportLeadModal, setShowImportLeadModal] = useState(false);
    const defaultColumn = useMemo(() => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
    }), [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        setFilter,
        canPreviousPage,
        canNextPage,
        gotoPage,
        setPageSize,
        rows,
        state: {pageIndex, pageSize, globalFilter},
    } = useTable({
        columns, data, defaultColumn, initialState: {pageIndex: currentPage - 1, pageSize: recordsPerPage || 10},
    }, useFilters, useGlobalFilter, useSortBy, usePagination)


    const handleClearFilters = () => {
        setGlobalFilter('');
        setSelectedSource(null);
        columns.forEach(column => {
            if (column.accessor) {
                setFilter(column.accessor, undefined);
            }
        });
    };

    const handlePageChange = (pageIndex) => {
        dispatch(setCurrentPage(pageIndex + 1));
        gotoPage(pageIndex);
    };

    const handlePageSizeChange = (size) => {
        dispatch(setRecordsPerPage(size));
        setPageSize(size);
    };
    return (
        // loading ? <FetchingDataLoading className={"content-container"}/> : <>
        // <CenteredModal size={"lg"} show={showImportLeadModal}
        //                children={<ImportLeadsDropZone handleClose={() => setShowImportLeadModal(false)}/>}
        //                onHide={() => setShowImportLeadModal(false)}/>
        <div className={"all-leads-table"}>
            <Row className={"m-4"}>
                <Row>
                    <Col lg={6}>
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                    </Col>
                </Row>
                <Row className={"mt-3 justify-content-between align-items-center"}>
                    <Col lg={6} className="social-filter-wrapper my-3">
                        <div className="social-filter-container">
                            <div onClick={handleClearFilters}
                                 className={`reset${selectedSource === null ? ' reset-selected' : ''}`}>
                                {selectedSource === null ? "Leads From" :
                                    <div className={"d-flex justify-content-between align-items-center"}>
                                        <div className={"me-2"}><IoReturnUpBackOutline size={20}/></div>
                                        <div>Back To All</div>
                                    </div>}
                            </div>
                        </div>
                    </Col>
                    <Col
                        className={"d-flex justify-content-center justify-content-lg-end align-items-center"}
                        lg={6}>
                        <div className={"clear-filter"} onClick={handleClearFilters}>
                            <FaFilterCircleXmark size={25}/>
                        </div>
                                    <span
                                        // htmlFor="fileInput"
                                        className={"import-leads"} onClick={() => setShowImportLeadModal(true)}>
                                        <LuImport size={25}/>
                                </span>
                            <span className={"export-leads"}
                                  // onClick={handleExportLeads}
                            >
                                            <PiExportBold size={25}/>
                                        </span>

                            <div className={"add-lead"} onClick={handleShow}>
                                <FaUserPlus size={25}/>
                            </div>
                            <Tooltip
                                anchorSelect=".clear-filter"
                                content="Clear search and filters."
                            />
                            <Tooltip
                                anchorSelect=".add-lead"
                                content="Add a new lead."
                            />
                            <Tooltip
                                anchorSelect=".import-leads"
                                content="Import leads from csv,xsxl file."
                            />
                            <Tooltip
                                anchorSelect=".export-leads"
                                content="Export leads to csv,xsxl file."
                            />
                            {/*<CreateClientModal show={showCreateModal} onHide={handleClose}/>*/}
                    </Col>
                </Row>
            </Row>
            <>
                <Table responsive={"lg"} className="table text-center position-relative" {...getTableProps()}>
                    {/*{loading ? <FetchingDataLoading/> : rows?.length > 0 ? <>*/}
                        <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                {headerGroup.headers.map((column, j) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} key={j}>
                                        {column.render('Header')}
                                        {/* Render the columns filter UI */}
                                        {/*<div>{column.canFilter ? column.render('Filter') : null}</div>*/}
                                        <span>
                                        {column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : <>
                                            {" "}
                                            {column.accessor && <BsFillCaretDownFill/>}
                                        </>}
                                    </span>
                                    </th>))}
                            </tr>))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {rows?.map(row => {
                            prepareRow(row);
                            return (<tr {...row.getRowProps()} className={"client-table-row filter-table-rows"}
                                        key={row.original.id}>
                                {row.cells.map((cell, j) => {
                                    return (<td {...cell.getCellProps()} key={j}>
                                        {(cell.column.id === "Actions" || cell.column.id === "lastActivity" || cell.column.id === "assignedTo") ? (cell.render("Cell")) : (
                                            <Link to={`/leads/${row.original.id}`}>
                                                {cell.render("Cell")}
                                            </Link>)}
                                    </td>);
                                })}
                            </tr>);
                        })}
                        </tbody>
                {/*    </> */}
                {/*: <div>*/}
                {/*        <h2 className={"mainColor fw-bold fs-4 text-center"}> No Leads Found </h2>*/}
                {/*    </div>}*/}
                </Table>
                {rows?.length > 0 ?
                    <>
                        <div
                            className={"d-flex justify-content-between flex-column flex-md-row align-items-center my-4 mx-3"}>
                            <div className={"d-flex flex-column justify-content-center align-items-center"}>
                                <div className={"mb-1"}>
                                    Records Per Page
                                </div>
                                <div className="btn-group records-buttons-container" role="group">
                                    {[10, 20, 30, 40, 50].map((pageSizeOption) => (<div
                                        key={pageSizeOption}
                                        role="button"
                                        className={`${pageSize === pageSizeOption ? 'record-button-selected' : 'record-button'}`}
                                        onClick={() => handlePageSizeChange(pageSizeOption)}
                                    >
                                        {pageSizeOption}
                                    </div>))}
                                </div>
                            </div>
                            <Pagination className={"data-table-pagination"}>
                                <Pagination.Prev onClick={() => handlePageChange(pageIndex - 1)}
                                                 disabled={!canPreviousPage}/>
                                {Array.from({length: totalPages}).map((_, index) => {
                                    if (totalPages <= 5 || index === 0 || index === totalPages - 1 || (index >= pageIndex - 2 && index <= pageIndex + 2)) {
                                        return (<Pagination.Item
                                            key={index}
                                            onClick={() => handlePageChange(index)}
                                            active={pageIndex === index}
                                        >
                                            {index + 1}
                                        </Pagination.Item>);
                                    } else if (index === 1 || index === totalPages - 2) {
                                        return <Pagination.Ellipsis key={index}/>;
                                    }
                                    return null;
                                })}
                                <Pagination.Next onClick={() => handlePageChange(pageIndex + 1)} disabled={!canNextPage}/>
                            </Pagination>
                            <div className={"d-flex justify-content-between align-items-center"}>
                                <div className="me-2 d-flex justify-content-start">
                                    <div>
                                        Page{' '}
                                    </div>
                                    <strong className={"d-flex justify-content-start"}>
                                        <div>
                                            <input
                                                className="page-number-input"
                                                type="number"
                                                value={currentPage}
                                                min={1}
                                                max={totalPages}
                                                onChange={e => dispatch(setCurrentPage(e.target.value))}
                                            />
                                        </div>
                                        <div className={"me-2"}>
                                            of {totalPages}
                                        </div>
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </> : null}
            </>
        </div>
    // </>
)
}

export default ClientsTable;