import {createAsyncThunk} from '@reduxjs/toolkit';
import FeatureOptionsService from "../../services/features/FeatureOptionsService";

export const fetchFeaturesOptions = createAsyncThunk('product/fetchFeaturesOptions', async () => {
    try {
        return await FeatureOptionsService.getAllFeaturesOptions();
    } catch (error) {
        throw error;
    }
});

export const fetchFeatureOptions = createAsyncThunk('product/fetchFeatureOptions', async (id) => {
    try {
        return await FeatureOptionsService.getSingleFeatureOptions(id);
    } catch (error) {
        throw error;
    }
});

export const addFeatureOptions = createAsyncThunk('product/addFeatureOptions', async (params) => {
    const data ={name: params.name, feature_id: params.featureId};
    try {
        return await FeatureOptionsService.addFeatureOptions(data);
    } catch (error) {
        throw error;
    }
});

export const updateFeatureOptions = createAsyncThunk('product/updateFeatureOptions', async (params) => {
    const {featureOptionsId, featureOptionsData} = params;
    try {
        return await FeatureOptionsService.updateFeatureOptions(featureOptionsId, featureOptionsData);
    } catch (error) {
        throw error;
    }
});

export const deleteFeatureOptions = createAsyncThunk('product/deleteFeatureOptions', async (id) => {
    try {
        return await FeatureOptionsService.deleteFeatureOptions(id);
    } catch (error) {
        throw error;
    }
});