import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import "./CreateRequest.css";
import AddProductTabComponent from "../../components/CreateRequestTabsContent/AddProductTab.component";
import CustomerInformationTabComponent
    from "../../components/CreateRequestTabsContent/CustomerInformationTab.component";
import ShippingPaymentComponent from "../../components/CreateRequestTabsContent/ShippingPayment.component";
import OrderSummaryTabComponent from "../../components/CreateRequestTabsContent/OrderSummaryTab.component";

const CustomTab = ({ title, isActive, onTabClick }) => {
    return (
        <div
            onClick={onTabClick}
            className={`request-custom-tab ${isActive ? 'active-tab' : ''}`}
        >
            <span className="trapezoid-container">
                <span className="trapezoid"></span>
                <span className="text-on-trapezoid">{title}</span>
            </span>
        </div>
    );
};

const CreateRequestPage = () => {
    const [key, setKey] = useState('add_products');

    const handleTabSelect = (k) => {
        setKey(k);
    };

    return (
        <div className={"create-request-tabs-container"}>
            <h4 className="fw-bold mainColor">Create a new request</h4>
            <p className="mainColor">
                Create orders for your customers and complete them without their intervention. The order will be saved in drafts
                when you do not complete the order creation steps
            </p>

            <Tabs
                activeKey={key}
                onSelect={handleTabSelect}
                className="create-request-tabs"
                id="controlled-tab-example"
            >
                <Tab eventKey="add_products" title={<CustomTab title="Add Products" isActive={key === 'add_products'}
                                                               onTabClick={() => handleTabSelect('add_products')}/>}>
                    {/* Tab 1 Content */}
                    <AddProductTabComponent />
                </Tab>
                <Tab eventKey="customer_information"
                     title={<CustomTab title="Customer information" isActive={key === 'customer_information'}
                                       onTabClick={() => handleTabSelect('customer_information')}/>}>
                    {/* Tab 2 Content */}
                    <CustomerInformationTabComponent />
                </Tab>
                <Tab eventKey="shipping"
                     title={<CustomTab title="Shipping and payment method" isActive={key === 'shipping'}
                                       onTabClick={() => handleTabSelect('shipping')}/>}>
                    {/* Tab 3 Content */}
                    <div className="tab-content">
                        <ShippingPaymentComponent />
                    </div>
                </Tab>
                <Tab eventKey="order_summary" title={<CustomTab title="Order summary" isActive={key === 'order_summary'}
                                                                onTabClick={() => handleTabSelect('order_summary')}/>}>
                    {/* Tab 4 Content */}
                    <OrderSummaryTabComponent />
                </Tab>
            </Tabs>
        </div>
    );
};

export default CreateRequestPage;