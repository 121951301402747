import {api, BackEnd_EndPoint} from "../config";

const SignInApi = async (signInData) => {
    try {
        const response = await api.post(`${BackEnd_EndPoint}login`, signInData,{
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export default SignInApi;