import { api, BackEnd_EndPoint } from '../config';
import {toast} from "react-toastify";

const SubcategoryService = {
    getSubcategories: async () => {
        try {
            const response = await api.get(`${BackEnd_EndPoint}subCategory`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    },

    getSubcategory: async (subcategoryId) => {
        try {
            const response = await api.get(`${BackEnd_EndPoint}subCategory/${subcategoryId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    },

    addSubcategory: async (subcategoryData) => {
        try {
            const response = await api.post(`${BackEnd_EndPoint}subCategory`, subcategoryData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success(response.data.message, {position: "bottom-right", theme: "dark"});
            return response.data;
        } catch (error) {
            toast.error(error.response.data.message, {position: "bottom-right", theme: "dark"});
            console.log(error)
        }
    },

    updateSubcategory: async (subCategoryId, subCategoryData) => {
        try {
            const response = await api.put(`${BackEnd_EndPoint}subCategory/${subCategoryId}`, subCategoryData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error updating Subcategory:', error.message || error);
            throw error;
        }
    },

    deleteSubcategory: async (subcategoryId) => {
        try {
            const response = await api.delete(`${BackEnd_EndPoint}subCategory/${subcategoryId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error deleting Subcategory:', error.message || error);
            throw error;
        }
    },

    reactivateSubcategory: async (subcategoryId) => {
        try {
            const response = await api.put(`subCategory/${subcategoryId}`, {status: "active"}, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            toast.success(response.data.message, {position: "bottom-right", theme: "dark"});
            return response.data;
        } catch (error) {
            toast.error(error.response.data.message, {position: "bottom-right", theme: "dark"});
            console.log(error)
        }
    }
};

export default SubcategoryService;