import {useEffect, useState} from 'react';
import {Button, Col, Dropdown, Form} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import {Formik} from "formik";
import {addProduct} from "../../features/product/productActions";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import CenteredModal from "../../components/Modals/CenteredModal/CenteredModal";
import AddFeatureForm from "./AddFeatureForm";
// import {fetchFeatureOptions} from "../../features/feature/featureOptionsActions";
import {fetchFeatures} from "../../features/feature/featureActions";

const AddProductForm = ({setCreateProduct, categories}) => {
    const dispatch = useDispatch();
    const [subCategories, setSubCategories] = useState([]);
    const [show, setShow] = useState();
    const [selectedFeatureId, setSelectedFeatureId] = useState(null);
    const features = useSelector(state => state?.feature?.data)
    // const featuresOptions = useSelector(state => state?.featureOptions?.data)
    const [featuresOptions, setFeaturesOptions] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchFeatures());
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (features.length > 0) {
            setSelectedFeatureId(features[0].id);
        }
    }, [features]);
    const initialValues = {
        name: "",
        code: "",
        weight: 0.0,
        discount: 0.0,
        price: 0.0,
        quantity: 0,
        minQty: "",
        maxQty: "",
        description: "",
        subCategory_id: 0,
        features: 0,
        featuresOptions: 0
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Product Name is required"),
        code: Yup.string().required("Product Code is required"),
        weight: Yup.number().required("Product Weight is required"),
        category: Yup.string().required("Product Category is required"),
        discount: Yup.number().required("Discount is required"),
        price: Yup.number().required("Price is required"),
        quantity: Yup.number().required("Quantity is required"),
        minQty: Yup.number().required("Minimum Quantity per order is required"),
        maxQty: Yup.number().required("Maximum Quantity per order is required"),
        description: Yup.string().required("Description is required"),
    });

    const handleSubmit = (values, actions) => {
        const newProduct = {
            name: values.name,
            code: values.code,
            weight: parseFloat(values.weight),
            discount: parseFloat(values.discount),
            price: parseFloat(values.price),
            quantity: parseInt(values.quantity),
            minQty: parseInt(values.minQty),
            maxQty: parseInt(values.maxQty),
            description: values.description,
            subCategory_id: parseInt(values.subCategory_id) || 1,
            features: parseInt(values.featuresOptions),
        };

        dispatch(addProduct(newProduct));
        actions.resetForm();
        setCreateProduct(false);
        toast.success("Product added successfully", { position: "bottom-right", theme: "dark" });
    };


    // const handleCategoryClick = (selectedCategory) => {
    //     categoryInputRef.current.value = selectedCategory;
    // };

    const handleSelectedCategory = (categoryId) => {
        const selectedCategory = categories.find((category) => Number(category.id) === Number(categoryId));
        setSubCategories(selectedCategory?.subCategories);
    }

    const handleSelectedFeature = async (event) => {
        const selectedFeature = features.find((feature) => Number(feature.id) === Number(event?.target?.value));
        setFeaturesOptions(selectedFeature?.options)
        // setFeaturesOptions
        // const featureId = event.target.value;
        // setSelectedFeatureId(featureId);
        // await dispatch(fetchFeatureOptions(featureId));
    }


    useEffect(() => {
        if(features.length > 0){
            setSelectedFeatureId(features[0].id);
            setFeaturesOptions(features[0].options);
        }
    }, [features]);
    return (
        <>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {(formik) => (
                <Form onSubmit={formik.handleSubmit} className={"create-product-form row"}>
                    <Col lg={7}>
                        <Row>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Product Name</Form.Label>
                                <Form.Control type="text" name={"name"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                {formik.touched.name && formik.errors.name && <div className={"error-message"}>{formik.errors.name}</div>}
                            </Form.Group>
                        </Row>
                        <Row className={"my-3"}>
                            <Col lg={4}>
                                <Form.Group className="mb-3" controlId="code">
                                    <Form.Label>Product code SKU</Form.Label>
                                    <Form.Control type="text" name={"code"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {formik.touched.code && formik.errors.code && <div className={"error-message"}>{formik.errors.code}</div>}
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3" controlId="weight">
                                    <Form.Label>Weight</Form.Label>
                                    <Form.Control type="text" name={"weight"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {formik.touched.weight && formik.errors.weight && <div className={"error-message"}>{formik.errors.weight}</div>}
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3" controlId="cost">
                                    <Form.Label>Product Cost</Form.Label>
                                    <Form.Control type="text" name={"cost"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {formik.touched.cost && formik.errors.cost && <div className={"error-message"}>{formik.errors.cost}</div>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <div>
                            <p>Quantities in warehouses</p>
                            <div className={"quantities-container py-3 px-4"}>
                                <div className={"d-flex justify-content-start align-items-center"}>
                                    <Dropdown className={"me-3"}>
                                        <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                                            Factory outlet
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Form.Group controlId="quantity" className={"w-50"}>
                                        <Form.Control type="number" name={"quantity"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        {formik.touched.quantity && formik.errors.quantity && <div className={"error-message"}>{formik.errors.quantity}</div>}
                                    </Form.Group>
                                </div>
                                <div className={"d-flex justify-content-between align-content-center"}>
                                    unlimited <Form.Check type="switch" id="custom-switch" /> limited
                                </div>
                            </div>
                        </div>
                        <Row className={"my-4"}>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="minQty">
                                    <Form.Label>Minimum quantity per order</Form.Label>
                                    <Form.Control type="text" name={"minQty"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {formik.touched.minQty && formik.errors.minQty && <div className={"error-message"}>{formik.errors.minQty}</div>}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3" controlId="maxQty">
                                    <Form.Label>Maximum quantity per order</Form.Label>
                                    <Form.Control type="text" name={"maxQty"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {formik.touched.maxQty && formik.errors.maxQty && <div className={"error-message"}>{formik.errors.maxQty}</div>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Form.Group controlId="description">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as={"textarea"} style={{height: "100px", width:"100%"}} name={"description"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {formik.touched.description && formik.errors.description && <div className={"error-message"}>{formik.errors.description}</div>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={5}>
                        <Row>
                            <Col lg={6} className={"mb-3"}>
                                <Form.Group controlId="discount">
                                    <div className={"d-flex justify-content-start"}>
                                        <Form.Check type="checkbox" id="custom-switch2" className={"me-2"} />
                                        <Form.Label>Discount</Form.Label>
                                    </div>
                                    <Form.Control type="number" name={"discount"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {formik.touched.discount && formik.errors.discount && <div className={"error-message"}>{formik.errors.discount}</div>}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group controlId="price">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control type="number" name={"price"} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {formik.touched.price && formik.errors.price && <div className={"error-message"}>{formik.errors.price}</div>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            {/* Category Select */}
                            <Row className={"mb-3"}>
                                <Form.Group controlId="category">
                                    <Form.Label>Category</Form.Label>
                                        <Form.Select
                                            name={"category"}
                                            onChange={(e) => {
                                                // Call handleSelectedCategory with the selected category ID
                                                const selectedCategoryId = e.target.value;
                                                handleSelectedCategory(selectedCategoryId);
                                                formik.handleChange(e)
                                            }}
                                            onBlur={formik.handleBlur}
                                        >
                                            <option value="">Select Category</option>
                                            {categories?.map((category, index) => (
                                                <option key={index} value={category.id}> {/* Assuming category ID is used */}
                                                    {category?.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    {formik.touched.category && formik.errors.category && (
                                        <div className={"error-message"}>{formik.errors.category}</div>
                                    )}
                                </Form.Group>
                            </Row>

                            {/* Subcategory Select */}
                            <Row>
                                <Form.Group controlId="subCategory">
                                    <Form.Label>Sub Category</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name={"subCategory_id"}
                                            onChange={(e)=>formik.handleChange(e)}
                                            onBlur={formik.handleBlur}
                                        >
                                            <option value="">Select Sub Category</option>
                                            {subCategories?.map((subCategory, index) => (
                                                <option key={index} value={subCategory.id}>
                                                    {subCategory?.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    {formik.touched.subCategory && formik.errors.subCategory && (
                                        <div className={"error-message"}>{formik.errors.subCategory}</div>
                                    )}
                                </Form.Group>
                            </Row>
                            <Row className={"mt-3"}>
                                <Form.Group controlId="feature" className={"mb-3"}>
                                    <Form.Label>
                                        Feature
                                    </Form.Label>
                                    <Form.Control
                                        as="select" aria-label="Default select example" onChange={(e) => {
                                        handleSelectedFeature(e);
                                        formik.handleChange(e);
                                    }}>
                                        <option value="" disabled>Select a feature...</option>
                                        {features?.map(feature => <option key={feature.id} value={feature.id}>{feature.name}</option>)}
                                    </Form.Control>
                                    {formik.touched.feature && formik.errors.feature && (
                                        <div className={"error-message"}>{formik.errors.feature}</div>
                                        )}
                                </Form.Group>
                                {selectedFeatureId ?
                                    <Form.Group controlId="featureOptions">
                                        <Form.Label>Feature Options</Form.Label>
                                        <Form.Control as="select" name="featuresOptions" onChange={formik.handleChange}>
                                            {Array.isArray(featuresOptions) ? (
                                                featuresOptions?.map((featuresOption) => (
                                                    <option key={featuresOption?.id} value={featuresOption?.id}>
                                                        {featuresOption?.name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option key={featuresOptions?.id} value={featuresOptions?.id}>
                                                    {featuresOptions?.name}
                                                </option>
                                            )}
                                        </Form.Control>
                                        {formik.touched.featuresOptions && formik.errors.featuresOptions && (
                                            <div className={"error-message"}>{formik.errors.featuresOptions}</div>
                                            )}
                                    </Form.Group>
                                    : null}
                            </Row>
                            <center>
                                <Button style={{width: "fit-content"}} onClick={()=>setShow(true)} className={"submit-btn rounded-pill px-3 fs-5 fw-bold my-3"}>
                                        Add Feature
                                </Button>
                            </center>

                        </Row>
                    </Col>
                    <center>
                        <Button style={{width: "fit-content"}} type={"submit"} className={"submit-btn rounded-pill px-3 fs-5 fw-bold my-3"}>Submit</Button>
                    </center>
                </Form>
            )}
        </Formik>
            <CenteredModal show={show} onHide={()=> setShow(false)}>
                <AddFeatureForm selectedFeatureId={selectedFeatureId} features={features} setSelectedFeatureId={setSelectedFeatureId} />
            </CenteredModal>
        </>
    );
};

export default AddProductForm;