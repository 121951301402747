import { Navigate } from 'react-router-dom';
// import {useSelector} from "react-redux";

function ProtectedRouteAdmin({ isSignedIn,
                                 // requiredRoles,
                                 children }) {
    // const userInfo = useSelector((state) => state.auth.userInfo)
    if (isSignedIn) {
        // if (userInfo?.type === "client"){
        //     return <Navigate to="/"/>;
        // }
        return children;
    } else {
        return <Navigate to="/admin/login"/>;
    }
}

export default ProtectedRouteAdmin;