import {AiOutlineLock} from 'react-icons/ai';
import "./generic-grid.css";
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";

const GenericGridComponent = ({data, dataSecondGrid}) => {
    return (<>
        {data && (<Row className={"justify-content-start my-2"}>
            {data.map((item, index) => (<Col lg={4} md={6} xs={12} key={index}>
                <div className={"grid-container-first p-4 mb-5 mx-3"}>
                    <div className={`grid-container-first-title ${item.locked ? ' locked' : ''}`}>
                        {item.locked && (<div className="lock-icon">
                            <AiOutlineLock size={30}/>
                        </div>)}
                        <div className="data-info">
                            {item.value} {item.type}
                        </div>
                    </div>
                    <div className="grid-container-first-details">
                        {item.description}
                    </div>
                </div>
            </Col>))}
        </Row>)}
        {dataSecondGrid && (<Row className={"justify-content-between"}>
            {dataSecondGrid.map((item, index) => (<Col xl={6} key={index}>
                <div className={"grid-container-second p-4 mb-5 mx-3"}>
                    <div className={'grid-container-second-title'}>
                        {item.title}
                    </div>
                    {item.widgets ? <div className={"widgets-container"}>
                        {item.widgets.map((widget, index) => (<div className={"widget"} key={index}>
                                {widget}
                            </div>))}
                    </div> : <div className={`grid-container-second-content ${item.locked ? ' locked' : ''}`}>
                        {item.locked && <div className="lock-icon">
                            <AiOutlineLock size={30}/>
                        </div>}
                        <div className={"mt-2 grid-container-second-details"}>
                            {item.description}
                        </div>
                    </div>}
                </div>
            </Col>))}
        </Row>)}
    </>);
};
export default GenericGridComponent;
