import Row from "react-bootstrap/Row";
import { Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {addCustomer} from "../../features/customer/customerActions";

const AddCustomerForm = ({setShow}) => {
    const dispatch = useDispatch();
    const clientId = useSelector(state => state?.auth?.userInfo?.id)
    const handleSubmit = async (values, { setSubmitting }) => {
        const params = {
            ...values,
            clientId: clientId
        }
        console.log(params);
        await dispatch(addCustomer(params));
        setSubmitting(false);
        setShow(false);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        phone: Yup.string().required("Phone is required"),
        city: Yup.string(),
        description: Yup.string(),
        apartment: Yup.string(),
        floor: Yup.string()
    });

    return (
        <div className={"content-container"}>
            <Formik
                initialValues={{
                    name: "",
                    phone: "",
                    city: "",
                    description: "",
                    apartment: "",
                    floor: ""
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit, errors, touched }) => (
                    <Form onSubmit={handleSubmit} className={"p-3"}>
                        <Row>
                            <Col lg={6}>
                                <Form.Group controlId={"name"} className={"mb-3"}>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Name"
                                        name={"name"}
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={touched.name && !!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group controlId={"phone"} className={"mb-3"}>
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Phone"
                                        name={"phone"}
                                        value={values.phone}
                                        onChange={handleChange}
                                        isInvalid={touched.phone && !!errors.phone}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.phone}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Group controlId={"city"} className={"mb-3"}>
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="City"
                                        name={"city"}
                                        value={values.city}
                                        onChange={handleChange}
                                        isInvalid={touched.city && !!errors.city}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.city}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group controlId={"apartment"} className={"mb-3"}>
                                    <Form.Label>Apartment</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Apartment"
                                        name={"apartment"}
                                        value={values.apartment}
                                        onChange={handleChange}
                                        isInvalid={touched.apartment && !!errors.apartment}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.apartment}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Group controlId={"floor"} className={"mb-3"}>
                                    <Form.Label>Floor</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Floor"
                                        name={"floor"}
                                        value={values.floor}
                                        onChange={handleChange}
                                        isInvalid={touched.floor && !!errors.floor}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.floor}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                                <Form.Group controlId={"description"} className={"mb-3"}>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as={"textarea"}
                                        style={{ height: "100px" }}
                                        placeholder="Description"
                                        name={"description"}
                                        value={values.description}
                                        onChange={handleChange}
                                        isInvalid={touched.description && !!errors.description}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.description}
                                    </Form.Control.Feedback>
                                </Form.Group>
                        </Row>
                        <center>
                            <Button type="submit" className={"submit-button fw-bold"}>Submit</Button>
                        </center>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AddCustomerForm;