import {ReactSVG} from "react-svg";
import img404 from "../../assets/icons/404.svg";
import {Button} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import NotFoundBG from "../../assets/icons/NotFoundBG.svg";
const NotFoundPage = () => {
    const location = useLocation();
    const adminPath = location.pathname.includes("admin");
    const url = adminPath ? "/admin/dashboard" : "/";
    return (
        <div className={"d-flex flex-column align-items-center align-content-center justify-content-start vh-100"} style={{backgroundImage: `url(${NotFoundBG})`}}>
            <ReactSVG src={img404} />
            <div className={"fs-5 text-uppercase my-5"} style={{color: "#565872"}}>Opps! Page Not Found</div>
            <Link to={url}>
                <Button className={"submit-btn"}>
                    Back To Home
                </Button>
            </Link>
        </div>
    );
};

export default NotFoundPage;
