import Container from "react-bootstrap/Container";
import {Outlet} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import SidebarAdmin from "./SidebarAdmin";
import HeaderComponent from "../../../components/Layout/Header/Header.component";
const LayoutComponent = () => {
    return (
        <div className={"d-flex justify-content-between mt-2"}>
            <ToastContainer />
            <div className={"px-3"}>
                <SidebarAdmin />
            </div>
            <Container>
                <main className={"flex-grow-1"}>
                    <HeaderComponent />
                    <Outlet />
                </main>
            </Container>
        </div>
    );
};

export default LayoutComponent;
