import {createAsyncThunk} from '@reduxjs/toolkit';
import SubcategoryService from "../../services/subcategory/SubcategoryService";

export const fetchSubcategories = createAsyncThunk('subcategory/fetchSubcategories', async () => {
    try {
        return await SubcategoryService.getSubcategories();
    } catch (error) {
        throw error;
    }
});

export const fetchSubcategory = createAsyncThunk('subcategory/fetchSubcategory', async (id) => {
    try {
        return await SubcategoryService.getSubcategory(id);
    } catch (error) {
        throw error;
    }
})

export const addSubcategory = createAsyncThunk('subcategory/addSubcategory', async (subcategoryData) => {
    try {
        return await SubcategoryService.addSubcategory(subcategoryData);
    } catch (error) {
        throw error;
    }
});

export const updateSubcategory = createAsyncThunk('subcategory/updateSubcategory', async (params) => {
    const {subCategoryId, subCategoryData} = params;
    try {
        return await SubcategoryService.updateSubcategory(subCategoryId, subCategoryData);
    } catch (error) {
        throw error;
    }
})

export const deleteSubcategory = createAsyncThunk('subcategory/deleteSubcategory', async (id) => {
    try {
        return await SubcategoryService.deleteSubcategory(id);
    } catch (error) {
        throw error;
    }
});

export const reactivateSubcategory = createAsyncThunk('subcategory/reactivateSubcategory', async (id) => {
    try {
        return await SubcategoryService.reactivateSubcategory(id);
    } catch (error) {
        throw error;
    }
})