import './App.css';
import 'react-phone-number-input/style.css'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Layout from "./components/Layout/Layout";
import RequestsPage from "./pages/Requests/Requests.page";
import AbandonedBasketsPage from "./pages/AbandonedBaskets/AbandonedBaskets.page";
import ProductsPage from "./pages/Products/Products.page";
import AnalyticsPage from "./pages/Analytics/Analytics.page";
import RequestMessagePage from "./pages/RequestMessage/RequestMessage.page";
import DraftsPage from "./pages/Drafts/Drafts.page";
import CreateRequestPage from "./pages/Requests/CreateRequest.page";
import {lazy, Suspense} from "react";
import LoadingAnimation from "./components/LoadingAnimation/LoadingAnimation";
import {ErrorBoundary} from 'react-error-boundary';
import SignUpPage from "./pages/Auth/SignUp.page";
import SignInPage from "./pages/Auth/SignIn.page";
import 'react-toastify/dist/ReactToastify.css';
import {useSelector} from "react-redux";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRouteClient";
import NotFoundPage from "./pages/NotFound/NotFound.page";
import Customers from "./pages/Customers/Customers";
import GrapesJs from "./pages/WebBuilder/GrapesJS";
import ProtectedRouteAdmin from "./components/ProtectedRoute/ProtectedRouteAdmin";
import LayoutComponent from "./pages/Admin/Layout/LayoutComponent";
import AdminDashboard from "./pages/Admin/Dashboard/AdminDashboard";
import ClientsPage from "./pages/Admin/Clients/Clients.page";

const HomePage = lazy(() => import('./pages/Home/Home.page'));

function App() {
    const userInfo = useSelector((state) => state.auth.userInfo)
    return (<ErrorBoundary
        FallbackComponent={() => <div>Something went wrong!</div>}
    >
        <Suspense fallback={<LoadingAnimation/>}>
                    <Router>
                        <Routes>
                            <Route
                                path="/"
                                element={<ProtectedRoute isSignedIn={userInfo} children={<Layout/>}/>}
                            >
                                <Route index element={<HomePage/>}/>
                                <Route path={"/requests"} element={<RequestsPage/>}/>
                                <Route path={"/drafts"} element={<DraftsPage/>}/>
                                <Route path={"/request-message"} element={<RequestMessagePage/>}/>
                                <Route path={"/create-request"} element={<CreateRequestPage/>}/>
                                <Route path={"/analytics"} element={<AnalyticsPage/>}/>
                                <Route path={"/abandoned_baskets"} element={<AbandonedBasketsPage/>}/>
                                <Route path={"/products"} element={<ProductsPage/>}/>
                                <Route path={"/customers"} element={<Customers />}/>
                                <Route path={"/design"} element={<div>design</div>}/>
                                <Route path={"/tickets"} element={<div>tickets</div>}/>
                                <Route path={"/loyalty"} element={<div>loyalty</div>}/>
                                <Route path={"/discounts"} element={<div>discounts</div>}/>
                                <Route path={"/auto_discounts"} element={<div>auto_discounts</div>}/>
                                <Route path={"/marketing"} element={<div>marketing</div>}/>
                                <Route path={"/text_campaigns"} element={<div>text_campaigns</div>}/>
                                <Route path={"/charity"} element={<div>charity</div>}/>
                                <Route path={"/seo"} element={<div>seo</div>}/>
                                <Route path={"/affiliate"} element={<div>affiliate</div>}/>
                                <Route path={"/settings"} element={<div>settings</div>}/>
                            </Route>
                            <Route path="*" element={<NotFoundPage />} />
                            <Route path={"/client/register"} element={<SignUpPage/>}/>
                            <Route path={"/client/login"} element={<SignInPage/>}/>
                            <Route path={"/build-website"} element={<GrapesJs />}/>
                            <Route path="/admin" element={<ProtectedRouteAdmin isSignedIn={userInfo}><LayoutComponent /></ProtectedRouteAdmin>}>
                                <Route path="/admin/dashboard" element={<AdminDashboard />}/>
                                <Route path="/admin/reports" element={"Reports goes here"}/>
                                <Route path="/admin/clients" element={<ClientsPage />}/>
                            </Route>
                            <Route path={"/admin/login"} element={<SignInPage/>}/>
                        </Routes>
                    </Router>
        </Suspense>
    </ErrorBoundary>);
}

export default App;
