import {useMemo} from "react";
import RequestsTableComponent from "./RequestsTable.component";

const RequestsDataTableComponent = () => {
    const data = [
        {
            orderNumber: '123456',
            client: 'John Doe',
            dateOfApplication: '2023-11-15',
            modificationDate: '2023-11-20',
            orderStatus: 'Delivery is in progress',
            shipping: 'Free Shipping',
            payingOff: '$100.00',
            notes: 'This is a note about the order.',
            paymentStatus: 'Paid',
            total: '$500.00',
        },
        {
            orderNumber: '789012',
            client: 'Jane Smith',
            dateOfApplication: '2023-11-10',
            modificationDate: '2023-11-18',
            orderStatus: 'Ready',
            shipping: 'Express Shipping',
            payingOff: '$75.00',
            notes: 'No special notes.',
            paymentStatus: 'Pending',
            total: '$300.00',
        },
        {
            orderNumber: '345678',
            client: 'Bob Johnson',
            dateOfApplication: '2023-11-12',
            modificationDate: '2023-11-22',
            orderStatus: 'Preparing',
            shipping: 'Standard Shipping',
            payingOff: '$50.00',
            notes: 'Order being prepared for shipment.',
            paymentStatus: 'Paid',
            total: '$200.00',
        },
        {
            orderNumber: '901234',
            client: 'Alice Williams',
            dateOfApplication: '2023-11-18',
            modificationDate: '2023-11-23',
            orderStatus: 'New',
            shipping: 'Free Shipping',
            payingOff: '$120.00',
            notes: 'New order received.',
            paymentStatus: 'Pending',
            total: '$600.00',
        },
        {
            orderNumber: '567890',
            client: 'Eve Davis',
            dateOfApplication: '2023-11-14',
            modificationDate: '2023-11-21',
            orderStatus: 'Everyone',
            shipping: 'Express Shipping',
            payingOff: '$90.00',
            notes: 'Order for everyone in the office.',
            paymentStatus: 'Paid',
            total: '$450.00',
        },
    ];

    const columns = useMemo(() => [
        {
            Header: 'Order Number',
            accessor: 'orderNumber',
        },
        {
            Header: 'Client',
            accessor: 'client',
        },
        {
            Header: 'Date of Application',
            accessor: 'dateOfApplication',
        },
        {
            Header: 'Modification Date',
            accessor: 'modificationDate',
        },
        {
            Header: 'Order Status',
            accessor: 'orderStatus',
            Cell: ({ row }) => {
                const orderStatus = row.original.orderStatus;
                return <div className={"mx-auto social-icon-container"}>{orderStatus}</div>;
            },
        },
        {
            Header: 'Shipping',
            accessor: 'shipping',
        },
        {
            Header: 'Paying Off',
            accessor: 'payingOff',
        },
        {
            Header: 'Notes',
            accessor: 'notes',
        },
        {
            Header: 'Payment Status',
            accessor: 'paymentStatus',
        },
        {
            Header: 'Total',
            accessor: 'total',
        },
    ], []);

    return (<RequestsTableComponent data={data} columns={columns} />);
};

export default RequestsDataTableComponent;