import GenericGridComponent from "../Shared-Components/GenericGrid.component";

const DiscountsGridComponent = () => {
    const data = [{value: 0, type: "Coupon", description: "Number of discount coupons"}, {
        value: 5536, type: "SAR", description: "Number of discount voucher requests", locked: true
    }, {
        value: 5536, type: "SAR", description: "Number of clients", locked: true
    }, {value: 0, type: "SAR", description: "Total sales of discount coupon orders"}, {
        value: 0,
        type: "SAR",
        description: "Average sales per discount coupon"
    }, {
        value: 5536, type: "SAR", description: "Average number of orders per discount coupon", locked: true
    }, {
        value: 5536, type: "SAR", description: "Return customer rate", locked: true
    }, {value: 0, type: "SAR", description: "Total sales of automatic discount orders"},];

    const dataSecondGrid = [{
        title: "Number of orders for each automatic discount type", description: "There is no matching data"
    }, {
        title: "Perform discounts",
        widgets: ["Total sales of discount coupon orders", "Total value of discount coupons", "Number of discount voucher requests", "Voucher name"]
    },];
    return (<GenericGridComponent data={data} dataSecondGrid={dataSecondGrid}/>);
};

export default DiscountsGridComponent;
