import GjsEditor from '@grapesjs/react';
import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import websitePlugin from 'grapesjs-preset-webpage';
import basicBlockPlugin from 'grapesjs-blocks-basic'
import formPlugin from 'grapesjs-plugin-forms'
const GrapesJs = () => {
    const onEditor = (editor) => {
        console.log('Editor loaded', { editor });
    };
    return (
        <GjsEditor
            grapesjs={grapesjs}
            options={{
                height: '100vh',
                storageManager: false,
            }}
            plugins={[websitePlugin, basicBlockPlugin, formPlugin]}
            onEditor={onEditor}
        />)
};

export default GrapesJs;
