import {api} from "../config";
import {toast} from "react-toastify";

const FeatureOptionsService = {
    getAllFeaturesOptions: async () => {
        try {
            const response = await api.get("featureCategories", {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    },

    addFeatureOptions: async (featureData) => {
        try {
            const response = await api.post("featureCategories", featureData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            toast.success(response.data.message, {position: "bottom-right", theme: "dark"})
            return response.data;
        } catch (error) {
            toast.error(error.response.data.message, {position: "bottom-right", theme: "dark"})
            throw error;
        }
    },

    updateFeatureOptions: async (featureId, featureData) => {
        try {
            const response = await api.put(`featureCategories/${featureId}`, featureData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error updating feature:', error);
            throw error;
        }
    },

    deleteFeatureOptions: async (featureId) => {
        try {
            const response = await api.delete(`featureCategories/${featureId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            toast.success(response.data.message, {position: "bottom-right", theme: "dark"})
            return response.data;
        } catch (error) {
            toast.error(error.response.data.message, {position: "bottom-right", theme: "dark"})
            throw error;
        }
    },

    getSingleFeatureOptions: async (featureId) => {
        try {
            const response = await api.get(`featureCategories/${featureId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    }

}
export default FeatureOptionsService;