import "./sales-grid.css";
import GenericGridComponent from "../Shared-Components/GenericGrid.component";

const SalesGridComponent = () => {
    const data = [{value: 10, type: "requests", description: "Total payment fees for delivery"}, {
        value: 5,
        type: "requests",
        description: "Shipping revenue"
    }, {value: 15, type: "sar", description: "Product sales"}, {
        value: 8,
        type: "sar",
        description: "Tax revenue"
    }, {value: 12, type: "requests", description: "Refunds processed"}, {
        value: 3,
        type: "sar",
        description: "Total revenue"
    }, {value: 6, type: "requests", description: "New customers"}, {
        value: 7,
        type: "requests",
        description: "Customers churn rate",
        locked: true
    }, {value: 9, type: "sar", description: "Net profit"}, {
        value: 14,
        type: "requests",
        description: "Expenses"
    }, {value: 2, type: "sar", description: "Gross profit", locked: true}, {
        value: 11,
        type: "sar",
        description: "Average order value"
    },];

    const dataSecondGrid = [{
        title: "Top selling cities",
        description: "There is no matching data"
    }, {title: "Best selling product", description: "Shipping revenue"}, {
        title: "Number of requests over time",
        description: "Product sales"
    }, {title: "Sales over time", description: "Tax revenue"}, {
        title: "Sales are based on shipping method",
        description: "Refunds processed",
        locked: true
    }, {
        title: "Sales based on payment method",
        description: "Total revenue",
        locked: true
    }, {title: "Sales based on sales channel", description: "New customers", locked: true},];

    return (<GenericGridComponent data={data} dataSecondGrid={dataSecondGrid} />);
};

export default SalesGridComponent;
