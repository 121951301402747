import { createSlice } from '@reduxjs/toolkit';

export const paginationSlice = createSlice({
    name: 'pagination',
    initialState: {
        totalPages: 1,
        currentPage: 1,
        recordsPerPage: 10,
    },
    reducers: {
        setTotalPages: (state, action) => {
            state.totalPages = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setRecordsPerPage: (state, action) => {
            state.recordsPerPage = action.payload;
        },
    },
});

export const { setTotalPages, setCurrentPage, setRecordsPerPage } = paginationSlice.actions;

export const selectTotalPages = (state) => state.pagination.totalPages;
export const selectCurrentPage = (state) => state.pagination.currentPage;
export const selectRecordsPerPage = (state) => state.pagination.recordsPerPage;

export default paginationSlice.reducer;