import {createSlice} from '@reduxjs/toolkit';
import {
    fetchSubcategories,
    addSubcategory,
    deleteSubcategory,
    updateSubcategory,
    fetchSubcategory, reactivateSubcategory
} from "./subcategoryActions";

const initialState = {
    data: [], loading: false, error: null,
};

const subcategorySlice = createSlice({
    name: 'subcategory', initialState, reducers: {}, extraReducers: (builder) => {
        builder
            .addCase(fetchSubcategories.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchSubcategories.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
            })
            .addCase(fetchSubcategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(addSubcategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(addSubcategory.fulfilled, (state, action) => {
                state.data.push(action.payload.data);
            })
            .addCase(addSubcategory.rejected, (state, action) => {
                state.error = action?.error?.message;
            })
            .addCase(deleteSubcategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteSubcategory.fulfilled, (state, action) => {
                state.loading = false;
                const deletedSubcategoryId = action.meta.arg;
                state.data = state.data.map((subcategory)=> subcategory.id === deletedSubcategoryId  ? {...subcategory, status: "deactive"} : subcategory);
                state.error = null;
            })
            .addCase(deleteSubcategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(reactivateSubcategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(reactivateSubcategory.fulfilled, (state, action) => {
                state.loading = false;
                const reactivatedSubcategoryId = action.meta.arg;
                state.data = state.data.map((subcategory)=> subcategory.id === reactivatedSubcategoryId  ? {...subcategory, status: "active"} : subcategory);
                state.error = null;
            })
            .addCase(reactivateSubcategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(updateSubcategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateSubcategory.fulfilled, (state, action) => {
                state.loading = false;
                state.data = state.data.map((subcategory) => subcategory.id === action.payload.id ? action.payload : subcategory);
                state.error = null;
            })
            .addCase(updateSubcategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
            .addCase(fetchSubcategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchSubcategory.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
            })
            .addCase(fetchSubcategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message;
            })
    }
})

export default subcategorySlice.reducer;