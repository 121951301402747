import {Col, Form} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import {useState} from "react";
import ChooseProductModal from "./ChooseProductModal";

const AddProductTabComponent = () => {
    const [centeredModalShow, setCenteredModalShow] = useState(false);
    return (
        <div className="tab-content">
            <Row className={"justify-content-evenly align-items-center py-5"}>
                <Col lg={4} xs={10}>
                    <Form.Group className="mb-3" controlId="selectCurrency">
                        <Form.Label className={"mainColor"}>Select currency</Form.Label>
                        <Form.Select aria-label="SAR" className={"mainColor-select"}>
                            <option>SAR</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select> </Form.Group>
                </Col>
                <Col lg={4} xs={10}>
                    <Form.Group className="mb-3" controlId="selectCurrency" onClick={()=>setCenteredModalShow(true)}>
                        <Form.Label className={"mainColor"}>Choose products</Form.Label>
                        <Form.Select aria-label="Choose products" className={"mainColor-select"}>
                            <option>Choose products</option>
                        </Form.Select> </Form.Group>
                </Col>
                <ChooseProductModal show={centeredModalShow} onHide={()=>setCenteredModalShow(false)} />
            </Row>
        </div>
    );
};

export default AddProductTabComponent;