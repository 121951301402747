import React, {useState} from 'react';
import {Button, Card, Collapse, Dropdown, Form} from "react-bootstrap";
import {ReactSVG} from "react-svg";
import Dots from "../../assets/media/dots.svg";
import {Previews} from "../../components/Category/PreviewDropZone";
import editIcon from "../../assets/media/edit.svg";
import actions from "../../assets/media/actions.svg";
import SubCategory from "../../components/Category/SubCategory";
import {useDispatch, useSelector} from "react-redux";
import {deleteCategory, reactivateCategory, updateCategory} from "../../features/category/categoryActions";
import expandIcon from "../../assets/media/expand.svg";
import collapseIcon from "../../assets/media/collapse.svg";
import {Media_Endpoint} from "../../services/config";
import {HiOutlineTrash} from "react-icons/hi";
import {BiImport} from "react-icons/bi";
import NewSubCategory from "../../components/Category/NewSubCategory";
import {FaCheck} from "react-icons/fa";
import {FaCircleXmark} from "react-icons/fa6";

const ProductCategory = ({category}) => {
    const [openParent, setOpenParent] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [categoryName, setCategoryName] = useState(category?.name);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [createSubCategory, setCreateSubCategory] = useState(false);
    const categoryId = category?.id;
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        setCategoryName(e.target.value);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const categoryData = {name: categoryName, image: uploadedFile};
        const params = {categoryId, categoryData}
        if (params.categoryData.image === null && params.categoryData.name === category.name) {
            setEditMode(false);
            return
        }
        dispatch(updateCategory(params))
        setEditMode(false);
    };

    const handleDeleteCategory = async () => {
         await dispatch(deleteCategory(categoryId))
    }
    const handleReactivateCategory = async () => {
        await dispatch(reactivateCategory(categoryId))
    }
    const subCategories = category?.subCategories
    const userToken = useSelector((state) => state.auth.userToken);
    console.log(userToken)

    return (<div className={"d-flex justify-content-between align-items-center"}>
        <Card className={"mb-3 flex-grow-1"}>
            <Card.Header className={`flex-container-centered  ${category?.status === "deactive" && "danger-category"}`}>
                <div className="flex-container-centered">
                    <ReactSVG src={Dots}/>
                    {editMode ? (<Form onSubmit={handleFormSubmit} id={"edit-category-form"}
                                       className="d-flex justify-content-between align-items-center">
                        <Form.Control
                            type="text"
                            value={categoryName}
                            onChange={handleInputChange}
                            className="me-2"
                        />
                        <Previews setUploadedFile={setUploadedFile} uploadedFile={uploadedFile}/>
                    </Form>) : (<>
                        <Button
                            variant="link"
                            onClick={() => setOpenParent(!openParent)}
                            aria-controls="parent-collapse"
                            aria-expanded={openParent}
                            className="fw-bold fs-5 text-decoration-none mainColor"
                        >
                            {categoryName}
                        </Button>
                    </>)}
                </div>
                <div className="flex-container-centered">
                    {editMode && (<>
                            <Button type="submit" form="edit-category-form" variant="link"
                                    className="text-decoration-none mainColor">
                                <FaCheck className={"mainColor"} size={20}/>
                            </Button>
                            <Button variant="link" className="text-decoration-none" onClick={() => setEditMode(false)}>
                                <FaCircleXmark color={"red"} size={20}/>
                            </Button>
                        </>)}
                    {!editMode && (<>
                            <div style={{width: '100%', height: '100px'}}>
                                <img src={Media_Endpoint + category?.image} alt="Category Image"
                                     style={{width: '100%', height: '100px', objectFit: "cover"}}/>
                            </div>
                            <Button
                                variant="link"
                                className="text-decoration-none mainColor"
                                onClick={() => {
                                    setEditMode((prevEditMode) => !prevEditMode);
                                    console.log("Edit mode set to true:", editMode);
                                }}
                            >
                                <ReactSVG src={editIcon}/>
                            </Button>
                            <Dropdown>
                                <Dropdown.Toggle
                                    id="dropdown-basic"
                                    drop={"left"}
                                    style={{backgroundColor: "transparent", border: "unset"}}
                                    className={"dropdown-category"}
                                >
                                    <ReactSVG src={actions}/>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={"products-filter-dropdown-menu"}>
                                    <Dropdown.Item className={"flex-container-centered px-2"}
                                                   onClick={() => setCreateSubCategory(true)}>
                                    <span>
                                        Add Subcategory
                                    </span>
                                        <BiImport size={20} className={"mainColor"}/> </Dropdown.Item>
                                    {category?.status === "deactive" ?
                                        <Dropdown.Item className={"flex-container-centered px-2"} onClick={handleReactivateCategory}>
                                    <span className={"me-2"}>
                                         Reactivate Category
                                    </span>
                                            <FaCheck size={20} className={"text-white rounded-circle mainBgColor p-1"} />
                                        </Dropdown.Item>
                                        :
                                    <Dropdown.Item className={"flex-container-centered px-2"} onClick={handleDeleteCategory}>
                                    <span className={"me-2"}>
                                         Deactivate Category
                                    </span>
                                        <HiOutlineTrash size={20} color={"red"}/>
                                    </Dropdown.Item>}
                                </Dropdown.Menu>
                            </Dropdown>
                        </>)}


                </div>
            </Card.Header>
            {createSubCategory ? <NewSubCategory categoryId={categoryId} createSubCategory={createSubCategory}
                                                 setCreateSubCategory={setCreateSubCategory}/> : null}
            <Collapse in={openParent}>
                <div id="parent-collapse">
                    {subCategories?.map((subCategory, index) => (<SubCategory subCategory={subCategory} key={index}/>))}
                </div>
            </Collapse>
        </Card>
        {subCategories?.length > 0 ? (!openParent ? (
                <ReactSVG src={expandIcon} role={"button"} onClick={() => setOpenParent(true)}/>) : (
                <ReactSVG src={collapseIcon} role={"button"} onClick={() => setOpenParent(false)}/>)): null}
    </div>);
};

export default ProductCategory;