import {
    CCol,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CRow,
    CWidgetStatsA,
    CWidgetStatsC
} from "@coreui/react";
import {FaDollarSign} from "react-icons/fa";
import {CChartBar, CChartDoughnut, CChartLine} from "@coreui/react-chartjs";
import {cilArrowTop, cilChartPie, cilOptions} from "@coreui/icons";
import CIcon from "@coreui/icons-react";

const WidgetsRow = () => {
    return (
        <CRow>
            <CCol lg={3} md={4} sm={6}>
                <CWidgetStatsA
                    className="mb-4 admin-content-container"
                    value={
                        <>
                            $9.000{' '}
                            <span className="fs-6 fw-normal">
                                    (40.9%)
                                </span>
                        </>
                    }
                    title="Total Sales"
                    action={
                        <div className="rounded-2 p-2" style={{ backgroundColor: "rgba(13, 153, 255, 0.1)", color: "rgb(13, 153, 255)" }}>
                            <FaDollarSign size={25} style={{ fontWeight: "bolder" }} />
                        </div>
                    }
                    chart={
                        <CChartLine
                            customTooltips={false}
                            className="mt-3"
                            style={{ height: '70px' }}
                            data={{
                                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                                datasets: [
                                    {
                                        label: 'My First dataset',
                                        backgroundColor: 'rgba(13, 153, 255, 0.1)', // Lighter color for the gradient
                                        borderColor: 'rgb(13, 153, 255)', // Color for the line
                                        borderWidth: 2, // Adjust the width of the line
                                        data: [1, 18, 9, 17, 34, 22, 11],
                                        fill: {
                                            target: 'origin',
                                            above: 'rgba(13, 153, 255, 0.1)', // Lighter color for above the line
                                            below: 'rgba(255, 255, 255, 0)' // Transparent color for fading effect
                                        }
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                            drawBorder: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                    y: {
                                        min: -9,
                                        max: 39,
                                        display: false,
                                        grid: {
                                            display: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                },
                                elements: {
                                    line: {
                                        borderWidth: 1,
                                    },
                                    point: {
                                        radius: 4,
                                        hitRadius: 10,
                                        hoverRadius: 4,
                                    },
                                },
                            }}
                        />
                    }
                />
            </CCol>
            <CCol lg={3} md={4} sm={6}>
                <CWidgetStatsA
                    className="mb-4 admin-content-container"
                    value={
                        <>
                            $9.000{' '}
                            <span className="fs-6 fw-normal">
            (40.9% <CIcon height={16} icon={cilArrowTop} />)
          </span>
                        </>
                    }
                    title="Total Income"
                    action={
                        <CDropdown alignment="end">
                            <CDropdownToggle color="transparent" caret={false} className="p-0">
                                <CIcon height={20} icon={cilOptions} className="text-white" />
                            </CDropdownToggle>
                            <CDropdownMenu>
                                <CDropdownItem>Action</CDropdownItem>
                                <CDropdownItem>Another action</CDropdownItem>
                                <CDropdownItem>Something else here...</CDropdownItem>
                                <CDropdownItem disabled>Disabled action</CDropdownItem>
                            </CDropdownMenu>
                        </CDropdown>
                    }
                    chart={
                        <CChartBar
                            customTooltips={false}
                            className="mt-3 admin-content-container"
                            style={{ height: '70px' }}
                            data={{
                                labels: [
                                    'January',
                                    'February',
                                    'March',
                                    'April',
                                    'May',
                                    'June',
                                    'July',
                                    'August',
                                    'September',
                                    'October',
                                    'November',
                                    'December',
                                ],
                                datasets: [
                                    {
                                        label: 'My First dataset',
                                        backgroundColor: 'rgba(255,255,255,.2)',
                                        borderColor: 'rgba(255,255,255,.55)',
                                        data: [78, 81, 80, 45, 34, 12, 40, 85, 65, 23, 12, 98, 34, 84, 67, 82],
                                        barPercentage: 0.6,
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                            drawBorder: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                    y: {
                                        min: -9,
                                        max: 39,
                                        display: false,
                                        grid: {
                                            display: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                },
                                elements: {
                                    line: {
                                        borderWidth: 1,
                                    },
                                    point: {
                                        radius: 4,
                                        hitRadius: 10,
                                        hoverRadius: 4,
                                    },
                                },
                            }}
                        />
                    }
                />
            </CCol>
            <CCol lg={3} md={4} sm={6}>
                <CWidgetStatsA
                    className="mb-4 admin-content-container"
                    value={
                        <>
                            $9.000{' '}
                            <span className="fs-6 fw-normal">
                                    (40.9%)
                                </span>
                        </>
                    }
                    title="Widget title"
                    action={
                        <div className="rounded-2 p-2" style={{ backgroundColor: "rgba(255, 94, 94, 0.2)", color: "rgba(255, 94, 94, 1)" }}>
                            <FaDollarSign size={25} style={{ fontWeight: "bolder" }} />
                        </div>
                    }
                    chart={
                        <CChartLine
                            customTooltips={false}
                            className="mt-3"
                            style={{ height: '70px' }}
                            data={{
                                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                                datasets: [
                                    {
                                        label: 'My First dataset',
                                        backgroundColor: 'rgba(255, 94, 94, 0.2)', // Lighter color for the gradient
                                        borderColor: 'rgba(255, 94, 94, 1)', // Color for the line
                                        borderWidth: 2, // Adjust the width of the line
                                        data: [1, 18, 9, 17, 34, 22, 11],
                                        fill: {
                                            target: 'origin',
                                            above: 'rgba(255, 94, 94, 0.2)', // Lighter color for above the line
                                            below: 'rgba(255, 255, 255, 0)' // Transparent color for fading effect
                                        }
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                            drawBorder: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                    y: {
                                        min: -9,
                                        max: 39,
                                        display: false,
                                        grid: {
                                            display: false,
                                        },
                                        ticks: {
                                            display: false,
                                        },
                                    },
                                },
                                elements: {
                                    line: {
                                        borderWidth: 1,
                                    },
                                    point: {
                                        radius: 4,
                                        hitRadius: 10,
                                        hoverRadius: 4,
                                    },
                                },
                            }}
                        />
                    }
                />
            </CCol>
            <CCol lg={3} md={4} sm={6}>
                <CWidgetStatsC
                    customTooltips={false}
                    className="mb-3 admin-content-container"
                    icon={<CIcon icon={cilChartPie} height={25} />}
                    inverse
                    progress={{ color: '#0D99FF', value: 75 }}
                    text="Widget helper text"
                    title="Widget title"
                    value="89.9%"
                />
            </CCol>
        </CRow>
    );
};

export default WidgetsRow;