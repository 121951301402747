import Row from "react-bootstrap/Row";
import {Button, Col, Dropdown} from "react-bootstrap";
import "./Requests.page.css";
import {CgBriefcase} from "react-icons/cg";
import {LuFilter} from "react-icons/lu";
import {AiFillPlusCircle} from "react-icons/ai";
import RequestsDataTableComponent from "../../components/RequestsTable/RequestsDataTable.component";
import {useState} from "react";
import FilterOrderModal from "../../components/Modals/CenteredModal/FilterOrderModal";
import {NavLink} from "react-router-dom";
import {RiDraftLine} from "react-icons/ri";
import {BiMessageAltDetail} from "react-icons/bi";

const RequestsPage = () => {
    const [centeredModalShow, setCenteredModalShow] = useState(false);
    return (
        <>
        <Row>
            <Col>
                <Button className={"create-request-button border-0"} as={NavLink} to={"/create-request"}>
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <div className={"me-2"}>
                            Create a new request
                        </div>
                        <div>
                            <AiFillPlusCircle color={"white"} />
                        </div>
                    </div>
                </Button>
            </Col>
            <Col className={"text-end d-flex justify-content-end"}>
                    <Dropdown>
                        <Dropdown.Toggle id="services-dropdown" className={"rounded-pill"} variant={"outline-primary"}>
                            Services {" "} <CgBriefcase className={"ms-2"} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={"text-end"}>
                                <Dropdown.Item as={NavLink} to={"/drafts"}>
                                    المسودات <RiDraftLine />
                                </Dropdown.Item>
                            <Dropdown.Item as={NavLink} to={"/request-message"}>
                                رسائل حالات الطلب <BiMessageAltDetail />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                <div className={"outline-primary-btn ms-2"} onClick={()=> setCenteredModalShow(true)}>
                    <div>
                        Filter
                    </div>
                    <div className={"ms-2"}>
                        <LuFilter />
                    </div>
                </div>
            </Col>
        </Row>
            <Row className={"mainColor ps-2 mt-4"}>
                <p className={"fs-3 fw-bold"}>Order list</p>
                <p>All orders in your store are here</p>
            </Row>
            <Row>
                <RequestsDataTableComponent />
            </Row>
            <FilterOrderModal show={centeredModalShow} onHide={()=> setCenteredModalShow(false)} />
        </>
    );
};

export default RequestsPage;
